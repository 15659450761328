/**
 * Created by dmitry on 12.12.2024
 */

var FieldInfoView = cc.Node.extend({
    ctor: function (info) {
        var styles = cleverapps.styles.FieldInfoView;

        var resources = info.actions.map(function (action) {
            return new FieldInfoIcon(action);
        });

        var content = new cleverapps.Layout(resources, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });

        this._super();
        this.setContentSize2(content.width, content.height);

        var background = cleverapps.UI.createScale9Sprite(bundles.merge.frames.background, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize2(this.width, this.height);
        background.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(background);

        var pointer = new cc.Sprite(bundles.merge.frames.pointer);
        pointer.setPositionRound(styles.pointer.x, styles.pointer.y);
        this.addChild(pointer);

        content.setPositionRound(this.width / 2, this.height / 2);
        content.setLocalZOrder(1);
        this.addChild(content);
    }
});

FieldInfoView.ACTION_PLANT = 1;
FieldInfoView.ACTION_HARVEST = 2;

cleverapps.styles.FieldInfoView = {
    margin: 16,

    padding: {
        x: 15,
        y: 15
    },

    pointer: {
        x: { align: "center" },
        y: -5
    }
};
