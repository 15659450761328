/**
 * Created by r4zi4l on 11.11.2021
 */

var Orders = function () {
    cleverapps.EventEmitter.call(this);
    this.softPrice = 0;
};
Orders.prototype = Object.create(cleverapps.EventEmitter.prototype);
Orders.prototype.constructor = Orders;

Orders.prototype.listMakers = function () {
    return cleverapps.unitsLibrary.listAvailableHeroes().map(function (unit) {
        return unit.findComponent(MakesOrder);
    }).filter(Boolean);
};

Orders.prototype.listAvailableMakers = function () {
    return this.listMakers().filter(function (maker) {
        return maker.state !== MakesOrder.STATE_EMPTY;
    });
};

Orders.prototype.findCanCook = function () {
    var makers = this.listMakers();
    for (var i = 0; i < makers.length; i++) {
        if (makers[i].canCook()) {
            return makers[i];
        }
    }

    return undefined;
};

Orders.prototype.findReady = function () {
    var makers = this.listMakers();
    for (var i = 0; i < makers.length; i++) {
        if (makers[i].isReady()) {
            return makers[i];
        }
    }

    return undefined;
};

Orders.prototype.updateOrder = function (maker) {
    this.trigger("orderStateChanged", maker);
};

Orders.prototype.selectMaker = function (maker) {
    this.trigger("makerSelected", maker);
};

Orders.prototype.processOrder = function (maker) {
    if (maker.state === MakesOrder.STATE_RECIPE) {
        if (!maker.canCook()) {
            new BuyIngredientsWindow(maker.recipe.getShoppingList());
            return;
        }

        this.trigger("ingredientsConsumed", maker);
        maker.cook();
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.MAKE_ORDER);
    } else if (maker.state === MakesOrder.STATE_COOKING) {
        maker.enjoy();
    } else if (maker.state === MakesOrder.STATE_READY) {
        this.trigger("orderClaimed", maker);
        this.softPrice = 0;
    }
};

Orders.prototype.isUnitRequired = function (unit) {
    return this.listMakers().some(function (maker) {
        if (maker.isProcessingCustomOrder()) {
            return maker.recipe.ingredients.some(function (ingredient) {
                return ingredient.ingredient === unit.code || ingredient.unit.code === unit.code;
            });
        }
        return false;
    });
};

Orders.prototype.getCustomRecipeIngredientCode = function () {
    var requiredCodes = {};
    this.listMakers().forEach(function (maker) {
        if (maker.isProcessingCustomOrder()) {
            maker.recipe.ingredients.forEach(function (ingredient) {
                if (Map2d.currentMap.customers.getStockAmount(ingredient) < ingredient.amount) {
                    requiredCodes[ingredient.ingredient || ingredient.unit.code] = true;
                }
            });
        }
    });

    var neededCodes = Object.keys(requiredCodes);
    return neededCodes.length && cleverapps.Random.choose(neededCodes);
};
