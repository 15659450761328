/**
 * Created by r4zi4l on 24.08.2021
 */

var OrderDetails = cc.Node.extend({
    ctor: function (maker) {
        this._super();

        this.maker = maker;
        this.setContentSize2(cleverapps.styles.OrderDetails);

        this.createContent();

        Game.currentGame.orders.on("makerSelected", this.updateMaker.bind(this), this);
        Game.currentGame.orders.on("orderStateChanged", this.updateOrder.bind(this), this);
        Map2d.currentMap.customers.on("onRecipesUpdated", this.updateIngredients.bind(this), this);
    },

    createContent: function () {
        var styles = cleverapps.styles.OrderDetails;
        var leftBasket = this.leftBasket = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.ow_bucket, cleverapps.UI.Scale9Rect.TwoPixelX);
        leftBasket.setContentSize2(styles.bucket.width, leftBasket.height);
        leftBasket.setPositionRound(styles.bucket.left);
        this.addChild(leftBasket);

        var rightBasket = this.rightBasket = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.ow_bucket, cleverapps.UI.Scale9Rect.TwoPixelX);
        rightBasket.setContentSize2(styles.bucket.width, rightBasket.height);
        rightBasket.setPositionRound(styles.bucket.right);
        if (cleverapps.config.name === "hustlemerge") {
            rightBasket.setScaleX(-1);
        }
        this.addChild(rightBasket);

        var arrow = this.arrow = new cleverapps.Spine(bundles.orderswindow.jsons.ow_exchange_arrows);
        arrow.setAnimation(0, "idle", false);
        arrow.setPositionRound(styles.arrows);
        this.addChild(arrow);

        if (this.maker) {
            this.ingredients = this.createIngredients();
            this.addChild(this.ingredients);

            this.rewards = this.createRewards();
            this.addChild(this.rewards);

            var turn = 0;
            arrow.setCompleteListener(function () {
                var animation = turn % 2 ? "idle" : "exchange";
                turn += 1;

                if (!this.maker || this.maker.state !== MakesOrder.STATE_COOKING) {
                    arrow.setAnimation(0, animation, false);
                }
            }.bind(this));
        }

        this.button = this.createButton();
    },

    createButton: function () {
        var styles = cleverapps.styles.OrderDetails.button;

        var maker = this.maker || {};
        var buttonOptions = {
            width: styles.width,
            height: styles.height,
            onClicked: this.onButtonClick.bind(this)
        };

        switch (maker.state) {
            case MakesOrder.STATE_RECIPE:
                buttonOptions.text = maker.canCook() ? "OrdersWindow.button.make" : "OrdersWindow.button.buyingredients";
                break;

            case MakesOrder.STATE_READY:
                buttonOptions.text = "Claim";
                break;

            case MakesOrder.STATE_COOKING:
                buttonOptions.canCoins = true;
                buttonOptions.price = SpeedUpWindow.CalcPrice(maker.getTimeLeft());
                buttonOptions.freeIcon = TextWithIcon.ICONS_BY_NAME.speedup;
                buttonOptions.eventName = cleverapps.EVENTS.SPENT.ORDER;
                buttonOptions.speedUp = true;
                buttonOptions.type = cleverapps.styles.UI.Button.Images.button_blue;

                if (ConfirmSpeedUpWindow.isAvailable(buttonOptions.price)) {
                    buttonOptions.confirmWindowOptions = {
                        timeLeft: maker.getTimeLeft(),
                        totalDuration: maker.recipe.cookingDuration
                    };
                }
                break;

            default:
                buttonOptions.disabled = true;
                buttonOptions.text = "OrdersWindow.button.make";
        }

        var button;
        if (maker.state === MakesOrder.STATE_COOKING) {
            button = new UseGoldButton(buttonOptions);
        } else {
            button = new cleverapps.UI.Button(buttonOptions);
        }

        button.setPositionRound(styles);
        this.addChild(button);

        if (Game.currentGame.tutorial.isActive() || maker.state === MakesOrder.STATE_READY) {
            button.runAction(new cc.Sequence(
                new cc.DelayTime(Game.currentGame.tutorial.isActive() ? 0.3 : 2.5),
                new cc.CallFunc(function () {
                    FingerView.hintClick(button, {
                        delay: 2.5,
                        repeatDelay: 2.5
                    });
                })
            ));
        }

        return button;
    },

    createIngredients: function () {
        var styles = cleverapps.styles.OrderDetails.ingredients;

        var icons = this.maker.recipe.ingredients.map(function (ingredient) {
            return new IngredientOrderIcon(ingredient, this.maker);
        }, this);

        var ingredients = new cleverapps.Layout(icons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        ingredients.setPositionRound(styles);
        return ingredients;
    },

    createRewards: function () {
        var styles = cleverapps.styles.OrderDetails.rewards;

        var icons = this.maker.recipe.order.map(function (item) {
            var image = CustomerRecipe.getIngredientIcon(item);
            image.setScale(styles.image.scale);
            image.setAnchorPoint(0.5, 0);
            return image;
        });

        var rewards = new cleverapps.Layout(icons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        rewards.setPositionRound(styles);
        return rewards;
    },

    changeButton: function () {
        this.button.stopAllActions();
        this.button.disabled = true;
        this.button.runAction(new cc.Sequence(
            new cc.ScaleTo(0.3, 0.85),
            new cc.RemoveSelf()
        ));
        this.button = this.createButton(this.maker);
        this.button.setVisible(false);
        this.button.setScale(0.85);
        this.button.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.Show(),
            new cc.ScaleTo(0.3, 1)
        ));
    },

    changeIngredients: function () {
        if (this.ingredients) {
            this.removeAnimated(this.ingredients);
            this.ingredients = undefined;
        }

        if (this.maker) {
            this.ingredients = this.createIngredients();
            this.addChild(this.ingredients);
            this.showAnimated(this.ingredients);
        }
    },

    changeRewards: function () {
        if (this.rewards) {
            this.removeAnimated(this.rewards);
            this.rewards = undefined;
        }

        if (this.maker) {
            this.rewards = this.createRewards();
            this.addChild(this.rewards);
            this.showAnimated(this.rewards);
        }
    },

    updateMaker: function (maker) {
        if (maker === this.maker) {
            return;
        }
        this.maker = maker;

        this.changeButton();
        this.changeIngredients();
        this.changeRewards();
    },

    updateOrder: function (maker) {
        if (this.maker === maker) {
            this.changeButton();
        }
    },

    updateIngredients: function () {
        var canCook = this.maker && this.maker.canCook();
        if (this.canCook !== canCook) {
            this.canCook = canCook;
            this.changeButton();
        }

        if (this.maker && this.maker.state === MakesOrder.STATE_RECIPE) {
            this.ingredients.children.forEach(function (icons) {
                icons.updateAmount(Map2d.currentMap.customers.getStockAmount(icons.ingredient));
            });
        }
    },

    onButtonClick: function () {
        Game.currentGame.orders.processOrder(this.maker);
    },

    removeAnimated: function (node) {
        node.children.forEach(function (child) {
            if (child.beforeRemoveAnimated) {
                child.beforeRemoveAnimated();
            }
        });

        node.setCascadeOpacityEnabledRecursively(true);
        node.runAction(new cc.Spawn(
            new cc.MoveBy(0.3, 0, cleverapps.styles.OrderDetails.delta.y).easing(cc.easeOut(2)),
            new cc.FadeOut(0.4),
            new cc.Sequence(
                new cc.ScaleTo(0.3, 0.5).easing(cc.easeIn(2)),
                new cc.RemoveSelf()
            )
        ));
    },

    showAnimated: function (node) {
        node.setCascadeOpacityEnabledRecursively(true);
        node.setScale(0.8);
        node.setOpacity(0.3);

        node.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.Spawn(
                new cc.FadeIn(0.4),
                new cc.ScaleTo(0.3, 1)
            )
        ));
    }
});

cleverapps.styles.OrderDetails = {
    width: 1240,
    height: 200,

    delta: {
        y: 100
    },

    bucket: {
        left: {
            x: { align: "center", dx: -370 },
            y: { align: "center", dy: 10 }
        },
        right: {
            x: { align: "center", dx: 360 },
            y: { align: "center", dy: 10 }
        },
        width: 550
    },

    arrows: {
        x: { align: "center", dx: -20 },
        y: { align: "center", dy: 55 }
    },

    button: {
        x: { align: "center", dx: -20 },
        y: { align: "center", dy: -60 },
        width: 260,
        height: 86
    },

    ingredients: {
        margin: 0,
        x: { align: "center", dx: -390 },
        y: { align: "bottom", dy: 52 }
    },

    rewards: {
        x: { align: "center", dx: 350 },
        y: { align: "bottom", dy: 77 },
        margin: 20,

        image: {
            scale: 1.2
        }
    }
};
