/**
 * Created by anatoly on 11.12.2024
 */

var MergeLogic;

var Merge3Logic = {
    listMergeAtPoint: function (unit, x, y) {
        var map = Map2d.currentMap;
        var mergingUnit = map.getUnit(x, y);
        var affected = [];

        if (mergingUnit && map.isGround(x, y) && !map.getFog(x, y) && unit.isMergeable(mergingUnit, true)) {
            affected = map.bfs(x, y, map.compareMergeable.bind(map, mergingUnit));
        }

        if (affected.length >= 2) {
            return affected;
        }
    },

    merge: function (affected, unit, mergeInfoUnit, automerge) {
        var game = Game.currentGame;
        var map = Map2d.currentMap;

        var affectedUnits = [unit];

        if (cleverapps.config.debugMode && automerge) {
            affectedUnits = affected;
        } else {
            affectedUnits = affectedUnits.concat(affected);
        }

        affectedUnits.forEach(function (pos) {
            var merging = map.getUnit(pos.x, pos.y);

            merging.claimPoints();
            merging.onMerge(affected[0]);
            var worker = map.workers.findAssigned(merging);
            if (worker) {
                worker.clearAssignment();
            }
            merging.remove(true);
        });

        var res = Merge3Logic.mergeBonus(mergeInfoUnit, affectedUnits.length);
        var resultUnits = [];

        for (var i = 0; i < res.bonus + res.keepLast; i++) {
            var newUnit = {
                code: mergeInfoUnit.code,
                stage: mergeInfoUnit.stage
            };

            if (i < res.bonus) {
                newUnit.unbuilt = !cleverapps.gameModes.skipBuildingStage;
                Object.assign(newUnit, mergeInfoUnit.getMergeUnit());
            }

            resultUnits.push(newUnit);
        }

        var bonusUnit = game.landmarks.getMergeBonusUnit(mergeInfoUnit);
        if (bonusUnit) {
            for (i = 0; i < res.bonus; ++i) {
                resultUnits.push(bonusUnit);
            }
        }

        var newUnits = [];

        for (i = 0; i < resultUnits.length; i++) {
            var pos = affected[i] || affected[affected.length - 1];
            newUnit = resultUnits[i];

            var newPos = map.findEmptySlot(pos.x, pos.y, newUnit, {
                skipCheckScreen: true,
                skipCheckEqual: true
            });

            if (newPos) {
                newUnit = new Unit(newUnit);
                newUnit.setPosition(newPos.x, newPos.y);
                map.add(Map2d.LAYER_UNITS, newPos.x, newPos.y, newUnit);
                newUnits.push(newUnit);
            } else {
                Game.currentGame.pocket.addUnits(newUnit, pos);
            }
        }

        if (!newUnits.length) {
            return;
        }

        var offerType;
        if (ThirdElement.IsAvailable(ThirdElement.TYPE_ADS, newUnits[0]) && Math.random() <= 0.3) {
            offerType = ThirdElement.TYPE_ADS;
        } else if (ThirdElement.IsAvailable(ThirdElement.TYPE_RUBY, newUnits[0])) {
            offerType = ThirdElement.TYPE_RUBY;
        } else if (ThirdElement.IsAvailable(ThirdElement.TYPE_ANIMALS, newUnits[0])) {
            offerType = ThirdElement.TYPE_ANIMALS;
        }

        if (offerType) {
            Game.currentGame.thirdElementPlanner.planNext({
                type: offerType,
                delay: 2200,
                target: {
                    code: newUnits[0].code,
                    stage: newUnits[0].stage,
                    x: newUnits[0].x,
                    y: newUnits[0].y
                }
            });
        }

        newUnits.forEach(function (newUnit, index) {
            map.onAddUnit(newUnit.x, newUnit.y, newUnit);
            newUnit.didMerged(newUnits[0], index);
        });

        newUnits[0].onStartDidMerged();

        game.counter.setTimeout(function () {
            Merge3Logic.playMergeSound({
                amount: affectedUnits.length,
                stage: unit.stage
            });
            map.onUnitFresh(newUnits[0]);
        }, 300);

        var unitForInfo = !offerType && newUnits.find(function (newUnit) {
            return !newUnit.isBuilt();
        });

        if (unitForInfo) {
            game.counter.setTimeout(function () {
                if (!cleverapps.focusManager.isFocused()) {
                    InfoView.DisplayInfo(unitForInfo);
                }
            }, 1300 + 100 * newUnits.length);
        }

        game.counter.setTimeout(function () {
            newUnits.forEach(function (newUnit, index) {
                map.onUnitAvailable(newUnit);
                if (index < res.bonus) {
                    Map2d.mapEvent(Map2d.SPAWN, { unit: newUnit, affected: affectedUnits });
                }
            });

            var sound = bundles.merge.urls["merge_effect_" + unit.code];
            if (sound) {
                cleverapps.audio.playSound(sound);
            }

            Map2d.mapEvent(Map2d.MERGE, { affected: affectedUnits });
        }, 1000 + 100 * newUnits.length);

        if (res.next > 5 && res.next === 2 * affected.length + 1) {
            game.counter.setTimeout(function () {
                game.onShowMergeBonus(newUnits);
            }, 1000 + 100 * newUnits.length);
        }

        if (cleverapps.gameModes.axemerge) {
            var targets = Merge3Logic.axeTargets(newUnits);

            game.counter.setTimeout(function () {
                targets.forEach(function (tuple) {
                    tuple.target.onDestruction(false, tuple.origin);
                });
            }, 700);

            game.counter.setTimeout(function () {
                targets.forEach(function (tuple) {
                    tuple.target.remove(true);
                    Map2d.currentMap.blockedGrounds.updateBlockedGrounds();
                });
            }, 1400);
        }

        game.advice.boo();
    },

    axeTargets: function (units) {
        var map = Map2d.currentMap;

        var directions = [{ x: 1, y: 0 }, { x: 0, y: 1 }, { x: -1, y: 0 }, { x: 0, y: -1 }];
        var cells = [];
        var used = {};
        var targets = [];
        var maxRadius = 6;
        var maxTargets = 3;

        units.forEach(function (unit) {
            var cell = {
                x: unit.x,
                y: unit.y,
                unit: unit
            };

            cells.push(cell);
            used[cell.x * 1000 + cell.y] = true;
        });

        for (var i = 0; i < cells.length && targets.length < maxTargets; ++i) {
            var origin = cells[i];

            for (var j = 0; j < directions.length && targets.length < maxTargets; ++j) {
                var delta = directions[j];
                var cell = {
                    x: origin.x + delta.x,
                    y: origin.y + delta.y,
                    unit: origin.unit
                };

                if (used[cell.x * 1000 + cell.y]) {
                    continue;
                }
                used[cell.x * 1000 + cell.y] = true;

                if (Math.abs(cell.x - cell.unit.x) > maxRadius || Math.abs(cell.y - cell.unit.y) > maxRadius) {
                    continue;
                }

                cells.push(cell);

                var unit = map.getUnit(cell.x, cell.y);
                var isSource = unit && ["source", "hlsource", "drsource", "chsource", "easource", "rpsource", "seasource", "advsource"].indexOf(Families[unit.code].type) !== -1;

                if (isSource) {
                    targets.push({
                        target: unit,
                        origin: cell.unit
                    });
                }
            }
        }

        return targets;
    },

    playMergeSound: function (options) {
        var total = options.stage * options.amount;
        var sound = 0;
        if (total >= 80) {
            sound = 4;
        } else if (total >= 40) {
            sound = 3;
        } else if (total >= 20) {
            sound = 2;
        } else if (total >= 10) {
            sound = 1;
        }

        cleverapps.audio.playSound(bundles.merge.urls["merge_effect_" + sound]);
    },

    mergeBonus: function (mergeInfoUnit, mergeAmount) {
        var prize = 0;
        var next = 5;
        var stages = Families[mergeInfoUnit.code].units;

        if (mergeAmount >= 3 && stages[mergeInfoUnit.stage].heroitem && mergeInfoUnit.stage === stages.length - 2) {
            prize = 1;
            mergeAmount -= 3;
        } else {
            while (mergeAmount >= 3) {
                var merging = 3;
                var p = 1;
                while (mergeAmount >= 2 * merging - 1) {
                    p *= 2;
                    merging = 2 * merging - 1;
                }
                if (next === 5) {
                    next = 2 * merging - 1;
                }

                mergeAmount -= merging;
                prize += p;
            }
        }

        return {
            bonus: prize,
            keepLast: mergeAmount,
            next: next
        };
    },

    showConfirmationWindow: function (unit, mergeInfoUnit, affected, callback) {
        var amount = Merge3Logic.mergeBonus(mergeInfoUnit, affected.length + 1).bonus;
        cleverapps.focusManager.display({
            stack: true,
            focus: "ConfirmMergeWindow",
            action: function (f) {
                new ConfirmMergeWindow(unit, mergeInfoUnit, amount, function (confirmed) {
                    callback && callback(confirmed);
                });

                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        });
    }
};