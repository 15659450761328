/**
 * Created by spepa on 02.08.2022
 */

var BuyFogWindow = CleverappsWindow.extend({
    onWindowLoaded: function (fogBlock) {
        this.fogBlock = fogBlock;

        this.createContent();

        this._super({
            name: "BuyFogWindow",
            title: "" + this.fogBlock.id + ".name",
            content: this.content,
            noBackground: true,
            notCloseByTouchInShadow: true,
            closeButtonDelay: true
        });

        this.fogBlock.hideBalloon();

        cleverapps.paymentsLogic.onChangeStatus(this.updateButton.bind(this), this);

        cleverapps.focusManager.showControlsWhileFocused(this.fogBlock.isSoftMoney() ? "MenuBarCoinsItem" : "MenuBarGoldItem");
    },

    updateButton: function () {
        var product = this.fogBlock.getProduct();

        if (connector.payments.isConnected() || !product || product instanceof VirtualProduct) {
            this.button.enable();
        } else {
            this.button.disable();
        }
    },

    getPerson: function () {
        return cleverapps.persons.choose(cleverapps.skins.getSlot("sellerPerson"), "worker");
    },

    onClose: function () {
        this.fogBlock.showBalloon();
    },

    createContent: function () {
        this.content = new cc.Node();
        this.content.setAnchorPoint(0.5, 0.5);
        this.content.setContentSize2(cleverapps.styles.BuyFogWindow);

        this.createIsland();
        this.createBadge();
        this.createGoods();
        this.createButton();

        this.showUpAnimation();
    },

    showUpAnimation: function () {
        var delay = 0;

        this.island.cells.forEach(function (cell) {
            if (delay < cell.delay + 0.5) {
                delay = cell.delay + 0.5;
            }

            [cell.border, cell.unit, cell.ground].filter(Boolean).forEach(function (node) {
                if (node) {
                    node.setScale(0);
                    node.runAction(new cc.Sequence(
                        new cc.DelayTime(cell.delay),
                        new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut()),
                        new cc.PlaySound(bundles.buyfogwindow.urls.showup_effect, { throttle: 50 })
                    ));
                }
            });
        });

        this.badge.setVisible(false);
        if (this.balloons) {
            this.balloons.setVisible(false);
        }

        this.content.runAction(new cc.Sequence(
            new cc.DelayTime(delay + 0.3),
            new cc.CallFunc(function () {
                this.badge.setVisible(true);
                this.badge.setScale(0);
                this.badge.runAction(new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut()));
                if (this.balloons) {
                    this.balloons.setVisible(true);
                    this.balloons.replaceParentSamePlace(this, { keepScale: true });
                    this.balloons.setAnimation(0, "open", false);
                    this.balloons.setCompleteListenerOnce(function () {
                        this.balloons.replaceParentSamePlace(this.content, { keepScale: true });
                        this.balloons.setAnimation(0, "idle", true);
                    }.bind(this));
                }
            }.bind(this))
        ));

        this.button.setScale(0);
        this.button.runAction(new cc.Sequence(
            new cc.DelayTime(delay),
            new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut())
        ));

        this.goodsTitle.setOpacity(0);
        this.goodsTitle.runAction(new cc.Sequence(
            new cc.DelayTime(delay + 0.5),
            new cc.FadeTo(0.2, 255)
        ));

        if (this.goodsTile) {
            this.goodsTile.setScale(0);
            this.goodsTile.runAction(new cc.Sequence(
                new cc.DelayTime(delay + 0.5),
                new cc.ScaleTo(0.2, 1).easing(cc.easeBackOut())
            ));
        }

        if (this.islandShine) {
            this.islandShine.setScale(0);
            this.islandShine.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.ScaleTo(0.6, 1)
            ));
        }

        this.items.setScale(0);
        this.items.runAction(new cc.Sequence(
            new cc.DelayTime(delay + 0.5),
            new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut())
        ));
    },

    createBadge: function () {
        var badge = this.badge = new TileBadge({
            rotation: 0,
            type: "value",
            value: "200%"
        });
        this.content.addChild(badge);
        var pos = cleverapps.styles.BuyFogWindow.badge;
        badge.setPositionRound(this.content.width / 2 + (this.island.width * this.island.scale) / 2 + badge.width / 2 + pos.x, this.content.height / 2 + pos.y);
    },

    createIsland: function () {
        var styles = cleverapps.styles.BuyFogWindow;
        var map = Map2d.currentMap;

        var hasDecorators = ["adventure", "adventure2"].indexOf(cleverapps.travelBook.getCurrentPage().id) !== -1 || cleverapps.config.name === "hustlemerge" && !cleverapps.travelBook.isExpedition();
        var withShine = cleverapps.config.name === "hustlemerge" && !cleverapps.travelBook.isExpedition();

        var decorTiles = [];
        if (hasDecorators) {
            decorTiles = map.fogs.getTilesWithDecorators(this.fogBlock);
        }

        var savedWaterLayer = map.layers[Map2d.LAYER_WATER];
        var savedGroundLayer = map.layers[Map2d.LAYER_GROUND];

        var isFogBlockTile = function (x, y) {
            return this.fogBlock.tiles.concat(decorTiles.filter(function (dt) {
                return map.isImpassableGround(dt.x, dt.y);
            })).filter(function (bt) {
                return bt.x === x && bt.y === y;
            }).length > 0;
        }.bind(this);

        map.layers[Map2d.LAYER_WATER] = map.map(map.groundMap, function (cell, x, y) {
            return isFogBlockTile(x, y) ? undefined : Map2d.TILE_WATER;
        });
        map.layers[Map2d.LAYER_GROUND] = map.map(map.groundMap, function (cell, x, y) {
            return isFogBlockTile(x, y) ? cell : undefined;
        });

        var island = new cc.Node();
        island.setAnchorPoint(0.5, 0.5);

        var cells = [];
        var x, y, miny = 100000;
        this.fogBlock.tiles.forEach(function (tile) {
            if (miny > tile.y) {
                miny = tile.y;
            }
        });

        this.fogBlock.tiles.forEach(function (tile) {
            map.setPointer(tile.x, tile.y);
            var groundView = ViewReader.parse(map.getGround(Iso.SAME), map);
            if (x === undefined) {
                x = tile.x;
                y = tile.y;
            }
            var dx = tile.x - x;
            var dy = tile.y - y;
            var X = dx + dy + 1;
            var Y = dx - dy;
            groundView.setPositionRound(X * cleverapps.styles.Map2dView.cell.width / 2, Y * cleverapps.styles.Map2dView.cell.height / 2);
            groundView.setLocalZOrder(1000 - Y);
            island.addChild(groundView, 1);

            var chessGroundView = ViewReader.parse(map.getTileAboveGround(Iso.SAME), map);
            if (chessGroundView) {
                chessGroundView.setPositionRound(X * cleverapps.styles.Map2dView.cell.width / 2, Y * cleverapps.styles.Map2dView.cell.height / 2);
                island.addChild(chessGroundView, 3);
            }

            var unit = tile.getFakeUnit();
            var unitView;
            if (unit && !unit.head) {
                unitView = UnitView.getUnitImage(unit, {
                    alignAnchorX: true,
                    alignAnchorY: true
                });
                unitView.setPositionRound(groundView.getPosition());
                unitView.setLocalZOrder(10000 - Y);
                island.addChild(unitView, 3);

                MultiCellView(unit, unitView);

                if (unit.grounded) {
                    var grounded = new cc.Sprite(GroundedView.getImage(unit.x, unit.y));
                    grounded.setPositionRound(groundView.width / 2, groundView.height / 2);
                    groundView.addChild(grounded);
                }
            }

            var borderView = undefined;
            if (this.fogBlock.isBorderTile(tile) && !hasDecorators) {
                borderView = this.createCellBorder();
                borderView.setPositionRound(groundView.getPosition());
                island.addChild(borderView, -1);
            }

            cells.push({
                ground: hasDecorators ? undefined : groundView,
                unit: unitView,
                border: borderView,
                delay: 0.1 * (tile.y - miny) + 0.3
            });
        }.bind(this));

        var balloonData = this.fogBlock.getBalloon();
        if (balloonData) {
            var balloons = this.balloons = new cleverapps.Spine(bundles.merge.jsons.fog_balloon_json);
            balloons.setLocalZOrder(1000);
            var skins = balloons.listSkins();
            balloons.setSkin(this.fogBlock.options.id % skins.length + "");

            if (cleverapps.skins.getSlot("fogballoon_showup_effect")) {
                cleverapps.audio.playSound(cleverapps.skins.getSlot("fogballoon_showup_effect"));
            }

            if (hasDecorators) {
                var dx = balloonData.tile.x - x;
                var dy = balloonData.tile.y - y;
                var offset = cleverapps.styles.FogBalloonView.animation;
                balloons.setPositionRound((dx + dy + 1) * cleverapps.styles.Map2dView.cell.width / 2 + offset.x.dx, (dx - dy) * cleverapps.styles.Map2dView.cell.height / 2 + offset.y.dy);
                island.addChild(balloons);
            } else {
                this.content.addChild(balloons);
            }
        }

        var highestDecorPoint = 0;
        var lowestDecorPoint = 0;
        decorTiles.forEach(function (tile) {
            var dx = tile.x - x;
            var dy = tile.y - y;
            var position = cc.p((dx + dy + 1) * cleverapps.styles.Map2dView.cell.width / 2, (dx - dy) * cleverapps.styles.Map2dView.cell.height / 2);

            var tileDecor = new cc.Node();
            var tileBorders = new cc.Node();
            tileDecor.setPositionRound(position);
            tileBorders.setPositionRound(position);

            (map.decorators.getDecorators(tile.x, tile.y) || []).filter(function (decorator) {
                return !this.fogBlock.options.noTerrainInBuyWindow || decorator.code.indexOf("terrains_") === -1;
            }.bind(this)).forEach(function (decorator) {
                var ViewClass = decorator.getViewClass();
                var decorView = new ViewClass(decorator);

                if (decorator.getLayerId() === Map2d.LAYER_BORDERS) {
                    tileBorders.addChild(decorView);
                } else {
                    tileDecor.addChild(decorView);
                }
            });

            map.setPointer(tile.x, tile.y);
            var borderTypes = ViewReader.calcBorderType(map, true);
            if (borderTypes.length > 0) {
                tileBorders.addChild(new Map2dBorderView(borderTypes), -1);
            }

            if (!this.fogBlock.options.noTerrainInBuyWindow) {
                island.addChild(tileBorders, -1);
            }

            island.addChild(tileDecor, 2);

            var decorBox = tileDecor.getBoundingBoxToWorld();
            if (decorBox.y + decorBox.height > highestDecorPoint) {
                highestDecorPoint = decorBox.y + decorBox.height;
            }
            if (decorBox.y < lowestDecorPoint) {
                lowestDecorPoint = decorBox.y;
            }
        }.bind(this));

        island = this.island = cleverapps.UI.wrap(island);
        island.setScale(Math.min(1, styles.island.height / island.getBoundingBoxToWorld().height));
        this.content.addChild(island);
        island.setPositionRound(styles.island);

        this.island.cells = cells;

        if (withShine) {
            this.islandShine = new cleverapps.Spine(bundles.buyfogwindow.jsons.fog_shine_json);
            this.islandShine.setAnimation(0, "animation", true);
            this.islandShine.setPositionRound(styles.island.shine);
            island.addChild(this.islandShine, -2);

            if (highestDecorPoint - lowestDecorPoint > island.height) {
                island.setContentSize(island.width, highestDecorPoint - lowestDecorPoint);
            }
        } else if (this.balloons) {
            var pos = styles.balloons;
            this.balloons.setPositionRound(this.content.width / 2 - (this.island.width * this.island.scale) / 2 - balloons.width / 2 + pos.x, this.content.height / 2 + pos.y);
            this.balloons.setScale(1.3);
        }

        map.layers[Map2d.LAYER_WATER] = savedWaterLayer;
        map.layers[Map2d.LAYER_GROUND] = savedGroundLayer;
    },

    createCellBorder: function () {
        var border = new cleverapps.Spine(bundles.buyfogwindow.jsons.island_shine_json);
        border.setAnimation(0, "animation", true);
        border.setSkin(this.fogBlock.options.windowSkin ? this.fogBlock.options.windowSkin : "base");
        return border;
    },

    createGoods: function () {
        var styles = cleverapps.styles.BuyFogWindow.goods;

        var goods = new cleverapps.Layout([this.createGoodsTitle(), this.createValuables()], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.linesMargin
        });

        this.content.addChild(goods);
        goods.setPositionRound(styles);
    },

    createGoodsTitle: function () {
        var styles = cleverapps.styles.BuyFogWindow.goods;

        if (cleverapps.environment.isMainScene() && !cleverapps.travelBook.isExpedition()) {
            this.goodsTitle = cleverapps.UI.generateOnlyText("BuyFogWindow.tiles", cleverapps.styles.FONTS.BUY_FOG_WINDOW_GOODS_TILES, {
                tiles: this.fogBlock.tiles.length
            });

            if (cleverapps.config.name === "hustlemerge") {
                return this.goodsTitle;
            } 
            var tile = new cc.Node();
            this.goodsTile = tile;
            tile.setAnchorPoint(0.5, 0.5);
            var map = Map2d.currentMap;
            map.setPointer(this.fogBlock.tiles[0].x, this.fogBlock.tiles[0].y);
            var ground = map.getGround(Iso.SAME);
            var cell = ViewReader.parse(ground, map);
            var border = this.createCellBorder();
            tile.setContentSize(border.getContentSize());
            cell.setPositionRound(tile.width / 2, tile.height / 2);
            border.setPositionRound(tile.width / 2, tile.height / 2);
            tile.addChild(border);
            tile.addChild(cell);

            return new cleverapps.Layout([this.goodsTile, this.goodsTitle], {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.tilesMargin
            });
        }
        this.goodsTitle = cleverapps.UI.generateOnlyText("BuyFogWindow.valuables", cleverapps.styles.FONTS.BUY_FOG_WINDOW_BONUS);
        return this.goodsTitle;
    },

    generateValuables: function () {
        var count = {};
        this.fogBlock.tiles.forEach(function (tile) {
            var unit = tile.getFakeUnit();
            if (unit && unit.code) {
                var key = Unit.GetKey(unit);
                count[key] = (count[key] || 0) + 1;
            }
        });

        var units = [];
        for (var key in count) {
            var unit = Unit.ParseKey(key);
            unit.amount = count[key];
            unit.price = Families[unit.code].units[unit.stage].price * unit.amount;
            units.push(unit);
        }
        units.sort(function (a, b) {
            return b.price - a.price;
        });
        return units.slice(0, 3);
    },

    createValuables: function () {
        var styles = cleverapps.styles.BuyFogWindow.goods;

        var bubbles = this.fogBlock.options.bubbles;
        var valuables = bubbles && bubbles.length > 0 ? bubbles : this.generateValuables();

        this.items = new RewardsListComponent({ units: valuables }, {
            shine: true,
            margin: styles.itemsMargin,
            font: cleverapps.styles.FONTS.BUY_FOG_WINDOW_GOODS_UNITS,
            textDirection: cleverapps.UI.HORIZONTAL
        });

        return this.items;
    },

    createButton: function () {
        var styles = cleverapps.styles.BuyFogWindow.button;
        var button = this.button = new cleverapps.UI.Button({
            text: this.fogBlock.getPriceTag(),
            width: styles.width,
            height: styles.height,
            onClicked: function () {
                this.fogBlock.buy(this.createListener(function (success) {
                    if (success) {
                        if (this.balloons) {
                            if (cleverapps.skins.getSlot("fogballoon_buy_effect")) {
                                cleverapps.audio.playSound(cleverapps.skins.getSlot("fogballoon_buy_effect"));
                            }

                            this.balloons.replaceParentSamePlace(Map2d.currentMap.getMapView().animations, { keepScale: true });
                            this.balloons.setAnimation(0, "close", false);
                            this.balloons.setCompleteListenerRemove();
                        }
                        this.close();
                    }
                }.bind(this)));
            }.bind(this)
        });
        this.content.addChild(button);

        this.updateButton();

        button.setPositionRound(styles);
    },

    listBundles: function () {
        return ["buyfogwindow"];
    }

});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BUY_FOG_WINDOW_BONUS: {
        size: 55,
        color: cleverapps.styles.COLORS.YELLOW_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    BUY_FOG_WINDOW_GOODS_TILES: {
        size: 45,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    BUY_FOG_WINDOW_GOODS_UNITS: {
        size: 55,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.BuyFogWindow = {
    width: 1100,
    height: 1000,

    badge: {
        x: 0,
        y: 380
    },

    balloons: {
        x: 0,
        y: 250
    },

    island: {
        width: 1300,
        height: 580,
        x: { align: "center" },
        y: { align: "center", dy: 190 }
    },

    goods: {
        x: { align: "center" },
        y: { align: "center", dy: -240 },
        linesMargin: 0,
        tilesMargin: 10,
        itemsMargin: {
            x: 80,
            y: 40
        },

        item: {
            width: 130,
            height: 130,
            maxScale: 1.45
        }
    },

    button: {
        width: 250,
        height: 100,
        x: { align: "center" },
        y: { align: "bottom", dy: 0 }
    }
};
