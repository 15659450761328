/**
 * Created by razial on 16.08.2024.
 */

QuestsConfig.adventure2 = cleverapps.clone(QuestsConfig.universal, true);

Harvested.CONFIG.adventure2 = {
    capacity: 250,
    price: 250,
    exchangeMinimum: 50,

    resources: {
        advgrass: {
            icon: bundles.resource_icons.frames.resource_advgrass,
            smallIcon: bundles.resource_icons.frames.resource_advgrass_small
        },
        advleaves: {
            icon: bundles.resource_icons.frames.resource_advleaves,
            smallIcon: bundles.resource_icons.frames.resource_advleaves_small
        },
        advlog: {
            icon: bundles.resource_icons.frames.resource_advlog,
            smallIcon: bundles.resource_icons.frames.resource_advlog_small
        },
        advstick: {
            icon: bundles.resource_icons.frames.resource_advstick,
            smallIcon: bundles.resource_icons.frames.resource_advstick_small
        },
        advstone: {
            icon: bundles.resource_icons.frames.resource_advstone,
            smallIcon: bundles.resource_icons.frames.resource_advstone_small
        },
        advblueberry: {
            icon: bundles.resource_icons.frames.resource_advblueberry,
            smallIcon: bundles.resource_icons.frames.resource_advblueberry_small
        },
        advlychee: {
            icon: bundles.resource_icons.frames.resource_advlychee,
            smallIcon: bundles.resource_icons.frames.resource_advlychee_small
        },
        advbanana: {
            icon: bundles.resource_icons.frames.resource_advbanana,
            smallIcon: bundles.resource_icons.frames.resource_advbanana_small
        },
        advorange: {
            icon: bundles.resource_icons.frames.resource_advorange,
            smallIcon: bundles.resource_icons.frames.resource_advorange_small
        },
        advbalsam: {
            icon: bundles.resource_icons.frames.resource_advbalsam,
            smallIcon: bundles.resource_icons.frames.resource_advbalsam_small
        },
        advcloth: {
            icon: bundles.resource_icons.frames.resource_advcloth,
            smallIcon: bundles.resource_icons.frames.resource_advcloth_small
        },
        advballoon0: {
            icon: bundles.resource_icons.frames.resource_advballoon0,
            smallIcon: bundles.resource_icons.frames.resource_advballoon0_small
        },
        advballoon1: {
            icon: bundles.resource_icons.frames.resource_advballoon1,
            smallIcon: bundles.resource_icons.frames.resource_advballoon1_small
        },
        advballoon2: {
            icon: bundles.resource_icons.frames.resource_advballoon2,
            smallIcon: bundles.resource_icons.frames.resource_advballoon2_small
        },
        advcoctail: {
            icon: bundles.resource_icons.frames.resource_advcoctail,
            smallIcon: bundles.resource_icons.frames.resource_advcoctail_small
        },
        advtea: {
            icon: bundles.resource_icons.frames.resource_advtea,
            smallIcon: bundles.resource_icons.frames.resource_advtea_small
        },
        advicecream: {
            icon: bundles.resource_icons.frames.resource_advicecream,
            smallIcon: bundles.resource_icons.frames.resource_advicecream_small
        },
        advcake: {
            icon: bundles.resource_icons.frames.resource_advcake,
            smallIcon: bundles.resource_icons.frames.resource_advcake_small
        }
    }
};

CustomerRecipes.adventure2 = {
    rpcustomerbridge0_0: [{
        ingredients: [{
            ingredient: "advgrass",
            amount: 20
        }, {
            ingredient: "advleaves",
            amount: 35
        }]
    }],

    rpcustomerbridge1_0: [{
        ingredients: [{
            code: "rpproduct0",
            stage: 0,
            amount: 1
        }]
    }],

    rpcustomerbridge2_0: [{
        ingredients: [{
            code: "rpproduct1",
            stage: 0,
            amount: 1
        }]
    }],

    rpcustomerbridge3_0: [{
        ingredients: [{
            code: "rpproduct2",
            stage: 0,
            amount: 1
        }]
    }],

    rpcustomerbridge4_0: [{
        ingredients: [{
            code: "rpproduct3",
            stage: 0,
            amount: 1
        }]
    }],

    rpcustomermain0_0: [{
        ingredients: [{
            code: "rpproduct0",
            stage: 0,
            amount: 2
        }]
    }],

    rpcustomermain1_0: [{
        ingredients: [{
            code: "rpproduct1",
            stage: 0,
            amount: 2
        }]
    }],

    rpcustomermain2_0: [{
        ingredients: [{
            code: "rpproduct2",
            stage: 0,
            amount: 5
        }]
    }],

    rpcustomermain3_0: [{
        ingredients: [{
            code: "rpproduct3",
            stage: 0,
            amount: 5
        }]
    }],

    rpcustomermain4_0: [{
        ingredients: [{
            code: "rpproduct4",
            stage: 0,
            amount: 10
        }]
    }],

    rpproducer0_0: [{
        ingredients: [{
            ingredient: "advlog",
            amount: 25
        }, {
            ingredient: "advstick",
            amount: 20
        }],
        order: [{
            code: "rpproduct0",
            stage: 0,
            amount: 1
        }]
    }],

    rpproducer1_0: [{
        ingredients: [{
            ingredient: "advstone",
            amount: 15
        }, {
            ingredient: "advblueberry",
            amount: 50
        }],
        order: [{
            code: "rpproduct1",
            stage: 0,
            amount: 1
        }]
    }],

    rpproducer2_0: [{
        ingredients: [{
            ingredient: "advlychee",
            amount: 35
        }, {
            ingredient: "advbanana",
            amount: 50
        }],
        order: [{
            code: "rpproduct2",
            stage: 0,
            amount: 1
        }]
    }],

    rpproducer3_0: [{
        ingredients: [{
            ingredient: "advgrass",
            amount: 30
        }, {
            ingredient: "advleaves",
            amount: 80
        }],
        order: [{
            code: "rpproduct3",
            stage: 0,
            amount: 1
        }]
    }],

    rpproducer4_0: [{
        ingredients: [{
            ingredient: "advlog",
            amount: 25
        }, {
            ingredient: "advstick",
            amount: 20
        }],
        order: [{
            code: "rpproduct4",
            stage: 0,
            amount: 1
        }]
    }]
};