/**
 * Created by Anastasiya on 15.06.2024
 */

var PixelOfferWindow = CleverappsWindow.extend({
    onWindowLoaded: function (offer) {
        this.offer = offer.logic;

        var styles = cleverapps.styles.PixelOfferWindow;

        this.createContent();

        this._super({
            name: "PixelOfferWindow",
            title: "PixelOfferWindow.name",
            content: this.content,
            foreground: bundles.windows.frames.window_foreground_png,
            styles: styles.window,
            openSound: bundles.pixelofferwindow.urls.showup_sfx,
            button: {
                text: this.offer.getProduct() ? this.offer.getProduct().getCurrentPrice() : "$$" + this.offer.getPrice(),
                width: styles.button.width,
                height: styles.button.height,
                onPressed: this.offer.buyProduct.bind(this.offer)
            }
        });

        this.buttons.setLocalZOrder(1);

        cleverapps.focusManager.showControlsWhileFocused("MenuBarGoldItem");
        this.offer.on("onProductBought", this.onRewardReady.bind(this), this);

        this.setupChildren();
    },

    getPerson: function () {
        return cleverapps.persons.choose("seller", "worker");
    },

    setupChildren: function () {
        this._super();

        if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
            this.balloon.setVisible(true);
        } else {
            this.balloon.setVisible(false);
        }
    },

    onRewardReady: function () {
        new RewardWindow(this.offer.getReward(), { event: cleverapps.EVENTS.EARN.PURCHASE });
        this.close();
    },

    createContent: function () {
        this.content = new cc.Node();
        this.content.setAnchorPoint(0.5, 0.5);
        this.content.setContentSize2(cleverapps.styles.PixelOfferWindow);

        this.createDescription();
        this.createGoods();
        this.createOfferBalloon();

        this.showUpAnimation();
    },

    showUpAnimation: function () {
        var delay = 0;

        this.lots.setScale(0);
        this.lots.runAction(new cc.Sequence(
            new cc.DelayTime(delay + 0.5),
            new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut())
        ));
    },

    createDescription: function () {
        var styles = cleverapps.styles.PixelOfferWindow.description;

        var texts = [];
        var offerReward = this.offer.getReward();
        Object.keys(offerReward).forEach(function (reward) {
            var rewardData = {};
            rewardData[reward] = offerReward[reward];
            var name;
            var amount;
            Object.keys(rewardData).forEach(function (key) {
                if (key === "unit") {
                    name = cleverapps.unitsLibrary.getUnitName(rewardData[key]);
                    amount = rewardData[key].amount;
                } else {
                    name = Messages.get("PixelOfferWindow.description.reward." + key);
                    amount = rewardData[key];
                }
            });
            texts.push({ name: name, amount: amount });
        });

        var part1 = cleverapps.UI.generateOnlyText("PixelOfferWindow.description.first", cleverapps.styles.FONTS.PIXEL_OFFER_TEXT);
        var part1reward = cleverapps.UI.generateOnlyText("x" + texts[0].amount + " " + texts[0].name, cleverapps.styles.FONTS.PIXEL_OFFER_REWARD_TEXT);

        var firstLine = new cleverapps.Layout([part1, part1reward], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.spaceMargin
        });

        if (texts[1]) {
            var part2 = cleverapps.UI.generateOnlyText("PixelOfferWindow.description.second", cleverapps.styles.FONTS.PIXEL_OFFER_TEXT);
            var part2reward = cleverapps.UI.generateOnlyText("x" + texts[1].amount + " " + texts[1].name, cleverapps.styles.FONTS.PIXEL_OFFER_REWARD_TEXT);

            var secondLine = new cleverapps.Layout([part2, part2reward], {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.spaceMargin
            });

            this.content.height = cleverapps.styles.PixelOfferWindow.height + styles.lineHeight;
        }

        var descriptionLayout = this.description = new cleverapps.Layout([firstLine, secondLine].filter(Boolean), {
            direction: cleverapps.UI.VERTICAL
        });

        cleverapps.UI.fitToBox(descriptionLayout, styles);

        this.content.addChild(descriptionLayout);
        descriptionLayout.setPositionRound(styles);
    },

    createGoods: function () {
        var styles = cleverapps.styles.PixelOfferWindow.goods;

        var lots = [];
        var offerReward = this.offer.getReward();
        Object.keys(offerReward).forEach(function (reward) {
            var rewardData = {};
            rewardData[reward] = offerReward[reward];
            var lot = this.createLot(rewardData);
            lots.push(lot);
        }.bind(this));

        var images = [];

        var pattern = new cc.Sprite(bundles.pixelofferwindow.frames.pattern_png);
        var columns = Math.round(styles.lots.width / pattern.width);
        var rows = Math.round(styles.lots.height / pattern.height);

        for (var i = 0; i < columns * rows; i++) {
            var image = new cc.Sprite(bundles.pixelofferwindow.frames.pattern_png);
            images.push(image);
        }

        var bg = new cleverapps.GridLayout(images, {
            columns: columns
        });

        var lotsLayout = this.lots = new cleverapps.Layout(lots, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.itemsMargin
        });
        lotsLayout.setPositionRound(styles.lots);

        bg.setPositionRound(styles);
        bg.addChild(lotsLayout);

        this.content.addChild(bg);
    },

    createLot: function (reward) {
        var styles = cleverapps.styles.PixelOfferWindow.goods.lot;
        var bg = cleverapps.UI.createScale9Sprite(bundles.pixelofferwindow.frames.tile_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(styles.bg);

        var sprite;
        var amount;
        Object.keys(reward).forEach(function (key) {
            if (key === "unit") {
                if (bundles.pixelofferwindow.frames[reward[key].code + "_png"]) {
                    sprite = new cc.Sprite(bundles.pixelofferwindow.frames[reward[key].code + "_png"]);
                } else {
                    sprite = UnitView.getUnitImage(reward[key], { preferStatic: true, useLibraryAnchors: true });
                }
                amount = reward[key].amount;
            } else {
                sprite = new cc.Sprite(bundles.pixelofferwindow.frames[key + "_png"]);
                amount = reward[key];
            }
        });

        sprite.setPositionRound(styles.item);
        cleverapps.UI.fitToBox(sprite, styles.item);
        bg.addChild(sprite);

        var text = cleverapps.UI.generateTTFText("x" + amount, cleverapps.styles.FONTS.PIXEL_OFFER_TEXT);
        text.setPositionRound(styles.amount);
        bg.addChild(text);

        return bg;
    },

    createOfferBalloon: function () {
        var styles = cleverapps.styles.PixelOfferWindow.balloon;

        var balloon = this.balloon = new cc.Sprite(bundles.pixelofferwindow.frames.balloon_png);
        balloon.setPositionRound(styles);

        this.content.addChild(balloon);
    },

    listBundles: function () {
        return ["pixelofferwindow"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PIXEL_OFFER_TEXT: {
        name: "nostroke",
        size: 45,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    PIXEL_OFFER_REWARD_TEXT: {
        name: "nostroke",
        size: 45,
        color: cleverapps.styles.COLORS.PIXEL_OFFER_REWARD_COLOR
    }
});

cleverapps.styles.PixelOfferWindow = {
    width: 1000,
    height: 600,

    window: {
        padding: {
            bottom: -45
        },
        margin: -30
    },

    description: {
        x: { align: "center" },
        y: { align: "top" },

        width: 850,
        height: 100,

        spaceMargin: 15,
        lineHeight: 40
    },

    goods: {
        x: { align: "center" },
        y: { align: "bottom", dy: -35 },
        itemsMargin: 150,

        lots: {
            x: { align: "center" },
            y: { align: "top", dy: -60 },

            width: 1100,
            height: 620
        },

        lot: {
            bg: {
                width: 350,
                height: 400
            },

            item: {
                x: { align: "center" },
                y: { align: "center", dy: 30 },

                width: 325,
                height: 300
            },

            amount: {
                x: { align: "center" },
                y: { align: "bottom", dy: 20 }
            }
        },

        item: {
            width: 250,
            height: 250,
            maxScale: 1.45
        }
    },

    balloon: {
        x: { align: "right", dx: 560 },
        y: { align: "center", dy: 80 }
    },

    button: {
        width: 350,
        height: 125,
        x: { align: "center" },
        y: { align: "bottom", dy: -110 }
    }
};
