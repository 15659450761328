/**
 * Created by r4zi4l on 09.02.2021
 */

var MergeAdviceSpawnLogic = function (options) {
    MergeAdviceBaseLogic.call(this, options);

    var prizes = cleverapps.toArray(options && options.prizes);
    if (prizes && prizes.length > 0) {
        this.prizes = prizes;
    }

    this.ingredients = options && options.ingredients;
};

MergeAdviceSpawnLogic.prototype = Object.create(MergeAdviceBaseLogic.prototype);
MergeAdviceSpawnLogic.prototype.constructor = MergeAdviceSpawnLogic;

MergeAdviceSpawnLogic.prototype.checkUnitPrizes = function (unit) {
    if (["chest", "unitsshop"].indexOf(unit.getType()) !== -1) {
        return true;
    }

    var customer = unit.findComponent(Customer);
    if (customer) {
        if (customer.isResting()) {
            customer.order();
        }
        return true;
    }

    if (Game.currentGame.tutorial.runningTutorialId === "story_wheat_3") {
        var prizes = [];
        var fruit = unit.findComponent(Fruit);
        if (fruit) {
            if (fruit && !fruit.isGrowing() && !fruit.unit.prizes) {
                fruit.makeReady();
            }
            prizes = fruit.unit.prizes;
        }

        var maker = unit.findComponent(MakesOrder);
        if (maker) {
            if (maker.state === MakesOrder.STATE_EMPTY && !cleverapps.windows.isActive()) {
                maker.order();
            }
            prizes = maker.recipe.listPrize();
        }

        return cleverapps.contains(prizes || [], this.prizes, Unit.GetKey);
    }

    if (unit.prizes && unit.prizes.length) {
        return cleverapps.contains(unit.prizes, this.prizes, Unit.GetKey);
    }

    return false;
};

MergeAdviceSpawnLogic.prototype.findMove = function () {
    if (this.ingredients && !Map2d.currentMap.customers.canTakeIngredients(this.ingredients)) {
        return;
    }

    var unit = this.listAvailableUnits().filter(this.checkUnitPrizes.bind(this))[0];
    if (!unit) {
        return;
    }

    var move = {};

    if (Game.currentGame.tutorial.checkTargets(unit) && ["chest", "unitsshop"].indexOf(unit.getType()) !== -1
        || Map2d.currentMap.findEmptySlot(unit.x, unit.y)) {
        move.cells = [cc.p(unit.x, unit.y)];
        move.arrow = move.cells[0];
        move.highlight = move.cells;
    }

    var currentWindow = cleverapps.windows.currentWindow();
    if (currentWindow instanceof OrdersWindow && unit.getType() === "hero"
        || currentWindow instanceof OpenChestWindow && unit.getType() === "chest"
        || currentWindow instanceof UnitsShopWindow && unit.getType() === "unitsshop"
        || currentWindow instanceof ExchangeWindow && ["rpcustomer", "eacustomer", "chcustomer"].includes(unit.getType())) {
        move.window = Map2d.SPAWN;
    }

    if (move.cells || move.window) {
        return move;
    }
};
