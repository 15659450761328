/**
 * Created by vladislav on 25/10/2022
 */

QuestsConfig.universal = {
    rpcustomerbridge1_1: {
        trigger: { fog: "fog1" },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomerbridge1", stage: 1 }
        }
    },
    rpproducer3: {
        trigger: { fog: "fog1" },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpproducer3", stage: 0 }
        }
    },

    rpcustomerbridge0_0: {
        trigger: { fog: "fog2" },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpcustomerbridge0", stage: 0 }
        }
    },
    rpcustomerbridge0_1: {
        trigger: { unit: { code: "rpcustomerbridge0", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomerbridge0", stage: 1 }
        }
    },
    rpcustomermain0_0: {
        trigger: { unit: { code: "rpcustomerbridge0", stage: 1 } },
        highlightTargets: false,
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpcustomermain0", stage: 0 }
        }
    },
    rpcustomermain0_1: {
        trigger: { unit: { code: "rpcustomermain0", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain0", stage: 1 }
        }
    },
    rpproducer0: {
        trigger: { unit: { code: "rpcustomermain0", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpproducer0", stage: 0 }
        }
    },
    rpproduct0: {
        trigger: { unit: { code: "rpproducer0", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpproduct0", stage: 0 }
        }
    },

    rpcustomerbridge1_0: {
        trigger: { unit: { code: "rpcustomermain0", stage: 1 } },
        highlightTargets: false,
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpcustomerbridge1", stage: 0 }
        }
    },
    rpcustomermain1_0: {
        trigger: { unit: { code: "rpcustomerbridge1", stage: 1 } },
        highlightTargets: false,
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpcustomermain1", stage: 0 }
        }
    },
    rpcustomermain1_1: {
        trigger: { unit: { code: "rpcustomermain1", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain1", stage: 1 }
        }
    },
    rpproducer1: {
        trigger: { unit: { code: "rpcustomermain1", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpproducer1", stage: 0 }
        }
    },
    rpproduct1: {
        trigger: { unit: { code: "rpproducer1", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpproduct1", stage: 0 }
        }
    },

    rpcustomerbridge2_0: {
        trigger: { unit: { code: "rpcustomermain1", stage: 1 } },
        highlightTargets: false,
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpcustomerbridge2", stage: 0 }
        }
    },
    rpcustomerbridge2_1: {
        trigger: { unit: { code: "rpcustomerbridge2", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomerbridge2", stage: 1 }
        }
    },
    rpcustomermain2_0: {
        trigger: { unit: { code: "rpcustomerbridge2", stage: 1 } },
        highlightTargets: false,
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpcustomermain2", stage: 0 }
        }
    },
    rpcustomermain2_1: {
        trigger: { unit: { code: "rpcustomermain2", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain2", stage: 1 }
        }
    },
    rpproducer2: {
        trigger: { unit: { code: "rpcustomermain2", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpproducer2", stage: 0 }
        }
    },
    rpproduct2: {
        trigger: { unit: { code: "rpproducer2", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpproduct2", stage: 0 }
        }
    },

    rpcustomerbridge3_0: {
        trigger: { unit: { code: "rpcustomermain2", stage: 1 } },
        highlightTargets: false,
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpcustomerbridge3", stage: 0 }
        }
    },
    rpcustomerbridge3_1: {
        trigger: { unit: { code: "rpcustomerbridge3", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomerbridge3", stage: 1 }
        }
    },
    rpcustomermain3_0: {
        trigger: { unit: { code: "rpcustomerbridge3", stage: 1 } },
        highlightTargets: false,
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpcustomermain3", stage: 0 }
        }
    },
    rpcustomermain3_1: {
        trigger: { unit: { code: "rpcustomermain3", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain3", stage: 1 }
        }
    },
    rpproduct3: {
        trigger: { unit: { code: "rpproducer3", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpproduct3", stage: 0 }
        }
    },

    rpcustomerbridge4_0: {
        trigger: { unit: { code: "rpcustomermain3", stage: 1 } },
        highlightTargets: false,
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpcustomerbridge4", stage: 0 }
        }
    },
    rpcustomerbridge4_1: {
        trigger: { unit: { code: "rpcustomerbridge4", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomerbridge4", stage: 1 }
        }
    },
    rpcustomermain4_0: {
        trigger: { unit: { code: "rpcustomerbridge4", stage: 1 } },
        highlightTargets: false,
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpcustomermain4", stage: 0 }
        }
    },
    rpcustomermain4_1: {
        trigger: { unit: { code: "rpcustomermain4", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain4", stage: 1 }
        }
    },
    rpproducer4: {
        trigger: { unit: { code: "rpcustomermain4", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpproducer4", stage: 0 }
        }
    },
    rpproduct4: {
        trigger: { unit: { code: "rpproducer4", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpproduct4", stage: 0 }
        }
    }
};
