/**
 * Created by mac on 2/25/20
 */

var Fogs = function (map2d) {
    cleverapps.EventEmitter.call(this);
    this.map2d = map2d;

    this.wands = 0;

    this.loadConfig();

    this.wantsCalcFogStates = undefined;

    this.blocks = {};
    this.fakeUnits = Fogs.ReadDefaults(map2d.field);
};

Fogs.prototype = Object.create(cleverapps.EventEmitter.prototype);
Fogs.prototype.constructor = Fogs;

Fogs.prototype.load = function (stored) {
    var openedFogs = Object.keys(this.config).filter(function (type) {
        return this.config[type].opened || stored.indexOf(type) !== -1;
    }, this);

    var unopenedFogs = Object.keys(this.config).filter(function (type) {
        return openedFogs.indexOf(type) === -1;
    }, this);

    for (var i = 0; i < openedFogs.length;) {
        var fog = this.config[openedFogs[i]];

        if (fog.available === false || fog.debugMode === true && !cleverapps.config.debugMode) {
            unopenedFogs.push(openedFogs[i]);
            openedFogs.splice(i, 1);
        } else {
            i += 1;
        }
    }

    var regions = this.map2d.regions;

    Object.keys(this.config).forEach(function (id) {
        this.config[id].emptyRegion = !regions[id];
    }, this);

    unopenedFogs.forEach(function (id) {
        var block = new FogBlock(id, this);

        var region = regions[id];
        if (!region) {
            return;
        }

        this.addBlockTiles(block, region);

        if (cleverapps.config.debugMode && block.isMoney() && !block.options.bubbles) {
            block.tiles.forEach(function (tile) {
                var unit = this.fakeUnits[tile.x] && this.fakeUnits[tile.x][tile.y];
                if (unit === undefined) {
                    console.log("No unit on " + block.id + " on x:" + tile.x + " y:" + tile.y);
                    return;
                }
                unit = unit.head || unit;
                if (Families[unit.code].units[unit.stage].price === undefined) {
                    alert("No price for " + unit.code + " " + unit.stage);
                }
            }.bind(this));
        }
        this.blocks[id] = block;
    }.bind(this));

    Object.values(this.blocks).forEach(function (block) {
        block.prepareOpenDelay();
    });
};

Fogs.prototype.getInfo = function () {
    return cleverapps.substract(Object.keys(this.config), Object.keys(this.blocks || {})).filter(function (id) {
        return !this.config[id].emptyRegion;
    }, this);
};

Fogs.prototype.save = function () {
    this.map2d.saveFogs();
};

Fogs.prototype.setWands = function (amount) {
    this.wands = amount;
    for (var id in this.blocks) {
        this.blocks[id].onChangeWandsAmount(amount);
    }
};

Fogs.prototype.takeWands = function (wands) {
    this.trigger("takeWands", wands);
};

Fogs.prototype.addBlockTiles = function (block, region) {
    region.positions.sort(function (a, b) {
        return a.y - b.y || b.x - a.x;
    }).forEach(function (p) {
        var head = region.head && cc.pointEqualToPoint(p, region.head);
        block.add(p.x, p.y, { head: head });
    });

    this.addBlockBalloon(block, region);
};

Fogs.prototype.getTilesWithDecorators = function (block) {
    var map = this.map2d;
    var used = {};
    var decorTiles = [];

    var buyFogCells = {};
    if (map.regions.buyFogCell) {
        map.regions.buyFogCell.positions.forEach(function (cell) {
            buyFogCells[cell.x + "_" + cell.y] = 1;
        });
    }

    var visit = function (tile, firstPass) {
        ISO_NEIGHBORS.concat([Iso.DOWN_BELOW, Iso.HORIZONTAL_LEFT, Iso.HORIZONTAL_RIGHT, Iso.UP_ABOVE]).forEach(function (dir) {
            var decorTile = {
                x: tile.x + dir.x,
                y: tile.y + dir.y
            };

            var key = map.getPositionKey(decorTile.x, decorTile.y);
            if (!used[key]) {
                used[key] = true;

                var unit = map.getUnit(decorTile.x, decorTile.y);

                var isBuyFogCell = buyFogCells[decorTile.x + "_" + decorTile.y];

                if (firstPass || !unit && !map.isImpassableGround(decorTile.x, decorTile.y) || isBuyFogCell) {
                    decorTiles.push(decorTile);
                }

                if (isBuyFogCell) {
                    visit(decorTile);
                }
            }
        });
    };

    block.tiles.forEach(visit, true);

    decorTiles.sort(function (a, b) {
        return b.x - a.x || a.y - b.y;
    });

    return decorTiles;
};

Fogs.prototype.addBlockBalloon = function (block, region) {
    if (region.positions.length > 0 && block.isMoney()) {
        if (!region.balloons) {
            region.balloons = FogBalloon.ChoosePosition(region.positions, region.head);
        }
        if (region.balloons) {
            block.addBalloon(region.balloons.x, region.balloons.y);
        }
    }
};

Fogs.ReadMapUnit = function (item) {
    item = cleverapps.clone(item, true);

    var family = Families[item.code];
    if (!family && !cleverapps.config.wysiwygMode) {
        cleverapps.throwAsync("unknown unit while reading map data " + item.code);
        return;
    }

    var data = Families[item.code].units[item.stage];

    item.type = Families[item.code].type;
    item.getType = function () {
        return item.type;
    };

    if (data.source) {
        item.grounded = true;
    }

    if (data.multicell) {
        item.multicell = data.multicell;
    }

    if (data.pointOfInterest) {
        item.pointOfInterest = data.pointOfInterest;
    }

    if (data.important) {
        item.important = data.important;
    }

    item.fresh = true;

    return item;
};

Fogs.ReadDefaults = function (defaults) {
    var units = {};
    defaults.forEach(function (item) {
        item = Fogs.ReadMapUnit(item);
        if (!item) {
            return;
        }

        var x = item.x;
        var y = item.y;

        if (units[x] === undefined) {
            units[x] = {};
        }

        units[x][y] = item;

        var shape = Unit.GetShape(item);
        if (shape.length > 1) {
            shape.forEach(function (delta) {
                if (delta.x || delta.y) {
                    var nx = x + delta.x;
                    var ny = y + delta.y;

                    if (units[nx] === undefined) {
                        units[nx] = {};
                    }

                    units[nx][ny] = {
                        head: item,
                        x: nx,
                        y: ny
                    };
                }
            });
        }
    });
    return units;
};

Fogs.prototype.initStage = function () {
    this.initStageRunned = true;
};

Fogs.prototype.open = function (id) {
    delete this.blocks[id];

    this.map2d.blockedGrounds.updateBlockedGrounds();
    Map2d.currentMap.workers.placeNotPositionedWorkers(id);

    this.wantsCalcFogStates = true;

    this.save();
    cleverapps.offerManager.refreshByFog(id);

    this.trigger("open");
};

Fogs.prototype.loadConfig = function () {
    this.config = FogsConfig[cleverapps.travelBook.getCurrentPage().id] || FogsConfig.main;

    var level = Game.currentGame && Game.currentGame.level;

    if (level && String(level.episodeNo).indexOf("ads") === 0) {
        this.config = FogsConfig.ads[level.episodeNo + "_" + level.levelNo] || FogsConfig.GenerateAdsDefault();
    }
};

Fogs.prototype.calcFogStates = function () {
    for (var id in this.blocks) {
        this.blocks[id].calcState();
    }
};

Fogs.prototype.calcFogStatesStage = function () {
    if (this.wantsCalcFogStates && !cleverapps.focusManager.isFocused()) {
        this.wantsCalcFogStates = undefined;
        this.calcFogStates();
    }
};

Fogs.prototype.isOpened = function (fogId) {
    return !this.blocks[fogId];
};

Fogs.prototype.getBlockById = function (fogId) {
    return this.blocks[fogId];
};

Fogs.prototype.findAvailableFogBlock = function () {
    var result;

    for (var id in this.blocks) {
        var block = this.blocks[id];

        if (block.state !== FogBlock.UNAVAILABLE) {
            if (!result || result.state < block.state) {
                result = block;
            }
        }
    }
    return result;
};

Fogs.prototype.showBalloons = function (silent) {
    for (var i in this.blocks) {
        this.blocks[i].showBalloon(silent);
    }
};

Fogs.prototype.hideBalloons = function () {
    for (var i in this.blocks) {
        this.blocks[i].hideBalloon();
    }
};

Fogs.prototype.findBlockWithProgress = function () {
    return Object.values(this.blocks).filter(function (block) {
        return block.state === FogBlock.CANTOPEN && !block.canOpen() && !block.isMoney() && !block.isSpecial();
    }).sort(function (a, b) {
        return a.getOpenPrice() - b.getOpenPrice();
    })[0];
};

Fogs.prototype.findFogReadyToOpen = function () {
    for (var i in this.blocks) {
        var fogBlock = this.blocks[i];
        if (fogBlock.options.available !== false && fogBlock.state === FogBlock.CANOPEN
            && !fogBlock.isMoney() && fogBlock.canOpen() && fogBlock.isReady()) {
            return this.blocks[i];
        }
    }
};

Fogs.prototype.findTile = function (x, y) {
    for (var i in this.blocks) {
        var fogBlock = this.blocks[i];
        var tile = fogBlock.findTile(x, y);
        if (tile) {
            return tile;
        }
    }
};

Fogs.prototype.findFakeUnit = function (targetUnit) {
    for (var row in this.fakeUnits) {
        for (var col in this.fakeUnits[row]) {
            if (Unit.IsApplicable(targetUnit, this.fakeUnits[row][col])) {
                return this.fakeUnits[row][col];
            }
        }
    }
};

Fogs.prototype.listFakeUnits = function (filter) {
    if (filter && typeof filter !== "function") {
        filter = Unit.IsApplicable.bind(Unit, filter);
    }

    var units = [];

    for (var row in this.fakeUnits) {
        for (var col in this.fakeUnits[row]) {
            if (!this.fakeUnits[row][col].head && filter(this.fakeUnits[row][col])) {
                units.push(this.fakeUnits[row][col]);
            }
        }
    }

    return units;
};

Fogs.prototype.getFakeUnit = function (x, y) {
    return this.fakeUnits[x] && this.fakeUnits[x][y];
};

Fogs.prototype.isPresentOnMap = function (unit) {
    if (!this.presentedUnits) {
        var presentedUnits = {};

        for (var row in this.fakeUnits) {
            for (var col in this.fakeUnits[row]) {
                if (!this.fakeUnits[row][col].head) {
                    presentedUnits[Unit.GetKey(this.fakeUnits[row][col])] = true;
                }
            }
        }

        this.presentedUnits = presentedUnits;
    }

    return this.presentedUnits[Unit.GetKey(unit)];
};
