/**
 * Created by r4zi4l on 17.01.2022
 */

var IngredientsTab = cc.Node.extend({
    ctor: function () {
        this._super();
        this.setContentSize2(cleverapps.styles.OrdersWindow);

        var styles = cleverapps.styles.IngredientsTab;

        if (styles.shelves) {
            styles.shelves.forEach(function (styles) {
                var shelf = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.ow_shelf, cleverapps.UI.Scale9Rect.TwoPixelXY);
                shelf.setContentSize2(cleverapps.styles.OrdersWindow.width + styles.padding, shelf.height);
                shelf.setPositionRound(styles);
                this.addChild(shelf);
            }, this);
        }

        this.createGrid();

        Map2d.currentMap.customers.on("onRecipesUpdated", this.createGrid.bind(this), this);
    },

    createGrid: function () {
        if (this.grid) {
            this.grid.removeFromParent();
            delete this.grid;
        }

        if (this.ingredientsBackgroundsGrid) {
            this.ingredientsBackgroundsGrid.removeFromParent();
            delete this.ingredientsBackgroundsGrid;
        }

        var styles = cleverapps.styles.IngredientsTab.grid;

        var icons = Game.currentGame.harvested.listResources().filter(function (ingredient) {
            return ingredient.amount > 0;
        }).map(function (ingredient) {
            return new IngredientOrderIcon(ingredient);
        });

        if (bundles.orderswindow.frames.ingredients_background) {
            var ingredientsBackgrounds = icons.map(function (icon) {
                var ingredientsBackground = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.ingredients_background, cleverapps.UI.Scale9Rect.TwoPixelXY);
                ingredientsBackground.setContentSize2(icon.width, icon.height);
                return ingredientsBackground;
            });

            this.ingredientsBackgroundsGrid = new cleverapps.GridLayout(ingredientsBackgrounds, {
                columns: styles.columns,
                margin: styles.margin
            });
            this.ingredientsBackgroundsGrid.setPositionRound(styles);
            this.addChild(this.ingredientsBackgroundsGrid);
        }

        this.grid = new cleverapps.GridLayout(icons, {
            columns: styles.columns,
            margin: styles.margin
        });
        this.grid.setPositionRound(styles);
        this.addChild(this.grid);
    }
});

cleverapps.styles.IngredientsTab = {
    grid: {
        columns: 6,

        x: { align: "center", dx: -5 },
        y: { align: "top", dy: -190 },

        margin: {
            x: 30,
            y: 50
        }
    },

    shelves: [{
        x: { align: "center" },
        y: { align: "center", dy: 110 },
        padding: 100
    }, {
        x: { align: "center" },
        y: { align: "center", dy: -80 },
        padding: 100
    }, {
        x: { align: "center" },
        y: { align: "center", dy: -270 },
        padding: 100
    }]
};
