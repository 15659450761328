/**
 * Created by mac on 12/23/20
 */

var MineableView = cc.Node.extend({
    ctor: function (mine, unitView) {
        this._super();

        this.mine = mine;
        this.unitView = unitView;
        this.visible = false;

        this.createProgress();
        this.restoreState();
    },

    restoreState: function (animated) {
        var worker = Map2d.currentMap.workers.findAssigned(this.mine.unit);

        if (this.progress) {
            if (cleverapps.gameModes.hideGuideAndProgress || InfoView.IsDisplayedFor(this.mine.unit) || this.mine.mined === 0 || worker) {
                this.hideProgress(animated);
            } else {
                this.showProgress(animated);
            }

            this.updateProgress(animated);
        }

        if (this.mine.isFree()) {
            this.createFreePrize(animated);
        } else {
            this.removeFreePrize(animated);
        }

        if (worker) {
            worker.onUpdateState();
        }
    },

    createProgress: function () {
        var styles = cleverapps.styles.MineableView;
        if (this.mine.endless) {
            var energyIcon = new cc.Sprite(bundles.merge.frames.mineable_energy);
            this.unitView.addChild(energyIcon);
            energyIcon.setAnchorPoint(0.5, 0.5);
            energyIcon.setPositionRound(styles.energy);
            return;
        }

        if (this.mine.countStages() < 2) {
            return;
        }

        this.progress = new ScaledProgressBar({
            type: ScaledProgressBar.Types.blue_small_with_frames
        });
        this.progress.hidden = false;
        this.unitView.addChild(this.progress);
        this.progress.setAnchorPoint(0.5, 0.5);
        this.progress.setLength(styles.progress.width);
        this.progress.setPositionRound(styles.progress);

        var fog = Map2d.currentMap.getFog(this.unitView.unit.x - 1, this.unitView.unit.y + 1);
        if (fog && styles.progress.upY) {
            this.progress.setPositionRound(styles.progress.x, styles.progress.upY);
        }
    },

    updateProgress: function (animated) {
        this.progress.setGoal(this.mine.unit.getData().mineable.length);
        var current = this.mine.unit.getData().mineable.length - this.mine.mined;
        this.progress.setPercentage(current, {
            animated: animated
        });
    },

    showProgress: function (animated) {
        if (!this.progress.hidden) {
            return;
        }
        this.progress.hidden = false;
        this.progress.stopAllActions();

        if (!animated) {
            this.progress.setVisible(true);
            return;
        }

        var styles = cleverapps.styles.MineableView;

        this.progress.setPositionY(this.progress.y + styles.progress.moveByY);
        this.progress.setScale(0.5);
        this.progress.setVisible(true);

        this.progress.runAction(new cc.Spawn(
            new cc.MoveBy(0.1, 0, -styles.progress.moveByY),
            new cc.ScaleTo(0.1, 1)
        ));
    },

    hideProgress: function () {
        if (this.progress.hidden) {
            return;
        }
        this.progress.hidden = true;
        this.progress.stopAllActions();
        this.progress.setVisible(false);
    },

    createFreePrize: function (animated) {
        var prizeView = new PrizeView({ sprite: bundles.merge.frames.simple_info_prize }, {
            onClick: this.mine.unit.handleClick.bind(this.mine.unit),
            unit: this.mine.unit
        });
        prizeView.setPositionRound(this.unitView.width / 2, this.unitView.height);

        if (animated) {
            this.unitView.animateAppearAdditionalView("free", prizeView);
        } else {
            this.unitView.createAdditionalView("free", prizeView);
        }
    },

    removeFreePrize: function (animated) {
        this.unitView.removeAdditionalView("free", !animated);
    }
});

cleverapps.styles.MineableView = {
    progress: {
        moveByY: 15,

        width: 84,
        x: { align: "center" },
        y: { align: "bottom", dy: 5 }
    },

    energy: {
        x: { align: "center", dx: 50 },
        y: { align: "top", dy: 10 }
    }
};