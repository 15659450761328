/**
 * Created by r4zi4l on 10.09.2021
 */

var StartQuestWindow = CleverappsWindow.extend({
    onWindowLoaded: function (quests) {
        this._super({
            name: "StartQuestWindow",
            content: this.createContent(quests),
            noBackground: true,
            closeButtonDelay: true
        });
    },

    getPerson: function () {
        return "dwarf";
    },

    createContent: function (quests) {
        var styles = cleverapps.styles.StartQuestWindow;

        var tiles = this.tiles = quests.map(this.createQuestTile.bind(this, quests), this);

        var tilesLayout = new cleverapps.Layout(tiles, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.tiles.margin
        });

        var title = this.title = cleverapps.UI.generateOnlyText(quests.length === 1 ? "StartQuestWindow.oneQuest.title" : "StartQuestWindow.title", cleverapps.styles.FONTS.STARTQUESTWINDOW_TITLE_TEXT);
        title.setDimensions(styles.title.width, 0);
        title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        return new cleverapps.Layout([title, tilesLayout], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createQuestTile: function (quests, quest) {
        var styles = cleverapps.styles.StartQuestWindow.tile;

        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setContentSize2(styles);
        container.quest = quest;

        var animation = container.animation = new cleverapps.Spine(bundles.startquest.jsons.quest_tile_json);
        animation.setAnchorPoint(0.5, 0.5);
        animation.setPositionRound(styles.animation);
        container.addChild(animation);

        var icon = container.icon = new QuestIcon(quest, {
            images: QuestIcon.Images.questsgroup
        });
        icon.setAnchorPoint(0.5, 0.5);
        icon.setPositionRound(styles.icon);
        icon.setScale(styles.icon.scale);
        container.addChild(icon);

        var task = container.task = cleverapps.UI.generateOnlyText(this.formatQuestTitleText(quest), cleverapps.styles.FONTS.STARTQUESTWINDOW_TILE_TASK_TEXT);
        task.setDimensions(styles.task.width, 0);
        task.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        task.fitTo(undefined, styles.task.height);
        task.setPositionRound(styles.task);
        container.addChild(task);

        if (quests.length > 1) {
            container.setScale(styles.doubleQuest.scale);
        }

        return container;
    },

    formatQuestTitleText: function (quest) {
        var text = quest.getTitle();
        var index = text.indexOf(": ");
        if (index !== -1) {
            ++index;
            return text.substr(0, index) + "\n" + text.substr(index + 1);
        }
        return text;
    },

    onShow: function () {
        this._super();

        this.title.setScale(0.3);
        this.title.runAction(new cc.Sequence(
            new cc.ScaleTo(0.26, 1.4),
            new cc.ScaleTo(0.37, 1)
        ));

        var delay = 0.1;
        this.tiles.forEach(function (tile) {
            tile.animation.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.PlaySound(bundles.startquest.urls.quest_new_effect),
                new cc.CallFunc(tile.animation.setAnimation.bind(tile.animation, 0, "open", false))
            ));

            tile.task.setCascadeOpacityEnabledRecursively(true);
            tile.task.setOpacity(0);
            tile.task.runAction(new cc.Sequence(
                new cc.DelayTime(delay + 0.6),
                new cc.FadeIn(0.8)
            ));

            tile.icon.setVisible(false);
            tile.icon.runAction(new cc.Sequence(
                new cc.ScaleTo(0, 0),
                new cc.DelayTime(delay + 0.6),
                new cc.Show(),
                new cc.ScaleTo(0.5, tile.icon.scale).easing(cc.easeBackOut(1))
            ));

            delay += 0.5;
        }, this);
    },

    closeAnimation: function (callback) {
        callback();
    },

    beforeCloseAnimation: function (callback) {
        var delay = 0.2;

        var questsGroupIcon = cleverapps.sideBar.findIconByClassName(QuestsGroupIcon);
        cleverapps.focusManager.showControlsWhileFocused("QuestsGroupIcon");
        var target = cleverapps.aims.getTarget("quests");
        cleverapps.aims.showTarget(target);

        var callCallback = cleverapps.wait(this.tiles.length, callback);

        this.tiles.forEach(function (tile, index) {
            tile.replaceParentSamePlace(cleverapps.scenes.getMovingNode(target), {
                keepScale: true
            });

            tile.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.CallFunc(function () {
                    tile.animation.setAnimation(0, "close", false);
                    tile.task.stopAllActions();
                    tile.task.runAction(new cc.FadeOut(0.2));

                    if (index === 0) {
                        questsGroupIcon.onScrollToItemListener();
                    }

                    tile.icon.runAction(
                        new cc.Sequence(
                            new cc.DelayTime(index * 0.2 + 0.2),
                            new cc.Spawn(
                                AnimationsLibrary.animateCollect(tile.icon, target, {
                                    duration: 0.6,
                                    jump: true,
                                    scale: 1,
                                    sound: bundles.sidebar.urls.quest_fly_effect
                                }),
                                new cc.RotateTo(0.2, -15).easing(cc.easeOut(1)),
                                new cc.Sequence(
                                    new cc.DelayTime(0.4),
                                    new cc.CallFunc(function () {
                                        questsGroupIcon.addQuestIcon(tile.icon, { fromWindow: true });
                                    }),
                                    new cc.RotateTo(0.3, 0).easing(cc.easeBackOut())
                                )
                            )
                        )
                    );
                }),
                new cc.DelayTime(1),
                new cc.CallFunc(callCallback),
                new cc.RemoveSelf()
            ));

            delay += 0.4;
        }, this);

        this.hideSelf();
    },

    listBundles: function (quests) {
        return ["startquest"].concat(StartQuestWindow.ListLazyBundles(quests));
    }
});

StartQuestWindow.ListLazyBundles = function (quests) {
    var bundles = [];

    if (quests) {
        var families = cleverapps.toArray(quests).map(function (quest) {
            return quest.unit && quest.unit.code; 
        }).filter(Boolean);
        bundles = bundles.concat(Families.listLazyBundles(families));
    }

    return bundles;
};

cleverapps.styles.DECORATORS = cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    STARTQUESTWINDOW_TILE_TASK_SHADOW: {
        color: new cc.Color(255, 255, 203, 255),
        direction: cc.size(0, -3),
        blur: 0
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    STARTQUESTWINDOW_TITLE_TEXT: {
        size: 120,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    STARTQUESTWINDOW_TILE_TASK_TEXT: {
        size: 90,
        color: new cc.Color(128, 71, 44, 255),
        shadow: cleverapps.styles.DECORATORS.STARTQUESTWINDOW_TILE_TASK_SHADOW
    }
});

cleverapps.styles.StartQuestWindow = {
    width: 1800,
    height: 1000,

    margin: 0,

    title: {
        width: 1200
    },

    tiles: {
        margin: 40
    },

    tile: {
        width: 1300,
        height: 900,

        doubleQuest: {
            scale: 0.9
        },

        animation: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        },

        icon: {
            x: { align: "center", dx: -260 },
            y: { align: "center", dy: -10 },
            scale: 1.4
        },

        task: {
            x: { align: "center", dx: 175 },
            y: { align: "center", dy: -10 },
            width: 500,
            height: 200
        }
    }
};
