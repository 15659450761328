/**
 * Created by evgeny on 18.06.2024
 */

var Switchable = function (unit, options, choices) {
    UnitComponent.call(this, unit);

    this.unit = unit;
    this.choices = choices;
    this.choice = options.choice;
};

Switchable.prototype = Object.create(UnitComponent.prototype);
Switchable.prototype.constructor = Switchable;

Switchable.prototype.save = function (data) {
    if (this.choice !== undefined) {
        data.choice = this.choice;
    }
};

Switchable.prototype.getChoice = function () {
    return this.choice;
};

Switchable.prototype.setChoice = function (choice, f) {
    f = f || function () {};

    if (this.choice === choice) {
        f();
        return;
    }
    var silent = this.choice !== undefined;

    var x = this.unit.x;
    var y = this.unit.y;
    var newUnit = new Unit({
        code: this.unit.code,
        stage: this.unit.stage,
        choice: choice
    });

    this.view.beforeChangeChoice(function () {
        newUnit.setPosition(x, y);
        Map2d.currentMap.onAddUnit(x, y, newUnit);
        Map2d.currentMap.add(Map2d.LAYER_UNITS, x, y, newUnit);
        Map2d.currentMap.onUnitAvailable(newUnit);

        newUnit.findComponent(Switchable).afterChangeChoice(f, silent);
    });
};

Switchable.prototype.afterChangeChoice = function (f) {
    var highlighter = Map2d.currentMap.onDiscoverMapView().highlighter;
    if (highlighter) {
        highlighter.addUnit(this.unit);
    }
    this.view.afterChangeChoice(f);
};

Switchable.prototype.showChoice = function (choice, silent) {
    choice = choice === undefined ? this.choice : choice;
    if (this.view) {
        this.view.showChoice(choice, silent);
    }
};

Switchable.prototype.animateHover = function () {
    if (this.view) {
        this.view.animateHover();
    }
};

Switchable.prototype.stopAnimateHover = function () {
    if (this.view) {
        this.view.stopAnimateHover();
    }
};

Switchable.prototype.handleClick = function () {
    cleverapps.meta.getMainObject().handleMakeChoiceWindow(this);
};