/**
 * Created by mac on 12/23/20
 */

var HeroTutorialView = cc.Node.extend({
    ctor: function (heroTutorial, unitView) {
        this._super();

        this.heroTutorial = heroTutorial;
        this.unitView = unitView;

        this.restoreState();
    },

    restoreState: function () {
        var styles = cleverapps.styles.HeroTutorialView;

        if (this.heroTutorial.isHidden) {
            this.unitView.visible = false;

            this.heroTutorial.isHidden = false;
        } else if (this.heroTutorial.isTutorialRunning) {
            this.unitView.sprite.setAnimation(0, "idle_unconscious", true);
            this.unitView.sprite.setCompleteListener(function () {});
            this.unitView.sprite.setSafeToRemove();

            this.unitView.clickRect = cc.rect(styles.unit.clickRect);

            if (this.heroTutorial.isRemovingDebris) {
                this.barrelAnimation = this.createBarrel();
            }

            this.hoverHandler = cleverapps.UI.applyHover(this.unitView);
        }
    },

    createBarrel: function () {
        var styles = cleverapps.styles.HeroTutorialView;

        var barrel = new cleverapps.Spine(bundles.merge.jsons.tutorial_barrel_json);
        this.addChild(barrel);
        barrel.setPositionRound(styles.barrel.offsetX, styles.barrel.offsetY);
        barrel.setAnimation(0, "idle_" + this.heroTutorial.stage, true);

        this.unitView.clickRect = cc.rect(styles.barrel.clickRect);

        this.hoverHandler = cleverapps.UI.applyHover(this.unitView);

        return barrel;
    },

    waveAnimation: function () {
        var styles = cleverapps.styles.HeroTutorialView;

        var waveAnimation = new cleverapps.Spine(bundles.merge.jsons.tutorial_wave_json);

        var mapView = Game.currentGame.map.getMapView();

        var position = mapView.alignInIsometricGrid(this.heroTutorial.unit.x, this.heroTutorial.unit.y);

        mapView.animations.addChild(waveAnimation);
        waveAnimation.setPositionRound(position.x + styles.wave.offsetX, position.y + styles.wave.offsetY);
        waveAnimation.setAnimation(0, "animation", false);
        waveAnimation.setCompleteListenerRemove();
        waveAnimation.setSafeToRemove();

        cleverapps.audio.playSound(bundles.merge.urls.tutorial_barrel_spawn_sfx);

        this.runAction(
            new cc.Sequence(
                new cc.DelayTime(1.25),
                new cc.CallFunc(function () {
                    this.unitView.visible = true;
                    this.unitView.sprite.setAnimation(0, "idle_unconscious", true);
                    this.unitView.sprite.setCompleteListener(function () {});
                    this.unitView.sprite.setSafeToRemove();

                    this.barrelAnimation = this.createBarrel();
                    this.barrelAnimation.setAnimationAndIdleAfter("in", "idle_0");
                    this.barrelAnimation.setSafeToRemove();
                }.bind(this))
            )
        );
    },

    animateRemoveDebris: function () {
        var styles = cleverapps.styles.HeroTutorialView;

        if (this.heroTutorial.stage === HeroTutorial.REMOVE_DEBRIS_STAGES - 1) {
            this.barrelAnimation.setAnimation(0, "transition_" + this.heroTutorial.stage, false);
            this.barrelAnimation.setCompleteListenerRemove();
            this.unitView.sprite.setSafeToRemove();

            this.unitView.clickRect = cc.rect(styles.unit.clickRect);

            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.3),
                new cc.CallFunc(function () {
                    this.unitView.sprite.setAnimationAndIdleAfter("fountain", "idle_unconscious");

                    cleverapps.audio.playSound(bundles.merge.urls.tutorial_resuscitate_sfx);
                }, this)
            ));
        } else {
            this.barrelAnimation.setAnimationAndIdleAfter("transition_" + this.heroTutorial.stage, "idle_" + (this.heroTutorial.stage + 1));
        }

        cleverapps.audio.playSound(bundles.merge.urls["tutorial_transition_sfx_" + this.heroTutorial.stage]);
    },

    animateResuscitation: function () {
        if (this.heroTutorial.stage < HeroTutorial.RESUSCITATE_STAGES - 1) {
            this.unitView.sprite.setAnimationAndIdleAfter("fountain", "idle_unconscious");
        } else {
            this.unitView.sprite.setAnimation(0, "fountain", false);
            this.unitView.sprite.setSafeToRemove();

            delete this.unitView.clickRect;

            if (this.hoverHandler) {
                this.hoverHandler.remove();
                delete this.hoverHandler;
            }

            this.unitView.sprite.setCompleteListener(function () {
                this.unitView.sprite.setAnimation(0, "wakeup", false);

                cleverapps.audio.playSound(bundles.merge.urls.tutorial_finish_sfx);

                this.unitView.sprite.setSafeToRemove();
                this.unitView.sprite.setCompleteListener(function () {
                    UnitView.applyDefaultAnimation(this.unitView.sprite, this.unitView.unit);
                }.bind(this));
            }.bind(this));
        }

        cleverapps.audio.playSound(bundles.merge.urls.tutorial_resuscitate_sfx);
    }
});

cleverapps.styles.HeroTutorialView = {
    wave: {
        offsetX: -260,
        offsetY: -120
    },

    unit: {
        clickRect: {
            x: 30,
            y: -10,
            width: 200,
            height: 120
        }
    },

    barrel: {
        offsetX: 63,
        offsetY: -78,

        clickRect: {
            x: 10,
            y: -40,
            width: 200,
            height: 150
        }
    }
};