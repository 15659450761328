/**
 * Created by mac on 12/28/20
 */

var Mines = {
    fruit: FruitMine.prize,
    source: SourceMine.prize,
    generator: GeneratorSourceMine.prize,
    monster: MonsterMine.prize,
    coinstree: ValuableMine.prize,
    energytree: ValuableMine.prize,
    magicplant: MagicplantMine.prize
};
