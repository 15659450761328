/**
 * Created by iamso on 22.11.2021
 */

var UnitStoriesData = {};

var UnitStoriesInitialize = cleverapps.once(function () {
    var data = {};

    var priority = [bundles.unit_stories_data.jsons.unitStories_json];
    var jsons = Object.values(bundles.unit_stories_data.jsons);
    jsons.sort(function (a, b) {
        return priority.indexOf(a) - priority.indexOf(b);
    });
    jsons.forEach(function (json) {
        var res = json.resolve().getJson();
        if (res) {
            if (res.skin) {
                data[res.skin] = cleverapps.clone(res.unitStories, true);
            } else {
                cleverapps.override(data, res.unitStories);
            }
        }
    });

    if (data.fog_fog4_0 && data.fog_fog4_0.default) {
        data.fog_fog4_0.default.target = { code: "wood", stage: 0 };
    }
    UnitStoriesData = data;
});
