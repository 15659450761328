/**
 * Created by razial on 12.10.2021
 */

var MissionTree = function (unit) {
    UnitComponent.call(this, unit);

    this.missionType = Mission.CompoundType(unit.getData().mission, cleverapps.travelBook.getCurrentPage().missionType);

    this.nextStage = unit.stage;
    this.multiline = false;
    this.current = false;

    if (cleverapps.config.adminMode) {
        return;
    }

    if (!MissionTree.targets[this.missionType]) {
        MissionTree.targets[this.missionType] = [];
    }

    MissionTree.targets[this.missionType].push(this);

    var mission = this.getMission();

    if (!mission && MissionManager.hasProperParent(Missions[this.missionType])) {
        cleverapps.missionManager.manualStart(this.missionType, {
            fromMissionTree: true
        });
        mission = cleverapps.missionManager.findByType(this.missionType);
    }

    var logic = mission && mission.logic;
    if (logic) {
        if (logic.onLevelChangedListeners && logic.onUpdateListeners && logic.onProgressChangedListeners) {
            logic.onLevelChangedListeners[this.unit.code] = this.onMissionLevelChanged.bind(this);
            logic.onUpdateListeners[this.unit.code] = this.onUpdatePrizes.bind(this);
            logic.onProgressChangedListeners[this.unit.code] = this.onUpdateState.bind(this);
        }

        this.onMissionLevelChanged();
        this.onUpdatePrizes();
    }
};

MissionTree.prototype = Object.create(UnitComponent.prototype);
MissionTree.prototype.constructor = MissionTree;

MissionTree.prototype.getView = function () {
    return this.view;
};

MissionTree.prototype.onCreate = function () {
    if (this.view) {
        this.view.onCreate();
    }
};

MissionTree.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    }
};

MissionTree.prototype.getMission = function () {
    return cleverapps.missionManager.findByType(this.missionType);
};

MissionTree.prototype.destructor = function () {
    var mission = this.getMission();
    var logic = mission && mission.logic;

    if (logic) {
        if (logic.onLevelChangedListeners && logic.onUpdateListeners && logic.onProgressChangedListeners) {
            logic.onLevelChangedListeners[this.unit.code] = function () {};
            logic.onUpdateListeners[this.unit.code] = function () {};
            logic.onProgressChangedListeners[this.unit.code] = function () {};
        }
    }

    var targets = MissionTree.targets[this.missionType];
    if (targets) {
        var index = targets.indexOf(this);
        if (index !== -1) {
            targets.splice(index, 1);
        }
    }
};

MissionTree.prototype.calcState = function () {
    var codes = cleverapps.unitsLibrary.listCodesByType(this.unit.getType(), cleverapps.travelBook.getCurrentPage().id);
    var state = {
        stage: this.unit.stage,
        multiline: codes.length > 1
    };

    var mission = this.getMission();
    if (!mission || !mission.logic || !(mission.logic instanceof PassMissionLogic)) {
        return state;
    }

    var level = 0;

    for (var i = 0; i < codes.length; ++i) {
        var code = codes[i];
        var units = UnitsLibrary.ListByCode(code);
        var total = units.length - 1;

        if (code !== this.unit.code) {
            level += total;
            continue;
        }

        var nextStage = mission.logic.shownProgress.level - level;
        if (nextStage < 0) {
            state.stage = units[0].stage;
        } else if (nextStage >= total) {
            state.stage = units[total].stage;
        } else {
            state.stage = units[nextStage].stage;
            state.current = true;
        }

        return state;
    }
};

MissionTree.prototype.showHint = function () {
    if (this.isCurrentTree()) {
        cleverapps.centerHint.create({
            currentTree: this.unit
        });
        return;
    }

    var current = Map2d.currentMap.listAvailableUnits(function (unit) {
        var missionTree = unit.findComponent(MissionTree);
        return missionTree && missionTree.isCurrentTree() && missionTree.missionType === this.missionType;
    }.bind(this))[0];

    if (!current) {
        cleverapps.centerHint.createTextHint("MissionTree.hint.notfound");
        return;
    }

    cleverapps.centerHint.create(this.unit.isLast() ? {
        nextTree: current
    } : {
        previousTree: current
    });

    FingerView.hintClick({ x: current.x, y: current.y }, { runOnce: true });
    Map2d.currentMap.getScrollView().runAction(new cc.CellScrollAction(current, { allowScrollWithFocus: true }).easing(cc.easeInOut(2)));
};

MissionTree.prototype.onMissionLevelChanged = function () {
    var state = this.calcState();
    this.nextStage = state.stage;
    this.current = state.current;
    this.multiline = state.multiline;
    MissionTree.wantsToUpdateUnitStage = true;
    this.onUpdateState();
};

MissionTree.prototype.wantsChangeStage = function () {
    return this.nextStage !== this.unit.stage;
};

MissionTree.prototype.changeStage = function () {
    if (!this.wantsChangeStage()) {
        return;
    }

    var view = this.view;

    this.unit.remove(true);

    var newUnit = new Unit({
        code: this.unit.code,
        stage: this.nextStage
    });
    newUnit.setPosition(this.unit.x, this.unit.y);
    Map2d.currentMap.onUnitAvailable(newUnit);
    Map2d.currentMap.onAddUnit(newUnit.x, newUnit.y, newUnit);

    if (view) {
        var newMissionTree = newUnit.findComponent(MissionTree);
        newMissionTree.getView().onUpgrade(view);
    }

    return newMissionTree;
};

MissionTree.prototype.isCurrentTree = function () {
    return !this.multiline || this.current;
};

MissionTree.prototype.needsProgress = function () {
    return this.current;
};

MissionTree.prototype.needsHelp = function () {
    return this.multiline && this.current;
};

MissionTree.prototype.calcPercent = function () {
    var mission = this.getMission();
    if (mission.logic.progress.level > mission.logic.shownProgress.level || !mission.logic.levels[mission.logic.progress.level]) {
        return 100;
    }
    if (mission.logic.progress.level < mission.logic.shownProgress.level) {
        return 0;
    }
    return mission.logic.progress.progress / mission.logic.levels[mission.logic.progress.level].task.goal * 100;
};

MissionTree.prototype.spawnPrizes = function () {
    var pageId = cleverapps.travelBook.getCurrentPage().id;
    if (MissionTree.PRIZES[pageId]) {
        var amountFree = Map2d.currentMap.countEmptySlots();
        var prizes = Prizes.Generate(MissionTree.PRIZES[pageId]).slice(0, amountFree);
        if (prizes.length) {
            Game.currentGame.spawn(prizes, this.unit, { radius: Map2d.RADIUS_SMALL });
            return true;
        }
    }
};

MissionTree.prototype.handlePrizes = function () {
    this.handleClick();

    return true;
};

MissionTree.prototype.onUpdatePrizes = function () {
    var mission = this.getMission();
    if (mission && mission.logic && mission.logic.countAvailableRewards && mission.logic.countAvailableRewards() > 0 && this.isCurrentTree()) {
        this.unit.setPrizes({ sprite: bundles.merge.frames.prize_exclamation });
    } else {
        this.unit.setPrizes();
    }
};

MissionTree.prototype.handleClick = function () {
    var mission = this.getMission();
    if (!mission || !mission.isRunning()) {
        cleverapps.centerHint.createTextHint("MissionNotStarted." + Missions[this.missionType].name);
        return false;
    }

    this.unit.squeeze();
    cleverapps.audio.playSound(bundles.main.urls.click_effect);

    if (!this.isCurrentTree()) {
        this.showHint();
        return true;
    }

    cleverapps.focusManager.display({
        stack: Game.currentGame.tutorial.checkTargets(this.unit),
        focus: "missionWindow",
        actions: [
            function (f) {
                cleverapps.timeouts.setTimeout(f, 300);
            },

            function (f) {
                var ViewClass = mission.getViewClass();
                new ViewClass(mission);

                cleverapps.focusManager.onceNoWindowsListener = f;
            },

            function (f) {
                f();
                Map2d.mapEvent(Map2d.MISSION_TREE_CLOSED, { affected: this.unit });
            }.bind(this)
        ]
    });

    return true;
};

MissionTree.findReadyForUpdateUnit = function () {
    for (var missionType in MissionTree.targets) {
        var targets = MissionTree.targets[missionType];
        if (!targets.length) {
            continue;
        }

        for (var i = 0; i < targets.length; ++i) {
            var missionTree = targets[i];

            if (Map2d.currentMap.getUnit(missionTree.unit.x, missionTree.unit.y) !== missionTree.unit) {
                continue;
            }

            if (missionTree.wantsChangeStage()) {
                return missionTree;
            }
        }
    }
};

MissionTree.processStateChange = function () {
    var missionTree = MissionTree.findReadyForUpdateUnit();
    if (!missionTree) {
        MissionTree.wantsToUpdateUnitStage = false;
        return;
    }

    cleverapps.focusManager.display({
        focus: "updateBuildPassUnit",
        actions: [
            function (f) {
                Map2d.currentMap.getScrollView().runAction(new cc.Sequence(
                    new cc.CellScrollAction(missionTree.unit, { allowScrollWithFocus: true }).easing(cc.easeInOut(2)),
                    new cc.CallFunc(f)
                ));
            },

            function (f) {
                var newMissionTree = missionTree.changeStage();
                if (newMissionTree) {
                    cleverapps.timeouts.setTimeout(f, 2000);
                } else {
                    f();
                }
            },

            function (f) {
                if (missionTree.spawnPrizes()) {
                    cleverapps.timeouts.setTimeout(f, 1100);
                } else {
                    f();
                }
            }
        ]
    });
};

MissionTree.targets = {};

MissionTree.PRIZES = {};
