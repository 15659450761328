/**
 * Created by vladislav on 25/10/2022
 */

QuestsConfig.halloween = cleverapps.clone(QuestsConfig.universal, true);

delete QuestsConfig.halloween.rpcustomermain0_0;
delete QuestsConfig.halloween.rpcustomermain0_1;
delete QuestsConfig.halloween.rpcustomerbridge1_0;
delete QuestsConfig.halloween.rpcustomermain1_0;
delete QuestsConfig.halloween.rpcustomermain1_1;
delete QuestsConfig.halloween.rpcustomerbridge2_0;
delete QuestsConfig.halloween.rpcustomermain2_0;
delete QuestsConfig.halloween.rpcustomermain2_1;
delete QuestsConfig.halloween.rpcustomerbridge3_0;
delete QuestsConfig.halloween.rpcustomermain3_0;
delete QuestsConfig.halloween.rpcustomermain3_1;
delete QuestsConfig.halloween.rpcustomerbridge4_0;
delete QuestsConfig.halloween.rpcustomerbridge4_1;
delete QuestsConfig.halloween.rpcustomermain4_0;
delete QuestsConfig.halloween.rpcustomermain4_1;
delete QuestsConfig.halloween.rpproducer4;
delete QuestsConfig.halloween.rpproduct4;

QuestsConfig.halloween.rpcustomerbridge0_0.trigger = { fog: "fog1" };
QuestsConfig.halloween.rpcustomerbridge1_1.trigger = { unit: { code: "rpcustomermain0a", stage: 1 } };
QuestsConfig.halloween.rpcustomerbridge2_1.trigger = { unit: { code: "rpcustomermain1a", stage: 1 } };
QuestsConfig.halloween.rpcustomerbridge3_1.trigger = { unit: { code: "rpcustomermain2a", stage: 1 } };
QuestsConfig.halloween.rpproducer3.trigger = { fog: "fog1" };

QuestsConfig.halloween.rpcustomermain1a_0 = {
    trigger: { unit: { code: "rpcustomermain0a", stage: 1 } },
    quest: {
        type: Map2d.OPEN_UNIT,
        unit: { code: "rpcustomermain1a", stage: 0 }
    }
};

QuestsConfig.halloween.rpcustomermain2a_0 = {
    trigger: { unit: { code: "rpcustomermain1a", stage: 1 } },
    quest: {
        type: Map2d.OPEN_UNIT,
        unit: { code: "rpcustomermain2a", stage: 0 }
    }
};

QuestsConfig.halloween.rpcustomermain3a_0 = {
    trigger: { unit: { code: "rpcustomermain2a", stage: 1 } },
    quest: {
        type: Map2d.OPEN_UNIT,
        unit: { code: "rpcustomermain3a", stage: 0 }
    }
};

QuestsConfig.halloween.rpcustomermain0a_1 = {
    trigger: { unit: { code: "rpcustomerbridge0", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain0a", stage: 1 }
    }
};
QuestsConfig.halloween.rpcustomermain1a_1 = {
    trigger: { unit: { code: "rpcustomerbridge1", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain1a", stage: 1 }
    }
};
QuestsConfig.halloween.rpcustomermain2a_1 = {
    trigger: { unit: { code: "rpcustomerbridge2", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain2a", stage: 1 }
    }
};
QuestsConfig.halloween.rpcustomermain3a_1 = {
    trigger: { unit: { code: "rpcustomerbridge3", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain3a", stage: 1 }
    }
};

QuestsConfig.halloween.rpproducer0.trigger.unit.code = "rpcustomermain0a";
QuestsConfig.halloween.rpproducer1.trigger.unit.code = "rpcustomermain1a";
QuestsConfig.halloween.rpproducer2.trigger.unit.code = "rpcustomermain2a";