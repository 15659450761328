/**
 * Created by mac on 5/25/21
 */

var HeroTutorial = function (unit) {
    UnitComponent.call(this, unit);

    this.stage = 0;
    this.isTutorialRunning = true;
    this.isRemovingDebris = true;
    this.isHidden = true;

    this.unit.movable = false;
};

HeroTutorial.prototype = Object.create(UnitComponent.prototype);
HeroTutorial.prototype.constructor = HeroTutorial;

HeroTutorial.prototype.start = function (callback) {
    cleverapps.focusManager.display({
        stack: true,
        focus: "customMergeTutorial",
        action: function (f) {
            this.view.waveAnimation();

            cleverapps.timeouts.setTimeout(function () {
                Game.currentGame.tutorial.showTutorial(MergeTutorials.dwarf_hustlemerge, function () {
                    cleverapps.timeouts.setTimeout(function () {
                        f();

                        callback();
                    }, 1000);
                });
            }, 2000);
        }.bind(this)
    });
};

HeroTutorial.prototype.handleClick = function () {
    if (this.isTutorialRunning) {
        if (this.isRemovingDebris) {
            this.view.animateRemoveDebris();

            this.stage++;

            if (this.stage === HeroTutorial.REMOVE_DEBRIS_STAGES) {
                this.isRemovingDebris = false;

                this.stage = 0;
            }
        } else if (this.stage < HeroTutorial.RESUSCITATE_STAGES) {
            this.view.animateResuscitation();

            this.stage++;

            if (this.stage === HeroTutorial.RESUSCITATE_STAGES) {
                cleverapps.timeouts.setTimeout(this.finish.bind(this), HeroTutorial.FINISH_ANIMATION_DURATION);
            }
        }

        return true;
    }

    return false;
};

HeroTutorial.prototype.finish = function () {
    this.isTutorialRunning = false;

    this.unit.movable = true;
};

HeroTutorial.FINISH_ANIMATION_DURATION = 1000;
HeroTutorial.REMOVE_DEBRIS_STAGES = 3;
HeroTutorial.RESUSCITATE_STAGES = 1;