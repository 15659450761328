/**
 * Created by vladislav on 25/10/2022
 */

QuestsConfig.rapunzel3 = cleverapps.clone(QuestsConfig.universal, true);

QuestsConfig.rapunzel3.rpplate_3 = {
    trigger: { unit: { code: "rpplate", stage: 1 } },
    tutorial: "rapunzel3_plate",
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpplate", stage: 3 }
    }
};

QuestsConfig.rapunzel3.rppans_3 = {
    trigger: { fog: "fog7" },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rppans", stage: 3 }
    }
};

QuestsConfig.rapunzel3.rpmugs_3 = {
    trigger: { fog: "fog10" },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpmugs", stage: 3 }
    }
};

QuestsConfig.rapunzel3.rpcustomermain0b_1 = {
    trigger: { unit: { code: "rpcustomermain0a", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain0b", stage: 1 }
    }
};
QuestsConfig.rapunzel3.rpcustomermain1b_1 = {
    trigger: { unit: { code: "rpcustomermain1a", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain1b", stage: 1 }
    }
};
QuestsConfig.rapunzel3.rpcustomermain1c_1 = {
    trigger: { unit: { code: "rpcustomermain1b", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain1c", stage: 1 }
    }
};
QuestsConfig.rapunzel3.rpcustomermain2b_1 = {
    trigger: { unit: { code: "rpcustomermain2a", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain2b", stage: 1 }
    }
};
QuestsConfig.rapunzel3.rpcustomermain2c_1 = {
    trigger: { unit: { code: "rpcustomermain2b", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain2c", stage: 1 }
    }
};
QuestsConfig.rapunzel3.rpcustomermain2d_1 = {
    trigger: { unit: { code: "rpcustomermain2c", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain2d", stage: 1 }
    }
};
QuestsConfig.rapunzel3.rpcustomermain3b_1 = {
    trigger: { unit: { code: "rpcustomermain3a", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain3b", stage: 1 }
    }
};
QuestsConfig.rapunzel3.rpcustomermain3c_1 = {
    trigger: { unit: { code: "rpcustomermain3b", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain3c", stage: 1 }
    }
};
QuestsConfig.rapunzel3.rpcustomermain3d_1 = {
    trigger: { unit: { code: "rpcustomermain3c", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain3d", stage: 1 }
    }
};

delete QuestsConfig.rapunzel3.rpcustomermain0_0;
delete QuestsConfig.rapunzel3.rpcustomermain0_1;
delete QuestsConfig.rapunzel3.rpcustomerbridge1_0;
delete QuestsConfig.rapunzel3.rpcustomermain1_0;
delete QuestsConfig.rapunzel3.rpcustomermain1_1;
delete QuestsConfig.rapunzel3.rpcustomerbridge2_0;
delete QuestsConfig.rapunzel3.rpcustomermain2_0;
delete QuestsConfig.rapunzel3.rpcustomermain2_1;
delete QuestsConfig.rapunzel3.rpcustomerbridge3_0;
delete QuestsConfig.rapunzel3.rpcustomermain3_0;
delete QuestsConfig.rapunzel3.rpcustomermain3_1;
delete QuestsConfig.rapunzel3.rpcustomerbridge4_0;
delete QuestsConfig.rapunzel3.rpcustomerbridge4_1;
delete QuestsConfig.rapunzel3.rpcustomermain4_0;
delete QuestsConfig.rapunzel3.rpcustomermain4_1;
delete QuestsConfig.rapunzel3.rpproducer4;
delete QuestsConfig.rapunzel3.rpproduct4;

QuestsConfig.rapunzel3.rpcustomermain0a_1 = {
    trigger: { unit: { code: "rpcustomerbridge0", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain0a", stage: 1 }
    }
};
QuestsConfig.rapunzel3.rpcustomermain1a_1 = {
    trigger: { unit: { code: "rpcustomerbridge1", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain1a", stage: 1 }
    }
};
QuestsConfig.rapunzel3.rpcustomermain2a_1 = {
    trigger: { unit: { code: "rpcustomerbridge2", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain2a", stage: 1 }
    }
};
QuestsConfig.rapunzel3.rpcustomermain3a_1 = {
    trigger: { unit: { code: "rpcustomerbridge3", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpcustomermain3a", stage: 1 }
    }
};
QuestsConfig.rapunzel3.rpproducer0.trigger.unit.code = "rpcustomermain0a";
QuestsConfig.rapunzel3.rpproducer1.trigger.unit.code = "rpcustomermain1a";
QuestsConfig.rapunzel3.rpproducer2.trigger.unit.code = "rpcustomermain2a";
QuestsConfig.rapunzel3.rpproducer3.trigger = { unit: { code: "rpcustomermain3a", stage: 0 } };
QuestsConfig.rapunzel3.rpcustomerbridge0_0.trigger = { fog: "fog1" };
QuestsConfig.rapunzel3.rpcustomerbridge1_1.trigger = { unit: { code: "rpcustomermain0b", stage: 1 } };
QuestsConfig.rapunzel3.rpcustomerbridge2_1.trigger = { unit: { code: "rpcustomermain1c", stage: 1 } };
QuestsConfig.rapunzel3.rpcustomerbridge3_1.trigger = { unit: { code: "rpcustomermain2d", stage: 1 } };