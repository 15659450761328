/**
 * Created by Denis Kuzin on 03 march 2022
 */

var ClanMessages = function () {
    this.onMessagesUpdated = function () {};

    this.messages = [];

    cleverapps.userClan.onLoaded = this.loadMessages.bind(this);
};

ClanMessages.prototype.loadMessages = function (callback) {
    callback = callback || function () {};

    if (cleverapps.userClan.id === undefined) {
        this.messages = [];

        callback();
        return;
    }

    this.loadMessagesRequest(function (data) {
        //        console.log("loadMessagesRequest", data);

        this.messages = data.messages.sort(function (a, b) {
            return a.messageId - b.messageId;
        }).map(function (message) {
            return new ClanMessage(message);
        });

        cleverapps.toolbar.resetByType(ToolbarItem.CLANS);
        this.onMessagesUpdated();
        callback();
    }.bind(this), function () {
        cleverapps.notification.create("ClanErrors.connectionError");
        callback();
    });
};

ClanMessages.prototype.updateMessages = function (messages) {
    (messages || []).forEach(function (messageData) {
        var index = this.messages.findIndex(function (message) {
            return message.messageId === messageData.messageId;
        });

        if (index !== -1) {
            this.messages[index].update(messageData);
        } else {
            this.messages.push(new ClanMessage(messageData));
        }
    }.bind(this));

    this.messages.sort(function (a, b) {
        return a.messageId - b.messageId;
    });
};

ClanMessages.prototype.loadMessagesRequest = function (onSuccess, onFailure) {
    var data = {
        userId: connector.platform.getUserID(),
        version: cleverapps.config.version
    };
    cleverapps.RestClient.post("/clans/message/list/" + cleverapps.userClan.id, data, onSuccess, onFailure);
};

ClanMessages.prototype.postMessage = function (messageData) {
    if (messageData.playerId === undefined) {
        messageData.playerId = connector.platform.getUserID();
    }
    if (messageData.clanId === undefined) {
        messageData.clanId = cleverapps.userClan.id;
    }

    var message = new ClanMessage(messageData);

    this.messages.push(message);
    this.onMessagesUpdated();
    cleverapps.audio.playSound(bundles.clan.urls.send_message_sfx);

    var revertMessage = function () {
        var index = this.messages.indexOf(message);
        if (index !== -1) {
            this.messages.splice(index, 1);
            this.onMessagesUpdated();
        }
    }.bind(this);

    cleverapps.RestClient.post("/clans/message/create", {
        userId: message.playerId,
        clanId: cleverapps.userClan.id,
        type: message.type
    }, function (data) {
        if (data.error) {
            cleverapps.notification.create(data.error);
            revertMessage();
        } else {
            message.posted(data.messageId);
        }
    }, function () {
        cleverapps.notification.create("ClanErrors.connectionError");
        revertMessage();
    });
};

ClanMessages.prototype.sendHelp = function (messageId, senderId) {
    cleverapps.audio.playSound(bundles.clan.urls.send_help_sfx);
    WaitWindow.show();

    cleverapps.RestClient.post("/clans/message/sendhelp", {
        messageId: messageId,
        userId: senderId,
        version: cleverapps.config.version
    }, function (data) {
        // console.log("sendHelp response", data);
        WaitWindow.hide();

        if (data.error) {
            cleverapps.notification.create(data.error);
        }

        this.updateMessages(data.messages);
        this.onMessagesUpdated();
    }.bind(this), function () {
        WaitWindow.hide();
        cleverapps.notification.create("ClanErrors.connectionError");
    });
};

ClanMessages.prototype.collectHelp = function (help, source) {
    var message = help.message;

    WaitWindow.show();

    cleverapps.RestClient.post("/clans/message/collecthelp", {
        userId: connector.platform.getUserID(),
        helpId: help.helpId
    }, function (data) {
        WaitWindow.hide();

        if (data.error) {
            cleverapps.notification.create(data.error);
        } else {
            help.collect(source);
            message.onHelpUpdated();
        }
    }, function () {
        WaitWindow.hide();
        cleverapps.notification.create("ClanErrors.connectionError");
    });
};

ClanMessages.prototype.collectGift = function (gift, source) {
    var message = gift.message;

    WaitWindow.show();

    cleverapps.RestClient.post("/clans/message/collectgift", {
        userId: connector.platform.getUserID(),
        giftId: gift.giftId
    }, function (data) {
        WaitWindow.hide();

        if (data.error) {
            cleverapps.notification.create(data.error);
        } else {
            gift.collect(source);
            message.onGiftUpdated();
        }
    }, function () {
        WaitWindow.hide();
        cleverapps.notification.create("ClanErrors.connectionError");
    });
};

ClanMessages.prototype.getUserMessageTimeout = function (messageType) {
    var timeout = ClansConfig.MESSAGE_FLOOD_TIMEOUT;
    var messages = this.messages.filter(function (message) {
        return message.playerId === connector.platform.getUserID();
    });

    if (ClansConfig.MESSAGES[messageType] && ClansConfig.MESSAGES[messageType].help) {
        timeout = ClansConfig.MESSAGE_HELP_TIMEOUT;
        messages = messages.filter(function (message) {
            return message.type === messageType;
        });
    }

    var message = messages[messages.length - 1];
    return message && Math.max(0, message.date + timeout - Date.now()) || 0;
};

ClanMessages.prototype.listMessages = function () {
    return this.messages.filter(function (message) {
        var timeLeft = message.getTimeLeft();
        return message.clanId === cleverapps.userClan.id && (timeLeft === undefined || timeLeft > 0);
    });
};

ClanMessages.prototype.listActiveRequests = function () {
    var messages = cleverapps.clanMessages.listMessages();
    return messages.filter(function (mes) {
        return mes.isHelpMessage() && mes.getTimeLeft() > 0;
    });
};

ClanMessages.prototype.listActiveGifts = function () {
    var messages = cleverapps.clanMessages.listMessages();
    return messages.filter(function (mes) {
        return mes.isGiftMessage() && mes.getTimeLeft() > 0;
    });
};

ClanMessages.prototype.canSendHelp = function () {
    return this.listActiveRequests().filter(function (req) {
        return req.canHelp() && !req.isUserMessage();
    }).length > 0;
};

ClanMessages.prototype.canGrabHelp = function () {
    return this.listActiveRequests().filter(function (req) {
        if (req.isUserMessage()) {
            return req.listHelp().some(function (help) {
                return !help.isReceived();
            });
        }
        return false;
    }).length > 0;
};

ClanMessages.prototype.canClaimGift = function () {
    return this.listActiveGifts().filter(function (req) {
        if (!req.isUserMessage()) {
            return req.gift && !req.gift.isReceived();
        }
        return false;
    }).length > 0;
};

ClanMessages.prototype.needsAttention = function () {
    return this.canGrabHelp() || this.canSendHelp() || this.canClaimGift();
};
