/**
 * Created by razial on 19.10.2021
 */

cleverapps.SkinManager.SKINS.undersea_expedition = cleverapps.SkinManager.SKINS.undersea2_expedition = cleverapps.SkinManager.SKINS.undersea3_expedition = {
    bundle: "expedition_undersea",

    slots: {
        workerSkin: function () {
            var json = cleverapps.skins.getSlot("worker_json") || bundles.mineable.jsons.worker_json;
            return cleverapps.Spine.hasSkin("undersea", json) ? "undersea" : undefined;
        },

        missionTableRowIcon: function (options) {
            if (Mission.GetChildType(options.type) === Mission.TYPE_EXPEDITION_FEAST) {
                return bundles.tablerow_icons.frames.feast_pearl;
            }
        },

        unitMoveAnimation: function () {
            return {
                json: bundles.merge.jsons.move_animation,
                zOrder: -1,
                sound: bundles.merge.urls.move_animation_effect
            };
        },

        gameAudio: function () {
            return bundles.expedition_undersea.urls.undersea_music;
        },

        bonusWorkerProductAnimation: function () {
            return cleverapps.config.name === "fairy" ? "animation" : "animation2";
        },

        bonusWorkerPersonSkin: function () {
            var animation = cleverapps.persons.getRole("narrator").json;
            return cleverapps.Spine.hasSkin("undersea", animation) ? "undersea" : undefined;
        },

        avatarFramesBundle: function () {
            return cleverapps.bundleLoader.isLoaded("snail_feast_undersea") ? "snail_feast_undersea" : undefined;
        },

        king: function () {
            return cleverapps.config.name === "fairy" ? "dwarf" : "king";
        },

        unitIcon: function (unit) {
            return bundles.unit_icons_undersea.frames["small_icon_family_" + unit.code];
        },

        unitOnCell: function () {
            return cleverapps.styles.UnitOnCellExpedition;
        },

        gameSceneBg: function () {
            return {
                scale9: bundles.expedition_undersea.frames.undersea_bg,
                animation: bundles.expedition_undersea.jsons.undersea_bg_animation,
                topAnimation: bundles.expedition_undersea.jsons.undersea_bg_animation_top,
                parallax: true
            };
        },

        merge_wand_png: bundles.menubar.frames.undersea_merge_wand_png,

        wand_icon_png: bundles.buttons_inlined_icons.frames.undersea_wand_icon_png,
        feed_icon_png: bundles.buttons_inlined_icons.frames.undersea_feed_icon_png,

        expedition_buildpass_icon_json: bundles.sidebar.jsons.undersea_expedition_buildpass_icon_json,
        expedition_feast_icon_json: bundles.sidebar.jsons.undersea_expedition_feast_icon_json,
        snail_feast_icon_json: bundles.sidebar.jsons.undersea_snail_feast_icon_json,
        snailhouse_offer_json: bundles.sidebar.jsons.undersea_snailhouse_offer_json,

        merge_animation_json: bundles.merge_animations.jsons.undersea_merge_animation_json,

        expedition_feast_icon: bundles.reward_icons.frames.undersea_expedition_feast_icon,
        snail_feast_icon: bundles.reward_icons.frames.undersea_snail_feast_icon,
        reward_wand_png: bundles.reward_icons.frames.undersea_reward_wand_png,
        reward_wand_small_png: bundles.reward_icons.frames.undersea_reward_wand_small_png,

        expeditionFeastBundle: function () {
            return bundles.expedition_feast_undersea;
        },

        rudolfGuideBundle: function () {
            return bundles.rudolfguidewindow_undersea;
        },

        getPoiAnimation: function (unit) {
            if (unit.code === "rpcustomermain3a") {
                return "idle_fog";
            }
        },

        fog_disappear_effect: function () {
            return bundles.fog_undersea.urls.open_effect;
        }
    }
};
