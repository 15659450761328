/**
 * Created by olga on 26.10.2022
 */

var MergeBonusHintContent = function (options) {
    this.unit = options.unit;
    this.amount = options.amount;

    this.doNotBlock = {
        x: this.unit.dragX,
        y: this.unit.dragY
    };
};

MergeBonusHintContent.prototype.hasSameContent = function (options) {
    return Unit.Equals(this.unit, options.unit);
};

MergeBonusHintContent.isApplicable = function (options) {
    var amount = options.amount;
    var unit = options.unit;
    if (!amount || !unit || options.wrongMerged) {
        return false;
    }
    var level = cleverapps.gameLevel.getLevel();
    var unitsCount = Families[unit.code].units.length;

    if (level < 4 && !cleverapps.gameLevel.withOwnLevel || Game.currentGame.tutorial.isActive() || (unit.findComponent(HeroItem) && unit.stage === unitsCount - 2) || unit.isMagicCrystal()) {
        return false;
    }
    if (!unit.getMergeUnit()) {
        return false;
    }
    var totalAmount = Map2d.currentMap.listAvailableUnits(unit).length;
    var mergeBonus = MergeLogic.mergeBonus(unit, amount);

    amount = mergeBonus.next;

    if (totalAmount < amount) {
        return false;
    }
    return true;
};

MergeBonusHintContent.prototype.getMessages = function () {
    var unit = this.unit;
    var amount = this.amount;

    var mergeBonus = MergeLogic.mergeBonus(unit, amount);
    var nextUnit = unit.getMergeUnit();
    amount = mergeBonus.next;
    if (amount <= 17) {
        var nextBonus = MergeLogic.mergeBonus(unit, amount);
        var text1 = Messages.get("CenterHint.merge.begins") + " x" + amount;
        var text2 = Messages.get("CenterHint.merge.ends") + " x" + nextBonus.bonus;
    } else {
        text1 = Messages.get("CenterHint.merge.beginsExtra");
        text2 = Messages.get("CenterHint.merge.endsExtra");
    }

    var messages = [
        { text: text1 },
        { icon: UnitView.getUnitImage(unit, { preferStatic: true }) },
        { text: text2 },
        { icon: UnitView.getUnitImage(nextUnit, { preferStatic: true }) }
    ];
    return messages;
};
