/**
 * Created by Andrey Popov on 30.10.2024
 */

var UpgradeWindow = CleverappsWindow.extend({
    onWindowLoaded: function (customer) {
        this.customer = customer;
        this.recipe = this.customer.getCurrentRecipe();

        this._super({
            name: "UpgradeWindow",
            customTitle: true,            
            contentPadding: customer.unit.isCloseToMapLeft() ? cleverapps.UI.DOCK_RIGHT : cleverapps.UI.DOCK_LEFT,
            noBackground: true,
            content: this.createContent(),
            notCloseByTouchInShadow: true
        });

        this.recipesUpdatedHandler = Map2d.currentMap.customers.on("onRecipesUpdated", this.updateButton.bind(this), this);
        this.updateButton();
    },

    createContent: function () {
        var styles = cleverapps.styles.UpgradeWindow;

        this.content = new cc.Node();
        this.content.setAnchorPoint(0.5, 0.5);
        this.content.setContentSize2(styles);

        this.title = new CustomerWindowTitle(this.customer, true);
        this.title.setPositionRound(styles.title);
        this.content.addChild(this.title);

        var bg = cleverapps.UI.createScale9Sprite(
            bundles.customer_window.frames.customer_scroll,
            cleverapps.UI.Scale9Rect.TwoPixelXY
        );
        bg.setContentSize2(styles.bg);
        bg.setPositionRound(styles.bg);
        this.content.addChild(bg);

        this.ingredient = new CustomerIngredientView(this.recipe.getIngredients()[0], CustomerIngredientView.TYPE_UPGRADE);
        this.ingredient.setPosition(bg.width / 2, bg.height / 2);
        bg.addChild(this.ingredient);

        this.button = this.createButton();
        this.button.setPositionRound(styles.button);
        this.content.addChild(this.button);

        return this.content;
    },

    beforeCloseAnimation: function (callback) {
        this.recipesUpdatedHandler.clear();
        Map2d.currentMap.customers.clearWantsWindow();

        if (!this.customerForExchange) {
            callback();
            return;
        }
        this.title.close();
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.6),
            new cc.CallFunc(function () {
                this.hideSelf();
                Map2d.currentMap.unhighlightUnit();
                Map2d.currentMap.zoomIn(this.customerForExchange.unit, {
                    callback: this.customerForExchange.exchange.bind(this.customerForExchange, callback)
                });
            }.bind(this))
        ));
    },

    createButton: function () {
        var styles = cleverapps.styles.UpgradeWindow.button;
        var shoppingList = this.recipe.getShoppingList();
        var button;
        if (!shoppingList.totalHard && !shoppingList.totalSoft) {
            button = new cleverapps.UI.Button({
                text: "Customer.Upgrade",
                width: styles.width,
                height: styles.height,
                onClicked: function () {
                    this.customerForExchange = this.customer;
                    this.close();
                }.bind(this)
            });
            button.adviceTarget = Map2d.SPAWN;

            if (this.customer.isRecipeReady()) {
                button.enable();
            } else {
                button.disable();
            }
        } else {
            var totalHard = shoppingList.totalHard, totalSoft = shoppingList.totalSoft;
            var msg = Messages.get("Customer.Buy") + (totalSoft ? " @@" + totalSoft : "") + (totalHard ? " $$" + totalHard : "");
            button = new cleverapps.UI.Button({
                type: cleverapps.styles.UI.Button.Images.button_blue,
                content: new TextWithIcon(msg),
                width: styles.buyWidth,
                height: styles.height,
                onClicked: function () {
                    if (!Map2d.currentMap.customers.canSpawnIngredients(shoppingList.hardItems.concat(shoppingList.softItems))) {
                        return;
                    }

                    if (totalHard && cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.RECIPE_INGREDIENT_ALL, totalHard)) {
                        Map2d.currentMap.customers.spawnIngredients(shoppingList.hardItems);
                    }

                    if (totalSoft && cleverapps.user.spendSoft(cleverapps.EVENTS.SPENT.RECIPE_INGREDIENT_ALL, totalSoft)) {
                        Map2d.currentMap.customers.spawnIngredients(shoppingList.softItems);
                    }

                    if (this.customer.isRecipeReady()) {
                        this.button.removeFromParent();
                        this.customerForExchange = this.customer;
                        this.close();
                    }
                }.bind(this)
            });
        }

        button.setCascadeOpacityEnabledRecursively(true);

        return button;
    },

    updateButton: function () {
        var newButton = this.createButton();
        this.content.addChild(newButton);
        newButton.setPositionRound(this.button.getPosition());
        this.button.removeFromParent();
        this.button = newButton;
    },

    onHide: function () {
        this._super();
        Map2d.currentMap.unhighlightUnit();
    },

    onShow: function () {
        this._super();
        Map2d.currentMap.highlightUnit(this.customer.unit);

        this.title.showUp();
        this.ingredient.showUp();

        this.button.setOpacity(0);
        this.button.runAction(new cc.Sequence(
            new cc.DelayTime(1.5),
            new cc.FadeIn(0.5)
        ));
    }
});

cleverapps.styles.UpgradeWindow = {
    width: 500,
    height: 600,

    title: {
        x: { align: "center" },
        y: { align: "top", dy: 270 }
    },

    bg: {
        x: { align: "center" },
        y: { align: "center", dy: -10 },
        width: 220,
        height: 260
    },

    button: {
        x: { align: "center" },
        y: { align: "bottom", dy: -30 },
        width: 250,
        height: 90,
        buyWidth: 290
    }
};
