/**
 * Created by Vladislav on 06.12.2024.
 */

var FallSaleTitleIcon = cc.Scale9Sprite.extend({
    ctor: function () {
        this._super(bundles.fall_sale_icons.frames.fallsale_title, cleverapps.UI.getScale9Rect(bundles.fall_sale_icons.frames.fallsale_title, cleverapps.UI.Scale9Rect.TwoPixelXY));

        var styles = cleverapps.styles.FallSaleTitleIcon;

        var text = cleverapps.UI.generateOnlyText("FallSaleWindow.shoptitle", cleverapps.styles.FONTS.FALL_SALE_BIG_TITLE);
        text.fitTo(styles.description.width, styles.description.height);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setPositionRound(styles.description);
        this.addChild(text);

        this.setLocalZOrder(-1);

        this.setPositionRound(styles);
        this.setContentSize2(styles);

        this.setCascadeOpacityEnabledRecursively(true);
    }
});

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    FALL_SALE_BIG_TITLE_SHADOW: {
        color: new cc.Color(255, 188, 95),
        direction: cc.size(2, -3)
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    FALL_SALE_BIG_TITLE: {
        size: 44,
        color: new cc.Color(137, 64, 45, 255),
        shadow: cleverapps.styles.DECORATORS.FALL_SALE_BIG_TITLE_SHADOW
    }
});

cleverapps.styles.FallSaleTitleIcon = {
    x: { align: "center" },
    y: { align: "bottom", dy: -100 },

    width: 500,
    height: 123,

    description: {
        height: 80,
        width: 340,

        x: { align: "center" },
        y: { align: "center", dy: -2 }
    }
};