/**
 * Created by vtbelo on 01.04.2021
 */

var UnitsShopTab = function (tab) {
    cleverapps.EventEmitter.call(this);

    this.tab = tab;
    this.load({});
};

UnitsShopTab.prototype = Object.create(cleverapps.EventEmitter.prototype);
UnitsShopTab.prototype.constructor = UnitsShopTab;

UnitsShopTab.prototype.load = function (stored) {
    this.stored = stored || {};
    this.buyed = {};
    this.inited = false;
    this.clear();
};

UnitsShopTab.prototype.save = function () {
    if (!this.inited) {
        return Object.keys(this.stored).length > 0 ? this.stored : undefined;
    }

    if (this.getLeftTime() > 0) {
        var info = {
            refresh: this.refreshTime,
            fresh: !!this.fresh
        };
        cleverapps.override(info, this.buyed);
        return info;
    }
};

UnitsShopTab.prototype.init = function () {
    if (this.inited) {
        return;
    }
    this.inited = true;

    if (this.stored) {
        this.refreshTime = this.stored.refresh || 0;
        this.fresh = this.stored.fresh || false;

        delete this.stored.refresh;
        delete this.stored.buyed;
        delete this.stored.fresh;

        this.buyed = this.stored;
        this.runRefreshTimeout();
    } else {
        this.refreshProducts();
    }
};

UnitsShopTab.prototype.clear = function () {
    if (this.refreshTimeout) {
        clearTimeout(this.refreshTimeout);
    }
};

UnitsShopTab.prototype.getLeftTime = function () {
    return this.refreshTime + cleverapps.parseInterval(UnitsShop.PRODUCTS[this.tab].refreshTimeout) - Date.now();
};

UnitsShopTab.prototype.processCartUnits = function (cart) {
    if (UnitsShop.cartRewards && UnitsShop.cartRewards.length) {
        UnitsShop.cartRewards.forEach(function (reward) {
            reward.collectRewardsAnimation(cart.firstItem);
        });
        UnitsShop.cartRewards = undefined;
    }
};

UnitsShopTab.prototype.getCartAmount = function () {
    if (!UnitsShop.cartRewards) {
        return 0;
    }
    return UnitsShop.cartRewards.length;
};

UnitsShopTab.prototype.buy = function (product, videoBonus) {
    this.buyed[product.itemId] = (this.buyed[product.itemId] || 0) + 1;

    cleverapps.user.incProgressCompare();
    cleverapps.unitsShop.save();

    if (!product.price) {
        cleverapps.localPushes.sendPush(cleverapps.LocalPushes.TYPES.FREE_CHEST, this.getLeftTime());
    }

    if (!UnitsShop.cartRewards) {
        UnitsShop.cartRewards = [];
    }

    var mission = cleverapps.missionManager.findRunningMission(Mission.TYPE_SALEPASS);
    var isSalePassActive = mission && !mission.logic.isAllTasksCompleted() && MissionManager.hasProperParent(mission);
    var cupStars = cleverapps.clanCup.isRunning() && Unit.CalcCupStars(product.unit);

    var amountWithBonus = videoBonus ? product.amount * 2 : product.amount;
    for (var i = 0; i < amountWithBonus; i++) {
        var unitReward = new Reward("units", product.unit);
        unitReward.receiveReward();

        if (product.price <= 0 || product.price === undefined) {
            Map2d.mapEvent(Map2d.BUY_FREE_UNIT, { tab: this.tab });
        }

        var unit = unitReward.spawnedUnits[0];
        if (isSalePassActive && product.salePassPoints) {
            unit.setPoints(product.salePassPoints);
        }

        if (cupStars) {
            unitReward.cupStars = {
                unit: unit,
                reward: new Reward("cup", {
                    type: CupsConfig.TYPE_CLAN,
                    amount: cupStars
                })
            };
            unitReward.cupStars.reward.receiveReward();
        }

        UnitsShop.cartRewards.push(unitReward);
    }

    if (Game.currentGame.tutorial.isActive() && Game.currentGame.tutorial.getCurrentStepType() === Map2d.BUY_FREE_UNIT) {
        Game.currentGame.advice.stopHint();
    }

    this.trigger("stateChanged");
    cleverapps.toolbar.resetByType(ToolbarItem.UNIT_SHOP);
};

UnitsShopTab.prototype.runRefreshTimeout = function () {
    if (this.getLeftTime() > 0) {
        this.clear();
        this.refreshTimeout = new cleverapps.LongTimeout(this.refreshProducts.bind(this, true), this.getLeftTime());
    } else {
        this.refreshProducts(true);
    }
};

UnitsShopTab.prototype.getFreeProduct = function () {
    this.init();

    return this.generateProducts().filter(function (product) {
        return !product.price && product.left > 0;
    })[0];
};

UnitsShopTab.prototype.generateProducts = function () {
    var config = UnitsShop.PRODUCTS[this.tab];
    var products = cleverapps.clone(config.default || [], true);

    if (config.fruit_template) {
        config.fruit_template.forEach(function (fruitTemplate) {
            var codes;
            if (fruitTemplate.currentType) {
                codes = [cleverapps.unitsLibrary.getCurrentFruit()];
            } else {
                codes = cleverapps.unitsLibrary.listAvailableByType("fruit");
            }

            for (var i = 0; i < codes.length; i++) {
                var fruit = cleverapps.clone(fruitTemplate, true);
                fruit.unit.code = codes[i];
                products.push(fruit);
            }
        });
    }

    var resource = cleverapps.unitsLibrary.getCurrentCastleResource();

    if (config.resource_chest_template && resource) {
        config.resource_chest_template.forEach(function (chestTemplate) {
            var chest = cleverapps.clone(chestTemplate, true);
            chest.unit.code = resource + "chest";
            products.push(chest);
        });
    }

    if (config.resource_template && resource) {
        var resourceData = config.resource_data[resource] || config.resource_data.default;
        for (var i = 0, price = resourceData.basicPrice; i < resourceData.stages; i++, price *= 3) {
            var resourceUnit = cleverapps.clone(config.resource_template, true);
            resourceUnit.unit = {
                stage: i,
                code: resource
            };
            resourceUnit.price = price;
            products.push(resourceUnit);
        }
    }

    if (config.line_template) {
        config.line_template.forEach(function (template) {
            var codes = cleverapps.unitsLibrary.listAvailableByType(template.codes);
            var chosen = codes.filter(function (code) {
                return !template.preferUndone || cleverapps.unitsLibrary.getLastOpenStage(code) < Families[code].units.length - 1;
            });
            chosen = chosen.length > 0 ? chosen : codes;

            if (template.randomChoose) {
                var shortage = template.randomChoose - chosen.length;
                if (shortage > 0) {
                    chosen = chosen.concat(cleverapps.substract(codes, chosen).slice(0, shortage));
                }
                chosen = cleverapps.Random.chooseAmount(chosen, template.randomChoose, this.refreshTime);
            }

            for (i = 0; i < chosen.length; i++) {
                var product = cleverapps.clone(template, true);
                product.unit = {
                    code: chosen[i],
                    stage: product.stage
                };
                products.push(product);
            }
        }, this);
    }

    if (config.hero_ruby) {
        config.hero_ruby.forEach(function (heroTemplate) {
            var hero = cleverapps.clone(heroTemplate, true);
            if (!cleverapps.unitsLibrary.isOpened({ code: hero.unit.code, stage: Families[hero.unit.code].units.length - 1 })) {
                products.unshift(hero);
            }
        });
    }

    var hero = cleverapps.unitsLibrary.getCurrentHero();
    if (config.hero_template && hero && hero !== "dwarf") {
        config.hero_template.forEach(function (heroTemplate) {
            var heroItem = cleverapps.clone(heroTemplate, true);
            heroItem.unit.code = hero;
            products.unshift(heroItem);
        });
    }

    if (Season.isRunning() && config.season_chest) {
        config.season_chest.forEach(function (seasonTemplate) {
            var seasonItem = cleverapps.clone(seasonTemplate, true);
            if (seasonItem.unit.code === "seasonitem") {
                seasonItem.unit.code = Season.getCurrentItemCode();
            }
            products.push(seasonItem);
        });
    }

    var level = Game.currentGame && Game.currentGame.level;

    products = products.filter(function (product) {
        var family = Families[product.unit.code];
        return family && (!level || level.families[product.unit.code])
            && (!product.onlyIfOpen || cleverapps.unitsLibrary.hasOpened(product.unit.code))
            && (!product.onlyIfNotDone || cleverapps.unitsLibrary.getLastOpenStage(product.unit.code) < family.units.length - 1)
            && (!product.landmarkDonor || Game.currentGame && Game.currentGame.landmarks && Game.currentGame.landmarks.isActiveDonor(product.unit));
    });

    var randomProducts = [];

    products.forEach(function (product) {
        if (Families[product.unit.code].units[product.unit.stage].deleted) {
            product.unit.stage = Unit.calcNewStageThenDeleted(product.unit.code, product.unit.stage);
        }

        product.TileModelClass = UnitsShopTileModel;
        product.itemId = product.unit.code + "_" + product.unit.stage + "_" + product.amount;
        product.left -= this.buyed[product.itemId] || 0;
        product.tab = this.tab;

        if (product.groupId === undefined) {
            randomProducts.push(product);
        }
    }, this);

    if (randomProducts.length !== products.length) {
        cleverapps.Random.seed(this.refreshTime);
        products.sort(function (p1, p2) {
            if (p1.groupId !== p2.groupId) {
                return p1.groupId - p2.groupId;
            }
            return cleverapps.Random.nextDouble() - 0.5;
        });
        cleverapps.Random.randomSeed();
        var prevGroup;
        products.forEach(function (product) {
            if (product.groupId !== undefined && product.groupId !== prevGroup) {
                prevGroup = product.groupId;
                randomProducts.push(product);
            }
        });
        products = randomProducts;
    }

    var mission = cleverapps.missionManager.findByType(Mission.TYPE_SALEPASS);
    if (mission && mission.isRunning() && !mission.logic.isAllTasksCompleted() && MissionManager.hasProperParent(mission)) {
        products.forEach(function (product) {
            var points;
            switch (product.type) {
                case Product.TYPE_HARD: points = product.price * 3; break;
                case Product.TYPE_SOFT: points = product.price / 11; break;
            }
            if (points !== undefined) {
                product.salePassPoints = Math.max(5, Math.ceil(Math.floor((points || 0) / 5) * 5 / product.amount));
            }
        });
    }

    if (Game.currentGame && Game.currentGame.tutorial && Game.currentGame.tutorial.isActive() && Game.currentGame.tutorial.getCurrentStepType() === Map2d.BUY_FREE_UNIT) {
        products.forEach(function (product) {
            if (product.videoBonus) {
                product.videoBonus = false;
            }
        });
    }

    return products;
};

UnitsShopTab.prototype.countBoughtProducts = function () {
    var freeProducts = this.generateProducts().filter(function (product) {
        return !product.price;
    });

    var freeKeys = freeProducts.map(function (product) {
        return product.itemId;
    });

    var amount = 0;
    for (var boughtKey in this.buyed) {
        if (freeKeys.indexOf(boughtKey) === -1) {
            amount += this.buyed[boughtKey];
        }
    }

    return amount;
};

UnitsShopTab.prototype.buyRefresh = function () {
    if (this.countBoughtProducts() > 0) {
        if (cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.REFRESH, UnitsShop.PRODUCTS[this.tab].refreshPrice)) {
            this.refreshProducts();
            return true;
        }
    }
    return false;
};

UnitsShopTab.prototype.refreshProducts = function (fresh) {
    this.refreshTime = Date.now();
    this.buyed = {};
    this.fresh = fresh;
    this.runRefreshTimeout();
    cleverapps.unitsShop.save();

    var products = this.generateProducts();
    products.forEach(function (product) {
        if (product.type === Product.TYPE_ADS) {
            TileModel.clearVideoProgress(product);
        }
    });

    cleverapps.localPushes.cancelPush(cleverapps.LocalPushes.TYPES.FREE_CHEST);
    this.trigger("stateChanged");
    cleverapps.toolbar.resetByType(ToolbarItem.UNIT_SHOP);
};

UnitsShopTab.prototype.setFreshShown = function () {
    if (this.fresh) {
        this.fresh = false;
        this.trigger("stateChanged");
        cleverapps.unitsShop.save();
    }
};
