/**
 * Created by andrey on 24.02.2021.
 */

var MergeTutorials = {
    dwarf: {
        steps: [
            {
                type: Map2d.MERGE,
                targets: { code: "dwarf", stage: 0 },
                person: "dwarf",
                gravitate: DialogueView.GRAVITATE.BOTTOM,
                text: "Tutorial.dwarf.step1"
            }
        ]
    },

    dwarf_hustlemerge: {
        steps: [
            {
                type: Map2d.CLICK_UNIT,
                targets: { code: "dwarf", stage: 1 },
                arrow: {
                    delay: 2
                },
                highlight: false
            },
            {
                type: Map2d.CLICK_UNIT,
                targets: { code: "dwarf", stage: 1 },
                arrow: {
                    delay: 2
                },
                highlight: false
            },
            {
                type: Map2d.CLICK_UNIT,
                targets: { code: "dwarf", stage: 1 },
                arrow: {
                    delay: 2
                },
                highlight: false
            },
            {
                type: Map2d.CLICK_UNIT,
                targets: { code: "dwarf", stage: 1 },
                arrow: {
                    delay: 2
                },
                highlight: false
            }
        ]
    },

    wheat_3: {
        replayOnReload: true,
        steps: [
            {
                type: Map2d.MERGE,
                targets: { code: "wheat", stage: 0 },
                person: "dwarf",
                gravitate: DialogueView.GRAVITATE.BOTTOM,
                controls: ["MenuBarGameLevelItem", "MenuBarCoinsItem"],
                text: "Tutorial.wheat_3_tutorial.step1"
            },
            {
                type: Map2d.MERGE,
                targets: { code: "wheat", stage: 1 },
                person: "dwarf",
                gravitate: DialogueView.GRAVITATE.BOTTOM,
                controls: ["MenuBarGameLevelItem", "MenuBarCoinsItem"],
                text: "Tutorial.wheat_3_tutorial.step2"
            },
            {
                type: Map2d.MERGE,
                targets: { code: "wheat", stage: 2 },
                person: "dwarf",
                gravitate: DialogueView.GRAVITATE.BOTTOM,
                controls: ["MenuBarGameLevelItem", "MenuBarCoinsItem"],
                text: "Tutorial.wheat_3_tutorial.step3"
            }
        ]
    },

    story_wheat_3: {
        replayOnReload: true,
        steps: [
            {
                type: Map2d.SPAWN,
                targets: { code: "wheat", stage: 3 },
                prizes: { code: "wheat", stage: 4 },
                person: "dwarf",
                gravitate: DialogueView.GRAVITATE.BOTTOM,
                controls: ["toolbar"]
            },
            {
                type: Map2d.HARVEST,
                targets: { code: "wheat", stage: 4 },
                allMoves: true,
                person: "dwarf",
                gravitate: DialogueView.GRAVITATE.TOP,
                controls: ["toolbar"],
                text: "Tutorial.dishes_0.step2"
            },
            {
                type: Map2d.SPAWN,
                targets: { code: "dwarf", stage: 1 },
                prizes: { code: "wands", stage: 0 },
                ingredients: { wheat: 2 },
                person: "dwarf",
                gravitate: DialogueView.GRAVITATE.BOTTOM,
                controls: ["toolbar"],
                text: "Tutorial.dishes_0.step3"
            }
        ]
    },

    greeting_wands_0: {
        steps: [
            {
                type: Map2d.HARVEST,
                targets: { code: "wands", stage: 0 },
                allMoves: true,
                person: "dwarf",
                gravitate: DialogueView.GRAVITATE.BOTTOM,
                controls: ["MenuBarWandsItem"],
                text: "Tutorial.dishes_0.step5"
            },
            {
                type: Map2d.OPENFOG,
                targets: "fog2",
                wait: "10 seconds",
                person: "dwarf",
                gravitate: DialogueView.GRAVITATE.BOTTOM,
                controls: ["MenuBarWandsItem"],
                text: "Tutorial.dishes_0.step6"
            }
        ]
    },

    wood_1: {
        replayOnReload: true,
        steps: [
            {
                type: Map2d.START_MINING,
                targets: { code: "woodsource", stage: 1, mined: 0 },
                region: "tutor_wood",
                person: "worker",
                gravitate: DialogueView.GRAVITATE.BOTTOM,
                text: "Tutorial.wood_1.step1",
                lovesInfoView: true
            },
            {
                type: Map2d.SPAWN,
                targets: { code: "woodsource", stage: 1 },
                prizes: { code: "wood", stage: 0 },
                gravitate: DialogueView.GRAVITATE.BOTTOM,
                wait: "15 seconds"
            },
            {
                type: Map2d.MERGE,
                targets: { code: "wood", stage: 0 },
                person: "worker",
                gravitate: DialogueView.GRAVITATE.BOTTOM,
                text: "Tutorial.wood_1.step3"
            }
        ]
    },

    wood_2: {
        steps: [
            {
                type: Map2d.START_MINING,
                targets: { code: "woodsource", stage: 1, mined: 1 },
                person: "worker",
                gravitate: DialogueView.GRAVITATE.BOTTOM,
                text: "Tutorial.wood_2.step1"
            },
            {
                type: Map2d.SPEED_UP,
                targets: { code: "woodsource", stage: 1, mined: 1 },
                gravitate: DialogueView.GRAVITATE.BOTTOM
            },
            {
                type: Map2d.SPAWN,
                targets: { code: "woodsource", stage: 1 },
                prizes: { code: "woodchest", stage: 0 },
                gravitate: DialogueView.GRAVITATE.BOTTOM
            },
            {
                type: Map2d.SPAWN,
                targets: { code: "woodchest", stage: 0 },
                person: "worker",
                gravitate: DialogueView.GRAVITATE.BOTTOM,
                text: "Tutorial.wood_2.step4"
            }
        ]
    },

    story_fog_fog4_0: {
        steps: [
            {
                type: Map2d.MERGE,
                targets: { code: "wood", stage: 0 },
                minUnits: 5,
                region: "fog4_0",
                person: "dwarf",
                text: ["Tutorial.merge5.step1.1", { code: "wood", stage: 0 }, "Tutorial.merge5.step1.2", { code: "wood", stage: 1 }]
            }
        ]
    },

    build_wood_2: {
        steps: [
            {
                type: Map2d.START_BUILDING,
                targets: { code: "wood", stage: 2 },
                person: "worker",
                text: "Tutorial.build_wood_2.step1"
            },
            {
                type: Map2d.SPEED_UP,
                targets: { code: "wood", stage: 2 },
                person: "worker",
                text: "Tutorial.build_wood_2.step2"
            }
        ]
    },

    woodgenerator_1: {
        filter: function () {
            if (Map2d.currentMap.workers.findFree() === undefined) {
                return false;
            }

            var unitData = Families.woodgenerator.units[0];
            var energy = unitData.mineable[0].energy;

            return cleverapps.lives.amount >= energy;
        },
        steps: [
            {
                type: Map2d.START_MINING,
                targets: { code: "woodgenerator", stage: 0 },
                person: "narrator",
                text: "Tutorial.woodgenerator_1.step1"
            },
            {
                type: Map2d.SPAWN,
                targets: { code: "woodgenerator", stage: 0 },
                person: "narrator",
                wait: "15 seconds",
                text: "Tutorial.woodgenerator_1.step2"
            }
        ]
    },

    story_kraken_0: {
        filter: function () {
            var region = Map2d.currentMap.regions.monsters;
            for (var i = 0; i < region.positions.length; i++) {
                var monster = Map2d.currentMap.getUnit(region.positions[i].x, region.positions[i].y);
                if (monster && monster.getData().monster === "kraken") {
                    var info = monster.getActionInfo();
                    if (info && info.buttons.wands.wands <= Game.currentGame.wands) {
                        return true;
                    }
                }
            }
        },
        steps: [
            {
                type: Map2d.START_MINING,
                controls: ["KrakenFeastMissionIcon"],
                monster: "kraken",
                targets: [{ code: "kraken", stage: 0 }],
                person: "dwarf",
                text: "Tutorial.kraken_0.step1"
            },
            {
                type: Map2d.SPAWN,
                controls: ["KrakenFeastMissionIcon"],
                monster: "kraken",
                targets: [{ code: "kraken", stage: 0 }],
                prizes: { code: "coins", stage: 0 },
                wait: "15 seconds"
            }
        ]
    },

    crystal_use: {
        steps: [
            {
                type: Map2d.MERGE,
                targets: { code: "crystal", stage: 2 },
                person: "dwarf",
                text: ["Tutorial.crystal.step1.1", { code: "crystal", stage: 2 }, "Tutorial.crystal.step1.2", { code: "crystal", stage: 3 }]
            },
            {
                type: Map2d.MERGE,
                targets: [{ code: "crystal", stage: 3 }, { code: "woodchest", stage: 1 }],
                person: "dwarf",
                text: ["Tutorial.crystal.step2.1", { code: "crystal", stage: 3 }, "Tutorial.crystal.step2.2", { code: "woodchest", stage: 1 }, "Tutorial.crystal.step2.3", { code: "woodchest", stage: 2 }]
            }
        ]
    },

    dragonia: {
        steps: [{
            type: Map2d.MERGE,
            targets: { code: "dragonblue", stage: 0 },
            person: "king",
            text: "Tutorial.dragonia.step1",
            tankAmount: "beginner"
        },
        {
            type: Map2d.HARVEST,
            targets: { code: "dragonblue", stage: 1 },
            person: "king",
            text: "Tutorial.dragonia.step2"
        },
        {
            type: Map2d.HARVEST,
            noScroll: true,
            targets: { code: "dragonblue", stage: 1 },
            person: "king"
        },
        {
            type: Map2d.OPENFOG,
            targets: "fog1",
            wait: "10 seconds",
            person: "king",
            controls: ["MenuBarWandsItem"],
            text: "Tutorial.dragonia.step3"
        },
        {
            type: Map2d.START_MINING,
            targets: [{ code: "drwoodsource", stage: 0, mined: 0 }, { code: "drwoodsource", stage: 1, mined: 0 }, { code: "drwoodsource", stage: 2, mined: 0 }],
            person: "king",
            text: "Tutorial.dragonia.step4"
        },
        {
            type: Map2d.SPAWN,
            targets: [{ code: "drwoodsource", stage: 0 }, { code: "drwoodsource", stage: 1 }, { code: "drwoodsource", stage: 2 }],
            person: "king",
            wait: "15 seconds",
            text: "Tutorial.dragonia.step5"
        },
        {
            type: Map2d.REFILL,
            targets: [{ code: "dragonblue", stage: 1 }],
            person: "king",
            text: "Tutorial.dragonia.step6",
            tankAmount: "refill"
        }]
    },

    story_fruitshop_0: {
        steps: [
            {
                type: Map2d.BUY_FREE_UNIT,
                targets: { code: "fruitshop", stage: 0 },
                person: "dwarf",
                text: "Tutorial.story_fruitshop_0.step1"
            }
        ]
    },

    story_resourceshop_0: {
        steps: [
            {
                type: Map2d.BUY_FREE_UNIT,
                targets: { code: "resourceshop", stage: 0 },
                person: "dwarf",
                text: "Tutorial.story_resourceshop_0.step1"
            }
        ]
    },

    story_discountshop_0: {
        steps: [
            {
                type: Map2d.BUY_FREE_UNIT,
                targets: { code: "discountshop", stage: 0 },
                person: "dwarf",
                text: "Tutorial.story_discountshop_0.step1"
            }
        ]
    },

    salepass: {
        steps: [
            {
                type: Map2d.ENTER_UNITS_SHOP,
                targets: { code: "fruitshop", stage: 0 },
                person: "dwarf",
                text: "Tutorial.salepass.step1"
            }
        ]
    },

    undersea: {
        steps: [{
            type: Map2d.MERGE,
            targets: { code: "sealisa", stage: 0 },
            person: "king",
            text: "Tutorial.undersea.step1",
            tankAmount: "beginner"
        },
        {
            type: Map2d.HARVEST,
            targets: { code: "sealisa", stage: 1 },
            person: "king",
            text: "Tutorial.undersea.step2"
        },
        {
            type: Map2d.HARVEST,
            noScroll: true,
            targets: { code: "sealisa", stage: 1 },
            person: "king"
        },
        {
            type: Map2d.OPENFOG,
            targets: "fog1",
            wait: "10 seconds",
            person: "king",
            controls: ["MenuBarWandsItem"],
            text: "Tutorial.undersea.step3"
        },
        {
            type: Map2d.START_MINING,
            targets: [{ code: "seacrabsource", stage: 0, mined: 0 }, { code: "seacrabsource", stage: 1, mined: 0 }, { code: "seacrabsource", stage: 2, mined: 0 }],
            person: "king",
            text: "Tutorial.undersea.step4"
        },
        {
            type: Map2d.SPAWN,
            targets: [{ code: "seacrabsource", stage: 0 }, { code: "seacrabsource", stage: 1 }, { code: "seacrabsource", stage: 2 }],
            person: "king",
            wait: "15 seconds",
            text: "Tutorial.undersea.step5"
        },
        {
            type: Map2d.REFILL,
            targets: { code: "sealisa", stage: 1 },
            person: "king",
            text: "Tutorial.undersea.step6",
            tankAmount: "refill"
        }]
    },

    undersea2: {
        steps: [{
            type: Map2d.MERGE,
            targets: { code: "sea2lisa", stage: 0 },
            person: "king",
            text: "Tutorial.undersea2.step1"
        },
        {
            type: Map2d.MERGE,
            targets: { code: "seastrawberry", stage: 0 },
            person: "dwarf",
            text: "Tutorial.undersea2.step2"
        },
        {
            type: Map2d.MERGE,
            targets: { code: "seastrawberry", stage: 1 }
        },
        {
            type: Map2d.MERGE,
            targets: { code: "seastrawberry", stage: 2 }
        },
        {
            type: Map2d.SPAWN,
            targets: { code: "seastrawberry", stage: 3 },
            person: "dwarf",
            text: "Tutorial.undersea2.step3"
        },
        {
            type: Map2d.FEED_HERO,
            targets: { code: "seastrawberry", stage: 4 },
            person: "king",
            text: "Tutorial.undersea2.step4"
        },
        {
            type: Map2d.OPENFOG,
            targets: "fog1",
            wait: "10 seconds",
            person: "king",
            controls: ["MenuBarWandsItem"],
            text: "Tutorial.undersea2.step5"
        }]
    },

    undersea3: {
        steps: [{
            type: Map2d.MERGE,
            targets: { code: "sea2lisa", stage: 0 },
            person: "king",
            text: "Tutorial.undersea2.step1"
        },
        {
            type: Map2d.MERGE,
            targets: { code: "seastrawberry", stage: 0 },
            person: "dwarf",
            text: "Tutorial.undersea2.step2"
        },
        {
            type: Map2d.MERGE,
            targets: { code: "seastrawberry", stage: 1 }
        },
        {
            type: Map2d.MERGE,
            targets: { code: "seastrawberry", stage: 2 }
        },
        {
            type: Map2d.SPAWN,
            targets: { code: "seastrawberry", stage: 3 },
            person: "dwarf",
            text: "Tutorial.undersea2.step3"
        },
        {
            type: Map2d.FEED_HERO,
            targets: { code: "seastrawberry", stage: 4 },
            person: "king",
            text: "Tutorial.undersea2.step4"
        },
        {
            type: Map2d.OPENFOG,
            targets: "fog1",
            wait: "10 seconds",
            person: "king",
            controls: ["MenuBarWandsItem"],
            text: "Tutorial.undersea2.step5"
        }]
    },

    dragonia2: {
        steps: [{
            type: Map2d.MERGE,
            targets: { code: "dr2dragonblue", stage: 0 },
            person: "dwarf",
            text: "Tutorial.dragonia2.step1"
        },
        {
            type: Map2d.MERGE,
            targets: { code: "drfruit", stage: 0 },
            person: "dwarf",
            text: "Tutorial.dragonia2.step2"
        },
        {
            type: Map2d.MERGE,
            targets: { code: "drfruit", stage: 1 }
        },
        {
            type: Map2d.MERGE,
            targets: { code: "drfruit", stage: 2 }
        },
        {
            type: Map2d.SPAWN,
            targets: { code: "drfruit", stage: 3 },
            person: "dwarf",
            text: "Tutorial.dragonia2.step3"
        },
        {
            type: Map2d.FEED_HERO,
            targets: { code: "drfruit", stage: 4 },
            person: "dwarf",
            text: "Tutorial.dragonia2.step4"
        },
        {
            type: Map2d.OPENFOG,
            targets: "fog1",
            wait: "10 seconds",
            person: "dwarf",
            controls: ["MenuBarWandsItem"],
            text: "Tutorial.dragonia2.step5"
        }]
    },

    blackfriday: {
        steps: [
            {
                type: Map2d.CLICK_UNIT,
                targets: { code: "dr2dragoncup", stage: 0 },
                person: "dwarf",
                text: "Tutorial.blackfriday.step1"
            },
            {
                type: Map2d.CLICK_UNIT,
                targets: { code: "dr2dragoncup", stage: 0 }
            },
            {
                type: Map2d.CLICK_UNIT,
                targets: { code: "dr2dragoncup", stage: 0 }
            },
            {
                type: Map2d.MERGE,
                targets: { code: "dr2dragonblue", stage: 0 },
                person: "dwarf",
                text: "Tutorial.blackfriday.step2"
            }]
    },

    story_dr2field_0_dragonia2: {
        steps: [
            {
                type: Map2d.FIELD_HARVEST,
                allMoves: true,
                targets: { code: "dr2field", stage: 0 },
                action: { type: FieldInfoView.ACTION_HARVEST },
                person: "dwarf",
                text: "Tutorial.dr2field.step1"
            },
            {
                type: Map2d.FIELD_PLANT,
                allMoves: true,
                targets: { code: "dr2field", stage: 0 },
                action: { type: FieldInfoView.ACTION_PLANT },
                person: "dwarf",
                text: "Tutorial.dr2field.step2"
            }
        ]
    },

    story_drproducer0a_0_dragonia3: {
        steps: [
            {
                type: Map2d.FIELD_HARVEST,
                allMoves: true,
                targets: { code: "drproducer0a", stage: 0 },
                action: { type: FieldInfoView.ACTION_HARVEST },
                person: "dwarf",
                text: "Tutorial.drproducer0a.step1"
            },
            {
                type: Map2d.FIELD_PLANT,
                allMoves: true,
                targets: { code: "drproducer0a", stage: 0 },
                action: { type: FieldInfoView.ACTION_PLANT },
                person: "dwarf",
                text: "Tutorial.drproducer0a.step2"
            }
        ]
    },

    rapunzel_dollsource: {
        steps: [
            {
                type: Map2d.START_MINING,
                targets: { code: "rpdollsource", stage: 0, mined: 0 },
                text: "Tutorial.rapunzel.step1"
            },
            {
                type: Map2d.SPAWN,
                targets: { code: "rpdollsource", stage: 0 },
                text: "Tutorial.rapunzel.step2",
                wait: "10 seconds"
            }
        ]
    },

    rapunzel_customer: {
        filter: function () {
            var unit = Map2d.currentMap.listAvailableUnits({ code: "rpprince", stage: 0 })[0];
            var recipe = unit.findComponent(Customer).getCurrentRecipe();
            return recipe && recipe.isReady();
        },
        steps: [
            {
                type: Map2d.CLICK_UNIT,
                targets: { code: "rpprince", stage: 0 },
                text: "Tutorial.rapunzel.step3"
            },
            {
                type: Map2d.EXCHANGE,
                targets: { code: "rpprince", stage: 0 }
            }
        ]
    },

    rapunzel2_doll: {
        steps: [
            {
                type: Map2d.START_MINING,
                targets: { code: "rpdollsource", stage: 0, mined: 0 },
                region: "tutor_rp2_1",
                text: "Tutorial.rapunzel2.step1"
            },
            {
                type: Map2d.SPAWN,
                targets: { code: "rpdollsource", stage: 0 },
                text: "Tutorial.rapunzel2.step2",
                wait: "10 seconds"
            },
            {
                type: Map2d.START_MINING,
                targets: { code: "rpdollsource", stage: 0, mined: 0 },
                region: "tutor_rp2_2",
                text: "Tutorial.rapunzel2.step3"
            },
            {
                type: Map2d.SPAWN,
                targets: { code: "rpdollsource", stage: 0 },
                text: "Tutorial.rapunzel2.step4",
                wait: "10 seconds"
            },
            {
                type: Map2d.MERGE,
                targets: { code: "rprapuncust", stage: 0 },
                person: "dwarf",
                wait: "6 seconds",
                text: "Tutorial.rapunzel2.step5"
            }
        ]
    },

    rapunzel3: {
        steps: [
            {
                type: Map2d.START_MINING,
                targets: { code: "rpplatesource", stage: 0, mined: 0 },
                text: "Tutorial.rapunzel3.step1"
            },
            {
                type: Map2d.SPAWN,
                targets: { code: "rpplatesource", stage: 0 },
                text: "Tutorial.rapunzel3.step2",
                wait: "10 seconds"
            },
            {
                type: Map2d.MERGE,
                targets: { code: "rpplate", stage: 0 },
                person: "dwarf",
                wait: "6 seconds",
                text: "Tutorial.rapunzel3.step3"
            }
        ]
    },

    treasureisland_0: {
        steps: [
            {
                type: Map2d.CLICK_UNIT,
                targets: { code: "dr2treasuremap", stage: 0 },
                text: "Tutorial.treasuremap.step1",
                waitAfter: 2500
            }
        ]
    },

    easter_eggssource: {
        steps: [
            {
                type: Map2d.START_MINING,
                targets: { code: "eaeggssource", stage: 0, mined: 0 },
                text: "Tutorial.easter.step1"
            },
            {
                type: Map2d.SPAWN,
                targets: { code: "eaeggssource", stage: 0 },
                text: "Tutorial.easter.step2",
                wait: "10 seconds"
            }
        ]
    },

    easter_customer: {
        steps: [
            {
                type: Map2d.CLICK_UNIT,
                targets: { code: "eaprince", stage: 0 },
                text: "Tutorial.easter.step3"
            },
            {
                type: Map2d.EXCHANGE,
                targets: { code: "eaprince", stage: 0 }
            }
        ]
    },

    china_sapphiresource: {
        steps: [
            {
                type: Map2d.START_MINING,
                targets: { code: "chsapphiresource", stage: 0, mined: 0 },
                text: "Tutorial.china.step1"
            },
            {
                type: Map2d.SPAWN,
                targets: { code: "chsapphiresource", stage: 0 },
                text: "Tutorial.china.step2",
                wait: "10 seconds"
            }
        ]
    },

    china_customer: {
        steps: [
            {
                type: Map2d.CLICK_UNIT,
                targets: { code: "chnarrator", stage: 0 },
                text: "Tutorial.china.step3"
            },
            {
                type: Map2d.EXCHANGE,
                targets: { code: "chnarrator", stage: 0 }
            }
        ]
    },

    collections: {
        steps: [{
            type: Map2d.MERGE,
            targets: { code: "clhydra", stage: 0 },
            person: "keeper",
            text: "Tutorial.collections.step1"
        },
        {
            type: Map2d.MERGE,
            targets: { code: "clhydra", stage: 1 },
            person: "keeper",
            text: "Tutorial.collections.step2"
        }]
    },

    story_clhydra_2: {
        steps: [{
            type: Map2d.OPENCHEST,
            person: "keeper",
            targets: { code: "clsimplechest", stage: 0 },
            text: "Tutorial.story_clhydra_2.step1"
        }]
    },

    story_clstrawberry_3: {
        steps: [{
            type: Map2d.SPAWN,
            person: "keeper",
            targets: { code: "clstrawberry", stage: 3 },
            text: "Tutorial.story_clstrawberry_3.step1"
        },
        {
            type: Map2d.FEED_HERO,
            person: "keeper",
            targets: { code: "clstrawberry", stage: 4 },
            controls: ["PawBoxIcon"],
            text: "Tutorial.story_clstrawberry_3.step2"
        }]
    },

    story_clpawchest_0: {
        steps: [{
            type: Map2d.OPENCHEST,
            person: "keeper",
            targets: { code: "clpawchest", stage: 0 },
            text: "Tutorial.story_clpawchest_0.step1"
        }]
    },

    story_clshop_0: {
        steps: [{
            type: Map2d.BUY_FREE_UNIT,
            person: "keeper",
            targets: { code: "clshop", stage: 0 },
            text: "Tutorial.story_clshop_0.step1"
        }]
    },

    free_worker: {
        steps: [{
            type: Map2d.START_BUILDING,
            person: "worker",
            text: "Tutorial.free_worker.step1"
        }]
    },

    advshrub_1: {
        steps: [{
            type: Map2d.START_MINING,
            targets: { code: "advshrub", stage: 1, mined: 0 },
            text: "Tutorial.advshrub_1.step1"
        }, {
            type: Map2d.HARVEST,
            targets: { code: "advshrub", stage: 1 },
            wait: "15 seconds"
        }, {
            type: Map2d.START_MINING,
            targets: { code: "advshrub", stage: 1, mined: 1 },
            text: "Tutorial.advshrub_1.step2",
            wait: "15 seconds"
        }, {
            type: Map2d.HARVEST,
            targets: { code: "advshrub", stage: 1 },
            wait: "15 seconds"
        }, {
            type: Map2d.START_MINING,
            targets: { code: "advshrub", stage: 1, mined: 0 },
            text: "Tutorial.advshrub_1.step3",
            wait: "15 seconds"
        }, {
            type: Map2d.HARVEST,
            targets: { code: "advshrub", stage: 1 },
            wait: "15 seconds"
        }, {
            type: Map2d.START_MINING,
            targets: { code: "advshrub", stage: 1, mined: 1 },
            text: "Tutorial.advshrub_1.step4",
            wait: "15 seconds"
        }, {
            type: Map2d.HARVEST,
            targets: { code: "advshrub", stage: 1 },
            wait: "15 seconds"
        }, {
            type: Map2d.FORCE,
            force: Forces.STORAGE_FORCE,
            text: "Tutorial.advshrub_1.step5",
            person: "worker"
        }, {
            type: Map2d.CLOSE_WINDOWS,
            text: "Tutorial.advshrub_1.step6",
            person: "worker",
            dialogueDuration: 6000
        }]
    },

    advcustomerbridge0_1: {
        steps: [{
            type: Map2d.START_MINING,
            targets: { code: "advbush", stage: 4 },
            wait: "15 seconds",
            text: "Tutorial.advcustomerbridge0_1.step1"
        }, {
            type: Map2d.HARVEST,
            targets: { code: "advbush", stage: 4 },
            wait: "15 seconds"
        }, {
            type: Map2d.START_MINING,
            targets: { code: "advbush", stage: 0 },
            wait: "15 seconds",
            text: "Tutorial.advcustomerbridge0_1.step2"
        }, {
            type: Map2d.HARVEST,
            targets: { code: "advbush", stage: 0 },
            wait: "15 seconds"
        }, {
            type: Map2d.EXCHANGE,
            targets: { code: "advcustomerbridge0", stage: 0 },
            person: "worker",
            text: "Tutorial.advcustomerbridge0_1.step3"
        }]
    }
};

MergeTutorials.dragonia3 = cleverapps.clone(MergeTutorials.dragonia2, true);
MergeTutorials.halloween = cleverapps.clone(MergeTutorials.dragonia2, true);
MergeTutorials.xmas = cleverapps.clone(MergeTutorials.dragonia2, true);

Object.keys(MergeTutorials).forEach(function (id) {
    MergeTutorials[id].id = id;
});
