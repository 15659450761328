/**
 * Created by Andrey Popov on 13.08.2024
 */

CustomerRecipes.universal = {
    rpproducer0_0: [
        {
            ingredients: [{
                code: "drfruit",
                stage: 4,
                amount: 1
            }],
            order: [{
                code: "rpproduct0",
                stage: 0,
                amount: 1
            }]
        }],

    rpproducer1_0: [
        {
            requiredUnits: [{ code: "rpcustomermain0", stage: 1 }],
            ingredients: [{
                code: "rpproduct0",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "rpproduct1",
                stage: 0,
                amount: 1
            }]
        }
    ],

    rpproducer2_0: [
        {
            requiredUnits: [{ code: "rpcustomermain1", stage: 1 }],
            ingredients: [{
                code: "rpproduct1",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "rpproduct2",
                stage: 0,
                amount: 1
            }]
        }
    ],

    rpproducer3_0: [
        {
            requiredUnits: [{ code: "rpcustomermain2", stage: 1 }],
            ingredients: [{
                code: "rpproduct2",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "rpproduct3",
                stage: 0,
                amount: 1
            }]
        }
    ],

    rpcustomerbridge0_0: [
        {
            fog: "fog1",
            ingredients: [{
                stage: 3,
                amount: 1,
                type: "drresource"
            }]
        }
    ],

    rpcustomerbridge1_0: [
        {
            requiredUnits: [{ code: "rpcustomermain0", stage: 1 }],
            ingredients: [{
                code: "rpproduct0",
                stage: 0,
                amount: 3
            }]
        }
    ],

    rpcustomerbridge2_0: [
        {
            requiredUnits: [{ code: "rpcustomermain1", stage: 1 }],
            ingredients: [{
                code: "rpproduct1",
                stage: 0,
                amount: 3
            }]
        }
    ],

    rpcustomerbridge3_0: [
        {
            requiredUnits: [{ code: "rpcustomermain2", stage: 1 }],
            ingredients: [{
                code: "rpproduct2",
                stage: 0,
                amount: 3
            }]
        }
    ],

    rpcustomermain0_0: [
        {
            ingredients: [{
                code: "rpproduct0",
                stage: 0,
                amount: 3
            }]
        }
    ],

    rpcustomermain1_0: [
        {
            requiredUnits: [{ code: "rpcustomermain0", stage: 1 }],
            ingredients: [{
                code: "rpproduct1",
                stage: 0,
                amount: 3
            }]
        }
    ],

    rpcustomermain2_0: [
        {
            ingredients: [{
                code: "rpproduct2",
                stage: 0,
                amount: 1
            }]
        }
    ],

    rpcustomermain3_0: [
        {
            ingredients: [{
                code: "rpproduct3",
                stage: 0,
                amount: 1
            }]
        }
    ]
};