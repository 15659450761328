/**
 * Created by Denis Kuzin on 18 february 2022
 */

var ClansChatTab = cc.Node.extend({
    ctor: function (options) {
        this._super();

        this.setContentSize2(options.width, options.height);

        this.createChat();

        this.updateRows();
        this.updateEnergyRequestButton();
        this.updatePhraseButton();

        cleverapps.clanMessages.onMessagesUpdated = this.createListener(this.updateRows.bind(this));

        cleverapps.UI.onClick(this, this.hidePhrases.bind(this), {
            interactiveScale: false
        });
        cleverapps.toolbar.resetByType(ToolbarItem.CLANS);
    },

    createChat: function () {
        var styles = cleverapps.styles.ClansChatTab;

        var chatBg = cleverapps.UI.createScale9Sprite(bundles.clan.frames.table_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        chatBg.setContentSize2(styles.chatBg.width, styles.chatBg.height);
        this.addChild(chatBg);
        chatBg.setPositionRound(styles.chatBg);

        var foreground = cleverapps.UI.createScale9Sprite(bundles.clan.frames.table_fg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        foreground.setContentSize2(chatBg.width, chatBg.height);
        chatBg.addChild(foreground, 1);
        foreground.setPositionRound(chatBg.width / 2, chatBg.height / 2);

        var chat = this.chat = new cleverapps.UI.ScrollView(new cc.Node(), {
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL
        });
        chat.setContentSize2(styles.scroll);
        chat.setPositionRound(chatBg.width / 2, chatBg.height / 2);
        chatBg.addChild(chat);
    },

    updateRows: function () {
        var styles = cleverapps.styles.ClansChatTab;

        var oldVisibleRows = this.findVisibleRows();
        var oldVisibleRect = this.chat.getVisibleRect();

        var items = cleverapps.clanMessages.listMessages().map(function (message) {
            return new ClanMessageRow(message);
        });

        var layout = new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            padding: styles.scroll.padding,
            margin: styles.scroll.margin
        });

        this.chat.setInnerContent(layout);

        var fresh = items.filter(function (item) {
            return item.message.isFresh();
        });

        if (fresh.length) {
            this.chat.scrollTo(cleverapps.UI.ScrollView.SCROLLS.LOWER_RIGHT, undefined, {
                padding: {
                    x: 0,
                    y: fresh[fresh.length - 1].height + styles.scroll.padding.y
                }
            });
            this.chat.scrollTo(cleverapps.UI.ScrollView.SCROLLS.LOWER_RIGHT, 0.5);
            return;
        }

        oldVisibleRows = oldVisibleRows.filter(function (oldRow) {
            return items.some(function (newRow) {
                return newRow.message.messageId === oldRow.message.messageId;
            });
        });

        if (oldVisibleRows.length) {
            var oldRow = oldVisibleRows[0];
            var newRow = items.find(function (row) {
                return row.message.messageId === oldRow.message.messageId;
            });

            var oldBoundingBox = oldRow.getBoundingBox();
            var newBoundingBox = newRow.getBoundingBox();

            var offset = (oldVisibleRect.y + oldVisibleRect.height) - (oldBoundingBox.y + oldBoundingBox.height);
            var scrollY = (newBoundingBox.y + newBoundingBox.height) + offset - oldVisibleRect.height / 2 - this.chat.innerContent.height / 2;

            this.chat.scrollToPoint(cc.p(0, scrollY));
            return;
        }

        this.chat.scrollTo(cleverapps.UI.ScrollView.SCROLLS.LOWER_RIGHT);
    },

    findVisibleRows: function () {
        if (!this.chat.innerContent) {
            return [];
        }

        return this.chat.innerContent.children.filter(function (child) {
            return child instanceof ClanMessageRow && child.isVisible();
        });
    },

    updateEnergyRequestButton: function () {
        var styles = cleverapps.styles.ClansChatTab.requestButton;

        if (this.energyRequestButton) {
            this.energyRequestButton.removeFromParent();
        }

        var content = new TextWithIcon("%% " + Messages.get("ClansChatTab.requestButton"), {
            font: cleverapps.styles.FONTS.BUTTON_TEXT
        });

        var timeLeft = cleverapps.clanMessages.getUserMessageTimeout(ClansConfig.MESSAGE_TYPE_ENERGY);
        if (timeLeft) {
            var countdown = new cleverapps.CountDownView(new cleverapps.CountDown(timeLeft, {
                onFinish: this.updateEnergyRequestButton.bind(this)
            }), {
                font: cleverapps.styles.FONTS.BUTTON_TEXT
            });

            content = new cleverapps.Layout([content, countdown], {
                direction: cleverapps.UI.VERTICAL,
                margin: styles.content.margin
            });
        }

        var button = this.energyRequestButton = new cleverapps.UI.Button({
            width: styles.width,
            height: styles.height,
            disabled: timeLeft,
            type: cleverapps.styles.UI.Button.Images.button_green,
            content: content,
            onClicked: function () {
                cleverapps.clanMessages.postMessage({
                    type: ClansConfig.MESSAGE_TYPE_ENERGY
                });
                this.updateEnergyRequestButton();
            }.bind(this)
        });
        this.addChild(button);
        button.setPositionRound(styles);
    },

    updatePhraseButton: function () {
        var styles = cleverapps.styles.ClansChatTab.phraseButton;

        this.hidePhrases();

        if (this.phraseButton) {
            this.phraseButton.removeFromParent();
        }

        var icon = new cc.Sprite(bundles.clans_window.frames.chat_icon);
        var text = cleverapps.UI.generateOnlyText("ClansChatTab.phraseButton", cleverapps.styles.FONTS.BUTTON_TEXT);
        var content = new cleverapps.Layout([icon, text], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        var timeLeft = cleverapps.clanMessages.getUserMessageTimeout();
        if (timeLeft) {
            var countdown = new cleverapps.CountDownView(new cleverapps.CountDown(timeLeft, {
                onFinish: this.updatePhraseButton.bind(this)
            }), {
                font: cleverapps.styles.FONTS.BUTTON_TEXT
            });

            content = new cleverapps.Layout([content, countdown], {
                direction: cleverapps.UI.VERTICAL,
                margin: styles.verticalMargin
            });
        }

        var button = this.phraseButton = new cleverapps.UI.Button({
            width: styles.width,
            height: styles.height,
            disabled: timeLeft,
            type: cleverapps.styles.UI.Button.Images.button_blue,
            content: content,
            onClicked: this.showPhrases.bind(this)
        });
        this.addChild(button);
        button.setPositionRound(styles);
    },

    showPhrases: function () {
        if (this.phrases) {
            this.hidePhrases();
            return;
        }

        var styles = cleverapps.styles.ClansChatTab.phrases;

        var items = Object.keys(ClansConfig.MESSAGES).filter(function (type) {
            return ClansConfig.MESSAGES[type].phrase;
        }).map(function (type) {
            var content = cleverapps.UI.generateOnlyText(ClansConfig.MESSAGES[type].phrase, cleverapps.styles.FONTS.BUTTON_TEXT);
            content.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            content.setDimensions(styles.button.width, 0);
            content.fitTo(undefined, styles.button.height);

            return new cleverapps.UI.Button({
                width: styles.button.width,
                height: styles.button.height,
                type: cleverapps.styles.UI.Button.Images.button_blue,
                content: content,
                onClicked: function () {
                    cleverapps.clanMessages.postMessage({
                        type: parseInt(type)
                    });
                    this.updatePhraseButton();
                }.bind(this)
            });
        }, this);

        var layout = new cleverapps.GridLayout(items, {
            columns: 3,
            margin: styles.margin,
            padding: styles.padding
        });

        var phrases = this.phrases = cleverapps.UI.createScale9Sprite(bundles.my_clan_window.frames.phrases_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        this.phrases.setAnchorPoint(0.5, 0);
        phrases.setContentSize2(layout.width, layout.height);
        phrases.setPositionRound(styles);
        this.phraseButton.addChild(phrases);
        phrases.y += this.phraseButton.height;
        phrases.replaceParentSamePlace(this);

        phrases.addChild(layout);
        layout.setPositionRound(phrases.width / 2, phrases.height / 2);

        var arrow = new cc.Scale9Sprite(bundles.my_clan_window.frames.phrases_arrow);
        arrow.setPositionRound(styles.arrow);
        phrases.addChild(arrow);

        this.phrases.setScale(0);
        this.phrases.runAction(new cc.ScaleTo(0.2, 1).easing(cc.easeBackOut()));
    },

    hidePhrases: function () {
        if (this.phrases) {
            this.phrases.runAction(new cc.Sequence(
                new cc.ScaleTo(0.1, 0.3),
                new cc.RemoveSelf()
            ));
            this.phrases = undefined;
        }
    }
});

cleverapps.styles.ClansChatTab = {
    chatBg: {
        width: 1340,
        height: 850,
        x: { align: "center" },
        y: { align: "top", dy: 15 }
    },

    requestButton: {
        width: 300,
        height: 100,
        x: { align: "center", dx: 200 },
        y: { align: "bottom", dy: -10 },

        content: {
            margin: -15
        }
    },

    phraseButton: {
        width: 300,
        height: 100,
        margin: 15,
        verticalMargin: -15,
        x: { align: "center", dx: -200 },
        y: { align: "bottom", dy: -10 }
    },

    scroll: {
        width: 1325,
        height: 820,
        margin: 0,
        padding: {
            y: 20
        }
    },

    phrases: {
        margin: {
            x: 20,
            y: 20
        },
        padding: {
            x: 20,
            y: 20
        },
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 20 },

        button: {
            width: 250,
            height: 120
        },

        arrow: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: -26 }
        }
    }
};