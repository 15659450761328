/**
 * Created by vladislav on 31/10/2022
 */

var Customers = function () {
    cleverapps.EventEmitter.call(this);

    this.customers = [];

    this.stock = {};
};

Customers.prototype = Object.create(cleverapps.EventEmitter.prototype);
Customers.prototype.constructor = Customers;

Customers.prototype.addCustomer = function (customer) {
    this.customers.push(customer);
};

Customers.prototype.removeCustomer = function (customer) {
    var index = this.customers.indexOf(customer);
    if (index !== -1) {
        this.customers.splice(index, 1);
    }
};

Customers.prototype.listActive = function () {
    return this.customers.filter(function (customer) {
        return customer.getCurrentRecipe();
    });
};

Customers.prototype.onUnitAvailable = function (unit) {
    if (Customers.IGNORES_STOCK.indexOf(unit.code) !== -1 || !Buildable.IsBuilt(unit) || !Creatable.IsCreated(unit)) {
        return;
    }

    if (unit.stocked > 0) {
        cleverapps.throwAsync("Unit added second time " + Unit.GetKey(unit));
        return;
    }

    var key = Unit.GetKey(unit);
    this.stock[key] = (this.stock[key] || 0) + 1;

    unit.stocked = 1;

    this.updateMarks();
    this.onRecipesUpdated();
};

Customers.prototype.onUnitRemoved = function (unit) {
    if (unit.stocked < 1) {
        cleverapps.throwAsync("Unit removed second time " + Unit.GetKey(unit));
        return;
    }

    if (!unit.stocked) {
        return;
    }

    var key = Unit.GetKey(unit);
    this.stock[key] = (this.stock[key] || 0) - 1;

    unit.stocked -= 1;

    this.updateMarks();
    this.onRecipesUpdated();
};

Customers.prototype.onRecipesUpdated = function () {
    this.trigger("onRecipesUpdated");
};

Customers.prototype.updateMarks = function () {
    this.needUpdateMarks = true;
    Game.currentGame && Game.currentGame.counter.trigger();
};

Customers.prototype.clearWantsWindow = function () {
    this.customers.forEach(function (customer) {
        customer.wantsWindow = undefined;
    });
};

Customers.prototype.process = function () {
    if (cleverapps.focusManager.isFocused() || Map2d.currentMap.dragging) {
        return;
    }

    var wantsWindow;
    var requiredUnits = {};

    this.customers.forEach(function (customer) {
        if (customer.isStandby()) {
            customer.order();
        }

        customer.replaceOrderItemsWithCoins();

        var recipe = customer.getCurrentRecipe();
        if (recipe) {
            var wasReady = recipe.wasReady;
            recipe.wasReady = recipe.isReady();

            if (wasReady === false && recipe.wasReady) {
                if (!wantsWindow && Map2d.currentMap.getUnit(customer.unit.x, customer.unit.y)) {
                    wantsWindow = customer;
                }
            }

            recipe.getIngredients().forEach(function (ingredient) {
                if (ingredient.unit) {
                    requiredUnits[Unit.GetKey(ingredient.unit)] = true;
                }
            });
        }
    });

    if (this.needUpdateMarks) {
        this.needUpdateMarks = false;

        Map2d.currentMap.listAvailableUnits(function (unit) {
            unit.setCustomerMark(unit.stocked && requiredUnits[Unit.GetKey(unit)]);
        });
    }

    if (wantsWindow && !cleverapps.gameModes.silentCustomers) {
        this.showWindow(wantsWindow);
    }
};

Customers.prototype.showWindow = function (customer) {
    if (!customer) {
        var customers = this.listActive();
        customer = customers.find(function (customer) {
            return customer.isRecipeReady();
        }) || customers[0];
    }

    if (!this._openStack) {
        this._openStack = [];
    }
    this._openStack.push(Date.now() + " " + new Error().stack);
    if (this._openStack.length > 2) {
        this._openStack.shift();
    }

    var isExchange = customer.getCurrentRecipe().getOrder().length > 0;

    cleverapps.focusManager.display({
        stack: Game.currentGame.tutorial.checkTargets(customer && customer.unit) && !cleverapps.windows.isActive(),
        focus: "CustomersWindow",
        actions: [
            function (f) {
                Map2d.currentMap.zoomIn(customer.unit, {
                    position: cleverapps.UI.DOCK_LEFT | cleverapps.UI.DOCK_RIGHT,
                    zoom: 1.33,
                    centerOffset: {
                        x: 0,
                        y: -cleverapps.resolution.getSceneSize().height / (isExchange ? 6 : 12)
                    },
                    callback: f
                });
            },

            function (f) {
                Map2d.currentMap.highlightUnit(customer.unit, f);
            },

            function (f) {
                var shoppingList = customer.getCurrentRecipe().getShoppingList();
                shoppingList.totalHard && cleverapps.focusManager.showControlsWhileFocused("MenuBarGoldItem");
                shoppingList.totalSoft && cleverapps.focusManager.showControlsWhileFocused("MenuBarCoinsItem");

                if (isExchange) {
                    new ExchangeWindow(customer);
                } else {
                    new UpgradeWindow(customer);
                }

                cleverapps.focusManager.onceNoWindowsListener = f;
            },

            function (f) {
                Map2d.currentMap.customers.listActive().forEach(function (customer) {
                    customer.onUpdateState();
                }, this);
                f();
                Map2d.currentMap.zoomOut();
                Map2d.currentMap.unhighlightUnit();

                Map2d.currentMap.unitGreeters.processFresh();
            }
        ]
    });
};

Customers.prototype.findCustomerForHint = function (options) {
    var codes = options.type ? cleverapps.unitsLibrary.listCodesByType(options.type) : [options.code];
    var stage = options.stage;

    var producesType = function (customerCode) {
        var recipes = CustomerRecipes[cleverapps.travelBook.getCurrentPage().id];
        return recipes[customerCode] && recipes[customerCode].some(function (template) {
            return template.order.some(function (order) {
                var orderCodes = order.type ? cleverapps.unitsLibrary.listCodesByType(order.type) : [order.code];
                return orderCodes.some(function (orderCode) {
                    return codes.some(function (code) {
                        return code === orderCode && (stage === undefined || order.stage === stage || (!Families[code].units[stage].unmergeable && stage > order.stage));
                    });
                });
            });
        });
    };

    var priority = Object.keys(Map2d.currentMap.fogs.config);
    var fakeUnits = Map2d.currentMap.fogs.listFakeUnits({ type: cleverapps.unitsLibrary.getExpeditionUnitType("customer") });

    fakeUnits.sort(function (a, b) {
        var fogA = Map2d.currentMap.getFog(a.x, a.y);
        var fogB = Map2d.currentMap.getFog(b.x, b.y);
        return priority.indexOf(fogA && fogA.fogBlock && fogA.fogBlock.id) - priority.indexOf(fogB && fogB.fogBlock && fogB.fogBlock.id);
    });

    for (var i = 0; i < fakeUnits.length; i++) {
        var fakeUnit = fakeUnits[i];
        for (var unitStage = 0; unitStage < Families[fakeUnit.code].units.length; unitStage++) {
            if (producesType(Unit.GetKey({ code: fakeUnit.code, stage: unitStage }))) {
                var real = this.customers.find(function (customer) {
                    return customer.unit.code === fakeUnit.code;
                });
                return real && real.unit || fakeUnit;
            }
        }
    }
};

Customers.prototype.takeIngredients = function (ingredients) {
    var units = [];
    var resources = {};

    var unitsToRemove = {};

    ingredients.forEach(function (ingredient) {
        if (ingredient.unit) {
            unitsToRemove[Unit.GetKey(ingredient.unit)] = ingredient.amount;
        }

        if (ingredient.ingredient) {
            resources[ingredient.ingredient] = (resources[ingredient.ingredient] || 0) + ingredient.amount;
        }
    });

    Map2d.currentMap.listAvailableUnits(function (unit) {
        var key = Unit.GetKey(unit);
        if (unit.stocked && unitsToRemove[key]) {
            unitsToRemove[key] -= 1;
            units.push(unit);
        }
    });

    Game.currentGame.harvested.takeList(resources);

    units.forEach(function (unit) {
        unit.setCustomerMark(false);
        unit.takePrizes();
        unit.setPrizes();

        if (unit.points) {
            unit.claimPoints();
        }

        var pulsing = unit.findComponent(Pulsing);
        if (pulsing) {
            pulsing.setRemoved(true);
        }

        unit.remove();
    });
};

Customers.prototype.getStockAmount = function (ingredient) {
    if (!ingredient) {
        return 0;
    }

    if (ingredient.ingredient) {
        return Game.currentGame.harvested.get(ingredient.ingredient) || 0;
    }

    if (ingredient.unit) {
        return this.stock[Unit.GetKey(ingredient.unit)] || 0;
    }

    return 0;
};

Customers.prototype.getLackingAmount = function (ingredient) {
    return Math.max(0, ingredient.amount - this.getStockAmount(ingredient));
};

Customers.prototype.canSpawnIngredients = function (ingredients) {
    var unitsAmount = ingredients.reduce(function (total, ingredient) {
        if (ingredient.unit) {
            return total + ingredient.amount;
        }
        return total;
    }, 0);

    var cellsToFree = unitsAmount - Map2d.currentMap.countEmptySlots();

    if (cellsToFree > 0) {
        cleverapps.centerHint.createTextHint("Spawn.nospace", { left: cellsToFree });
        return false;
    }
    return true;
};

Customers.prototype.spawnIngredients = function (ingredients, lacking) {
    var cell = Map2d.currentMap.getScreenCenterCell();

    for (var i = 0; i < ingredients.length; i++) {
        var ingredient = ingredients[i];
        var amount = lacking ? this.getLackingAmount(ingredient) : ingredient.amount;

        if (ingredient.unit) {
            for (var j = 0; j < amount; ++j) {
                var slot = Map2d.currentMap.findEmptySlot(cell.x, cell.y);

                var unit = new Unit(ingredient.unit);
                unit.setPosition(slot.x, slot.y);
                Map2d.currentMap.add(Map2d.LAYER_UNITS, unit.x, unit.y, unit);
                Map2d.currentMap.onAddUnit(unit.x, unit.y, unit);
                Map2d.currentMap.onUnitAvailable(unit);
            }
        }

        if (ingredient.ingredient) {
            Game.currentGame.harvested.add(ingredient.ingredient, amount);
        }
    }
};

Customers.prototype.canTakeIngredients = function (ingredients) {
    for (var i = 0; i < ingredients.length; i++) {
        var ingredient = ingredients[i];

        if (this.getStockAmount(ingredient) < ingredient.amount) {
            return false;
        }
    }
    return true;
};

Customers.IGNORES_STOCK = ["unknown", "multiCellBody", "thirdelement", "barrel", "caravanship", "drgrowing", "seagrowing"];
