/**
 * Created by vladislav on 20/10/2022
 */

var Customer = function (unit, options) {
    UnitComponent.call(this, unit);

    if (cleverapps.config.adminMode || cleverapps.config.editorMode || cleverapps.config.wysiwygMode) {
        return;
    }

    this.restore(options);

    Map2d.currentMap.customers.addCustomer(this);
};

Customer.prototype = Object.create(UnitComponent.prototype);
Customer.prototype.constructor = Customer;

Customer.prototype.restore = function (options) {
    var appearTime = options.appearTime || options.seed;
    if (appearTime && cleverapps.gameModes.silentCustomers) {
        appearTime -= Customer.COOLDOWN;
    }
    var cookingTime = options.ct;

    var seed = options.seed;
    var recipe = this.chooseFreeRecipe(seed);

    if (recipe && this.unit.prizesCount) {
        this._setState(Customer.STATE_READY, recipe, seed);
    } else if (recipe && cookingTime) {
        this._setState(Customer.STATE_COOKING, recipe, seed, cookingTime);
    } else if (recipe && seed && !appearTime) {
        this._setState(Customer.STATE_RECIPE, recipe, seed);
    } else {
        this._setState(Customer.STATE_EMPTY, undefined, seed, appearTime || Date.now());
    }
};

Customer.prototype.onPrizeHarvested = function () {
    this.reset();
};

Customer.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    }
};

Customer.prototype.handleClick = function () {
    if (this.getCookingTimeLeft()) {
        cleverapps.focusManager.display({
            focus: "ConfirmSpeedUpWindowCustomer",
            action: function (f) {
                new ConfirmSpeedUpWindow({
                    timeLeft: this.getCookingTimeLeft(),
                    totalDuration: this.getCookingDuration(),
                    eventName: cleverapps.EVENTS.SPENT.CUSTOMER,
                    callback: this.enjoy.bind(this),
                    canCoins: false
                });
                cleverapps.focusManager.onceNoWindowsListener = f;
            }.bind(this)
        });

        return;
    }

    if (!this.recipe) {
        if (!this.hasSomeRecipes()) {
            cleverapps.centerHint.createTextHint("Customer.NoMore");
        } else if (this._getRecipeRequiredUnit()) {
            CustomerHintContent.pointToCustomer(this.unit.code, this._getRecipeRequiredUnit());
        }
        return false;
    }

    this.unit.squeeze();

    Map2d.currentMap.customers.showWindow(this);

    return true;
};

Customer.prototype.handlePrizes = function () {
    return this.unit.prizes.waiting;
};

Customer.prototype.showWaiting = function () {
    if (!this.unit.prizes && this.getTimeLeft() > 0 && Boolean(CustomerRecipe.selectTemplate(this.unit, this.seed))) {
        this.unit.setPrizes({ waiting: true });
    }
};

Customer.prototype.handleRemove = function () {
    Map2d.currentMap.customers.removeCustomer(this);
};

Customer.prototype.exchange = function (callback) {
    Map2d.mapEvent(Map2d.BEFORE_EXCHANGE, { affected: this.unit });

    this.recipe.cook(this.unit, function () {
        var timeLeft = this.getCookingTimeLeft();
        var climbable = this.unit.findComponent(Climbable);
        if (climbable && !timeLeft && !this.unit.isLast()) {
            climbable.gotoNextStage(callback);
            return;
        }

        callback();
    }.bind(this));

    this.cook(this.recipe);

    this.unit.squeeze();
};

Customer.prototype.cook = function (recipe) {
    this._setState(Customer.STATE_COOKING, recipe, this.seed, Date.now() + recipe.getCookingDuration());
};

Customer.prototype.enjoy = function () {
    this._setState(Customer.STATE_READY, this.recipe, this.seed);
};

Customer.prototype.reset = function () {
    Map2d.mapEvent(Map2d.EXCHANGE, { affected: this.unit });

    var climbable = this.unit.findComponent(Climbable);
    if (climbable && !this.unit.isLast()) {
        climbable.gotoNextStage();
        return;
    }

    this._setState(Customer.STATE_EMPTY, undefined, Date.now(), Date.now());
};

Customer.prototype.order = function () {
    var recipe = this.chooseFreeRecipe(this.seed);
    if (recipe) {
        this._setState(Customer.STATE_RECIPE, recipe, this.seed);
    }
};

Customer.prototype.chooseFreeRecipe = function (seed) {
    var recipeTemplate = CustomerRecipe.selectTemplate(this.unit, seed);
    if (recipeTemplate) {
        return new CustomerRecipe(recipeTemplate, seed);
    }
};

Customer.prototype.isRecipeReady = function () {
    return Boolean(this.recipe && this.recipe.isReady());
};

Customer.prototype.getCurrentRecipe = function () {
    if (!this.cookingEndTime && !this.unit.prizesCount) {
        return this.recipe;
    }
};

Customer.prototype.getCookingRecipe = function () {
    if (this.cookingEndTime) {
        return this.recipe;
    }
};

Customer.prototype.hasSomeRecipes = function (useFilter) {
    return Boolean(CustomerRecipe.selectTemplate(this.unit, this.seed, !useFilter));
};

Customer.prototype._getRecipeRequiredUnit = function () {
    var recipe = CustomerRecipe.selectTemplate(this.unit, this.seed, true);
    if (recipe && recipe.requiredUnits) {
        return recipe.requiredUnits.filter(function (unit) {
            return !cleverapps.unitsLibrary.isOpened(unit);
        })[0];
    }
};

Customer.prototype.isStandby = function () {
    return !this.unit.prizesCount && !this.getCurrentRecipe() && !this.getTimeLeft() && !this.getCookingTimeLeft() && this.hasSomeRecipes();
};

Customer.prototype.save = function (data) {
    data.seed = this.seed;
    data.appearTime = this.appearTime;
    data.ct = this.cookingEndTime;
};

Customer.prototype.destructor = function () {
    clearTimeout(this.timeout);
};

Customer.prototype.getTimeLeft = function () {
    return Math.max(0, this.appearTime + Customer.COOLDOWN - Date.now());
};

Customer.prototype.getCookingTimeLeft = function () {
    if (this.cookingEndTime) {
        return Math.max(0, this.cookingEndTime - Date.now());
    }
    return 0;
};

Customer.prototype.getCookingDuration = function () {
    if (this.cookingEndTime) {
        return this.recipe.getCookingDuration();
    }
    return 0;
};

Customer.prototype.isResting = function () {
    return !this.unit.prizesCount && !this.cookingEndTime && !this.recipe;
};

Customer.prototype.getGuideOptions = function () {
    return this.unit.getData().customer.guide;
};

Customer.prototype.replaceOrderItemsWithCoins = function () {
    if (!this.recipe) {
        return;
    }
    var isReplaced;
    this.recipe.order.forEach(function (item) {
        if (item.unit && Game.currentGame.needToReplaceWithCoins(item.unit)) {
            item.unit = Game.currentGame.getUnitReplacer(item.unit);
            isReplaced = true;
        }
    });
    if (isReplaced && this.unit.prizesCount) {
        this.unit.setPrizes(this.recipe.listPrize());
    }
};

Customer.prototype._setState = function (state, recipe, seed, time) {
    clearTimeout(this.timeout);
    this.timeout = undefined;

    this.recipe = undefined;
    this.cookingEndTime = undefined;
    this.appearTime = undefined;

    this.unit.setPrizes();

    switch (state) {
        case Customer.STATE_RECIPE:
            this.seed = seed;
            this.recipe = recipe;
            break;

        case Customer.STATE_COOKING:
            this.seed = seed;
            this.recipe = recipe;
            this.cookingEndTime = time;
            this.timeout = new cleverapps.LongTimeout(this.enjoy.bind(this), this.getCookingTimeLeft());
            break;

        case Customer.STATE_READY:
            this.seed = seed;
            this.recipe = recipe;
            this.unit.setPrizes(recipe.listPrize());
            break;

        default:
            this.seed = seed;
            this.appearTime = time;
            this.timeout = new cleverapps.LongTimeout(this.order.bind(this), this.getTimeLeft());
    }

    this.unit.save();

    this.showWaiting();
    this.onUpdateState();

    Map2d.currentMap.customers.updateMarks();
};

Customer.COOLDOWN = cleverapps.parseInterval("10 seconds");

Customer.STATE_EMPTY = 0;
Customer.STATE_RECIPE = 1;
Customer.STATE_COOKING = 2;
Customer.STATE_READY = 3;
