/**
 * Created by mac on 12/23/20
 */

var FruitView = cc.Node.extend({
    ctor: function (fruit) {
        this._super();
        this.fruit = fruit;

        if (cleverapps.gameModes.hideFruitParticlesAnimation) {
            return;
        }

        this.restoreState();
        var sfxs = [this.getStartSfx(), this.getCompleteSfx()].filter(function (sfx) {
            return sfx && sfx.resolve();
        });
        if (sfxs.length) {
            cleverapps.bundleLoader.ensureSoundsLoaded(sfxs);
        }
    },

    restoreState: function (animated) {
        if (!this.fruit.unit.isBuilt()) {
            return;
        }

        if (cleverapps.silentIntro) {
            animated = false;
        }

        this.createGrowthAnimation();

        if (this.fruit.isGrowing()) {
            this.startGrowth(animated);
        } else {
            this.completeGrowth(animated);
        }
    },

    createGrowthAnimation: function () {
        if (this.animation) {
            return;
        }

        var json = bundles.fruit.jsons.fruit_animation_json;
        if (this.fruit.unit.getType() === "resource") {
            json = bundles.fruit.jsons.castle_animation_json;
        } else if (this.fruit.unit.getType() === "drgenerator") {
            json = bundles.fruit.jsons.generator_animation_json;
        }

        this.animation = new cleverapps.Spine(json);
        this.addChild(this.animation);
    },

    getStartSfx: function () {
        return bundles.merge.urls[this.fruit.unit.code + "_start_effect"] || bundles.fruit.urls[this.fruit.unit.getType() + "_start_effect"];
    },

    getCompleteSfx: function () {
        return bundles.merge.urls[this.fruit.unit.code + "_complete_effect"] || bundles.fruit.urls[this.fruit.unit.getType() + "_complete_effect"];
    },

    startGrowth: function (animated) {
        this.animation.setAnimation(0, "growing", true);

        if (animated) {
            cleverapps.audio.playSound(this.getStartSfx());
        }
    },

    completeGrowth: function (animated) {
        if (!animated) {
            this.animation.setAnimation(0, "ready", true);
            return;
        }

        this.animation.setAnimationAndIdleAfter("blink", "ready");
        cleverapps.audio.playSound(this.getCompleteSfx());
    }
});