/**
 * Created by andrey on 07.02.2022.
 */

FogsConfig.initialize = cleverapps.extendFunc(FogsConfig.initialize, function () {
    this._super();

    FogsConfig.main = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        }, // wood_start
        fog1: {
            id: 1,
            price: 0,
            exp: 0,
            headless: true
        },
        fog2: {
            id: 2,
            price: 5,
            exp: 10,
            autoOpen: true,
            conditions: {
                unit: { code: "wands", stage: 0, hidden: true }
            }
        },
        fog3: {
            id: 3,
            price: 5,
            exp: 10,
            parentFogId: "fog2",
            silentUnblock: true,
            conditions: {
                level: 3
            }
        },
        fog4_0: {
            id: 4,
            price: 5,
            exp: 20,
            parentFogId: "fog3",
            conditions: {
                level: 4
            }
        },
        fog4_1: {
            id: 5,
            price: 5,
            exp: 25,
            parentFogId: "fog3",
            conditions: {
                level: 4
            }
        }, // wood_castle
        fog5: {
            id: 6,
            price: 15,
            exp: 50,
            parentFogId: "fog4_0",
            conditions: {
                level: 5
            }
        },
        fog6: {
            id: 7,
            price: 20,
            exp: 50,
            parentFogId: "fog4_1",
            conditions: {
                level: 6,
                hero: "king"
            }
        }, // wood_generator

        fog7: {
            id: 8,
            price: 115,
            exp: 50,
            parentFogId: "fog6",
            conditions: {
                level: 7
            }
        }, // stone_start
        fog8: {
            id: 9,
            price: 225,
            exp: 50,
            parentFogId: "fog7",
            conditions: {
                level: 8,
                hero: "bear"
            }
        }, // stone_castle
        fog9: {
            id: 10,
            price: 500,
            exp: 50,
            parentFogId: "fog7",
            conditions: {
                level: 9,
                hero: "bear"
            }
        },

        fog10: {
            id: 11,
            price: 1000,
            exp: 50,
            parentFogId: "fog9",
            conditions: {
                level: 10
            }
        }, // stone_generator + wagon_start

        fog11: {
            id: 12,
            price: 1500,
            exp: 50,
            parentFogId: "fog8",
            conditions: {
                level: 11,
                hero: "elf"
            }
        }, // wagon_castle
        fog12: {
            id: 13,
            price: 1300,
            exp: 50,
            parentFogId: "fog11",
            conditions: {
                level: 12
            }
        }, // wagon_generator

        fog13: {
            id: 14,
            price: 1600,
            exp: 50,
            parentFogId: "fog12",
            conditions: {
                level: 13,
                hero: "elf",
                family: "wood"
            }
        }, // bamboo_start
        fog14: {
            id: 15,
            price: 1950,
            exp: 50,
            parentFogId: "fog13",
            conditions: {
                level: 14,
                family: "wood"
            }
        }, // bamboo_castle
        fog15: {
            id: 16,
            price: 2350,
            exp: 50,
            parentFogId: "fog14",
            conditions: {
                level: 15,
                family: "wood"
            }
        }, // bamboo_generator

        fog16: {
            id: 17,
            price: 2800,
            exp: 50,
            parentFogId: "fog11",
            conditions: {
                level: 16,
                hero: "troll",
                family: "stone"
            }
        }, // sapphire_start
        fog17: {
            id: 18,
            price: 3300,
            exp: 50,
            parentFogId: "fog16",
            conditions: {
                level: 17,
                family: "stone"
            }
        }, // sapphire_castle
        fog18: {
            id: 19,
            price: 4160,
            exp: 50,
            parentFogId: "fog16",
            conditions: {
                level: 18,
                family: "stone"
            }
        }, // sapphire_generator

        fog19_2: {
            id: 20,
            price: 5200,
            exp: 50,
            parentFogId: "fog16",
            conditions: {
                level: 19,
                hero: "troll",
                family: "wagon"
            }
        }, // snow_start
        fog20_2: {
            id: 21,
            price: 7150,
            exp: 50,
            parentFogId: "fog19_2",
            conditions: {
                level: 20,
                family: "wagon"
            }
        }, // snow_castle
        fog21_2: {
            id: 22,
            price: 8400,
            exp: 50,
            parentFogId: "fog20_2",
            conditions: {
                level: 21,
                family: "wagon"
            }
        }, // snow_generator

        // fog22 already opened need fog22_1
        fog22_1: {
            id: 30,
            price: 9600,
            exp: 50,
            parentFogId: "fog17",
            conditions: {
                family: "bamboo"
            }
        }, // lava_start
        fog23: {
            id: 31,
            price: 10900,
            exp: 50,
            parentFogId: "fog22_1",
            conditions: {
                family: "bamboo"
            }
        }, // lava_castle
        fog24: {
            id: 32,
            price: 12400,
            exp: 50,
            parentFogId: "fog22_1",
            conditions: {
                family: "bamboo"
            }
        }, // lava_generator

        fog25: {
            id: 37,
            price: 9600,
            exp: 50,
            parentFogId: "fog15",
            conditions: {
                family: "sapphire"
            }
        }, // sand_start
        fog26: {
            id: 38,
            price: 10900,
            exp: 50,
            parentFogId: "fog25",
            conditions: {
                family: "sapphire"
            }
        }, // sand_castle
        fog27: {
            id: 39,
            price: 12400,
            exp: 50,
            parentFogId: "fog25",
            conditions: {
                family: "sapphire"
            }
        }, // sand_generator

        fog28: {
            id: 41,
            price: 9600,
            exp: 50,
            parentFogId: "fog27",
            conditions: {
                family: "snow"
            }
        }, // asia_start
        fog29: {
            id: 42,
            price: 10900,
            exp: 50,
            parentFogId: "fog28",
            conditions: {
                family: "snow"
            }
        }, // asia_castle
        fog30: {
            id: 43,
            price: 12400,
            exp: 50,
            parentFogId: "fog28",
            conditions: {
                family: "snow",
                hero: "gnomes"
            }
        }, // asia_generator -gnomes

        moneyFog1: {
            id: 23,
            price: 240,
            windowSkin: "green",
            money: true,
            parentFogId: "fog4_1"
        },
        moneyFog2: {
            id: 24,
            price: 900,
            windowSkin: "green",
            money: true,
            parentFogId: "fog7",
            conditions: {
                level: 7
            }
        },
        moneyFog3: {
            id: 25,
            price: 800,
            windowSkin: "green",
            money: true,
            parentFogId: "fog7",
            conditions: {
                hero: "bear"
            }
        },
        moneyFog4: {
            id: 26,
            price: 1100,
            windowSkin: "green",
            money: true,
            parentFogId: "fog12"
        },
        moneyFog5: {
            id: 27,
            price: 1500,
            windowSkin: "green",
            money: true,
            parentFogId: "fog8",
            conditions: {
                hero: "bear"
            }
        },
        moneyFog6: {
            id: 33,
            price: 1799,
            windowSkin: "green",
            money: true,
            parentFogId: "fog9"
        },
        moneyFog7: {
            id: 34,
            price: 3599,
            money: true,
            windowSkin: "green",
            soft: true,
            parentFogId: "fog10"
        },
        moneyFog8: {
            id: 35,
            price: 599,
            windowSkin: "green",
            money: true,
            parentFogId: "fog5",
            conditions: {
                hero: "king"
            }
        },
        moneyFog9: {
            id: 36,
            price: 1499,
            windowSkin: "green",
            money: true,
            parentFogId: "fog19_2"
        },
        moneyFog10: {
            id: 40,
            price: 2199,
            windowSkin: "green",
            money: true,
            parentFogId: "fog15"
        },
        moneyFog11: {
            id: 44,
            price: 1990,
            windowSkin: "green",
            money: true,
            parentFogId: "fog11"
        },
        moneyFog12: {
            id: 46,
            price: 7490,
            windowSkin: "green",
            money: true,
            parentFogId: "fog25"
        },
        moneyFog13: {
            id: 47,
            price: 3299,
            windowSkin: "green",
            money: true,
            parentFogId: "fog28"
        },

        fog99: {
            id: 28,
            price: 1000,
            exp: 50,
            available: false,
            conditions: {
                level: 100
            }
        },

        dragonIsland: {
            id: 29,
            special: true,
            price: 1000,
            parentFogId: "fog5"
        },

        landmarkfog: {
            id: 45,
            price: 0,
            exp: 0,
            parentFogId: "fog6"
        }
    };

    FogsConfig.afterInitialize();
});

FogsConfig.initialize();
