/**
 * Created by Andrey Popov on 14.03.2024
 */

var QuestInfoView = cc.Node.extend({
    ctor: function (questIcon, options) {
        this._super();
        this.options = options || {};

        this.quest = questIcon.quest;
        this.questIcon = questIcon;

        var styles = cleverapps.styles.QuestInfoView;

        if (this.isCompact()) {
            this.setContentSize2(styles.compact);

            this.setAnchorPoint(0, 0.5);

            this.bg = new cc.Scale9Sprite(bundles.questinfo.frames.info_bg);
            this.addChild(this.bg);
            this.bg.setContentSize2(this.getContentSize());

            var checkMark = new cc.Sprite(bundles.questinfo.frames.info_thumbsup);
            checkMark.setPositionRound(this.bg.width / 2, this.bg.height / 2);
            this.addChild(checkMark);

            this.arrow = new cc.Sprite(bundles.questinfo.frames.info_arrow_compact);
            this.arrow.setPositionRound(styles.compact.arrow);
            this.addChild(this.arrow);
        } else {
            this.addContent();
        }

        if (this.isCompletion()) {
            this.showCompletion();
        } else {
            this.show();
        }

        this.setupChildren();

        var clickHandler = cleverapps.UI.onClick(this, function (touch) {
            if (this.magnifier) {
                var magnifierClick = cc.rectContainsPoint(cc.rect(0, 0, this.magnifier.width, this.magnifier.height), this.magnifier.convertTouchToNodeSpace(touch));
                var iconBgClick = cc.rectContainsPoint(cc.rect(0, 0, this.iconBg.width, this.iconBg.height), this.iconBg.convertTouchToNodeSpace(touch));
                                
                if (magnifierClick || iconBgClick) {
                    clickHandler.setEnabled(false);
                    this.quest.find();
                    this.hide();
                }
            }
        }.bind(this), {
            interactiveScale: false
        });

        this.setCascadeOpacityEnabled(true);
    },

    addContent: function () {
        var styles = cleverapps.styles.QuestInfoView.regular;

        this.bg = new cc.Scale9Sprite(bundles.questinfo.frames.info_bg);
        this.addChild(this.bg);

        var title = this.createTitle();
        title.setPositionRound(styles.title);
        this.addChild(title);

        this.iconBg = this.createIcon();
        this.iconBg.setPositionRound(styles.icon);
        this.addChild(this.iconBg);

        var rewards = this.createRewards();
        rewards.setPositionRound(styles.rewards);
        this.addChild(rewards);

        this.setContentSize(styles.width, styles.height + title.height);

        this.bg.setContentSize2(this.getContentSize());

        this.arrow = new cc.Sprite(bundles.questinfo.frames.info_arrow);
        this.arrow.setPositionRound(styles.arrow.x, this.height - title.height / 2 + styles.arrow.offsetY);
        this.addChild(this.arrow);

        this.setAnchorPoint(0, this.arrow.y / this.height);
    },

    createTitle: function () {
        var styles = cleverapps.styles.QuestInfoView.regular;

        var titleBg = new cc.Scale9Sprite(bundles.questinfo.frames.info_title);

        var title = cleverapps.UI.generateOnlyText(this.quest.getTitle(), cleverapps.styles.FONTS.QUESTPANEL_CAPTION);
        title.setDimensions(styles.title.width - 2 * styles.title.padding.x, 0);
        var titleBgHeight = Math.min(title.height + 2 * styles.title.padding.y, styles.title.maxHeight);
        titleBg.setContentSize(styles.title.width, titleBgHeight);
        title.fitTo(undefined, titleBgHeight - 2 * styles.title.padding.y);
        title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        title.setPositionRound(titleBg.width / 2, titleBg.height / 2);
        titleBg.addChild(title);

        return titleBg;
    },

    createIcon: function () {
        var styles = cleverapps.styles.QuestInfoView.regular;

        var iconBg = new cc.Scale9Sprite(bundles.questinfo.frames.info_icon_bg);
        iconBg.setContentSize(styles.icon);
        var icon = QuestIcon.getIconImage(this.quest);
        cleverapps.UI.fitToBox(icon, {
            width: 0.8 * iconBg.width,
            height: 0.8 * iconBg.height,
            maxScale: 1.6
        });
        icon.setPositionRound(iconBg.width / 2, iconBg.height / 2 + styles.icon.offsetY);
        iconBg.addChild(icon);

        if (!this.isCompletion() && (!this.quest.unit || !this.quest.unit.code || !Families[this.quest.unit.code].oneViewForStages)) {
            this.magnifier = new cc.Sprite(bundles.questinfo.frames.info_magnifier);
            this.magnifier.setPositionRound(styles.icon.magnifier);
            cleverapps.UI.applyHover(this.magnifier);
            iconBg.addChild(this.magnifier);
        }

        return iconBg;
    },

    createRewards: function () {
        var styles = cleverapps.styles.QuestInfoView.regular;

        var rewardsTitle = cleverapps.UI.generateOnlyText("RewardsListComponent.rewardTitle", cleverapps.styles.FONTS.QUESTPANEL_REWARDS);
        var rewardsList = this.rewardsList = new RewardsListComponent(this.quest.config.prize || {}, {
            toMainWorld: this.quest.config.toMainWorld,
            font: cleverapps.styles.FONTS.QUESTPANEL_REWARDS,
            columns: 6,
            noPrefix: true,
            small: true,
            textDirection: cleverapps.UI.HORIZONTAL,
            event: cleverapps.EVENTS.EARN.OTHER
        });

        return new cleverapps.Layout([rewardsTitle, rewardsList], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.rewards.margin
        });
    },

    hide: function () {
        this.stopAllActions();

        this.runAction(
            new cc.Sequence(
                new cc.Spawn(
                    new cc.ScaleTo(0.15, 0).easing(cc.easeIn(2)),
                    new cc.FadeOut(0.15).easing(cc.easeIn(2))
                ),
                new cc.RemoveSelf()
            )
        );
    },

    setupChildren: function () {
        var box = this.questIcon.getGlobalBoundingBox();
        var scene = cleverapps.scenes.getRunningScene();

        var position = scene.convertToNodeSpace(cc.p(
            Math.max(cleverapps.styles.QuestInfoView.minX, box.x + box.width + 1.2 * this.arrow.width),
            box.y + box.height / 2
        ));

        this.setPositionRound(position);
        this.bg.setPositionRound(this.width / 2, this.height / 2);
    },

    show: function () {
        this.stopAllActions();

        this.setVisible(false);

        if (this.isCompact()) {
            var styles = cleverapps.styles.QuestInfoView.compact;
            this.runAction(new cc.Sequence(
                new cc.ScaleTo(0, 0.2),
                new cc.Show(),
                new cc.ScaleTo(0.2, 1).easing(cc.easeBackOut()),
                new cc.MoveBy(styles.duration, styles.delta, 0).easing(cc.easeInOut(1.7)),
                new cc.MoveBy(styles.duration, -styles.delta, 0).easing(cc.easeInOut(1.7)),
                new cc.MoveBy(styles.duration, styles.delta, 0).easing(cc.easeInOut(1.7)),
                new cc.MoveBy(styles.duration, -styles.delta, 0).easing(cc.easeInOut(1.7)),
                new cc.CallFunc(function () {
                    this.hide();
                }.bind(this))
            ));
        } else {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(this.options.delay || 0),
                new cc.CallFunc(this.setupChildren.bind(this)),
                new cc.ScaleTo(0, 0.22, 0.2),
                new cc.Show(),
                new cc.ScaleTo(0.15, 1.1, 1),
                new cc.ScaleTo(0.06, 0.9, 1.1).easing(cc.easeOut(2)),
                new cc.ScaleTo(0.06, 1, 1).easing(cc.easeIn(2)),
                new cc.DelayTime(this.options.duration || 5),
                new cc.CallFunc(this.hide.bind(this))
            ));
        }
    },

    showCompletion: function () {
        this.stopAllActions();

        this.setVisible(false);

        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0, 0.2),
            new cc.CallFunc(function () {
                this.questIcon.highlight(0.8);
            }.bind(this)),
            new cc.DelayTime(0.5),
            new cc.CallFunc(this.setupChildren.bind(this)),
            new cc.Show(),
            new cc.PlaySound(bundles.questsgroupicon.urls.quest_completed_effect),
            new cc.ScaleTo(0.15, 1.1, 1),
            new cc.ScaleTo(0.06, 0.9, 1.1).easing(cc.easeOut(2)),
            new cc.ScaleTo(0.06, 1, 1).easing(cc.easeIn(2)),
            new cc.DelayTime(0.5),
            new cc.CallFunc(function () {
                var checkmark = new cc.Sprite(bundles.questinfo.frames.info_mark);
                checkmark.setAnchorPoint(0.5, 0.5);
                checkmark.setPositionRound(cleverapps.styles.QuestInfoView.regular.icon.checkmark);
                this.iconBg.addChild(checkmark);

                checkmark.setScale(1.7);
                checkmark.setOpacity(0.3);
                checkmark.runAction(new cc.Sequence(
                    new cc.FadeIn(0.1),
                    new cc.ScaleTo(0.3, 0.9).easing(cc.easeOut(1)),
                    new cc.ScaleTo(0.1, 1)
                ));

                var confetti = new cleverapps.Spine(bundles.questinfo.jsons.confetti_quests_json);
                confetti.setAnchorPoint(0.5, 0.5);
                confetti.setPositionRound(this.width / 2, this.height / 2);
                confetti.setAnimation(0, "animation", false);
                confetti.setCompleteListenerRemove();
                this.addChild(confetti);

                var shine = new cleverapps.Spine(bundles.questinfo.jsons.big_shine_quests_json);
                shine.setAnchorPoint(0.5, 0.5);
                shine.setPositionRound(this.width / 2, this.height / 2);
                shine.setAnimation(0, "animation", false);
                shine.setCompleteListenerRemove();
                this.addChild(shine, -1);

                var glowTop = new cleverapps.Spine(bundles.questinfo.jsons.linear_glow_quests_json);
                glowTop.setAnchorPoint(0.5, 0.5);
                glowTop.setPositionRound(this.width / 2, this.height);
                glowTop.setAnimation(0, "animation", false);
                glowTop.setCompleteListenerRemove();
                this.addChild(glowTop);

                var glowBottom = new cleverapps.Spine(bundles.questinfo.jsons.linear_glow_quests_json);
                glowBottom.setAnchorPoint(0.5, 0.5);
                glowBottom.setPositionRound(this.width / 2, 0);
                glowBottom.setAnimation(0, "animation", false);
                glowBottom.setCompleteListenerRemove();
                this.addChild(glowBottom);
            }.bind(this)),
            new cc.ScaleTo(0.2, 1.1).easing(cc.easeOut(2)),
            new cc.ScaleTo(0.2, 1).easing(cc.easeIn(2)),
            new cc.DelayTime(0.4),
            new cc.CallFunc(function () {
                this.rewardsList.receiveRewardsAnimation();
            }.bind(this)),
            new cc.DelayTime(0.3),
            new cc.Spawn(
                new cc.ScaleTo(0.2, 0).easing(cc.easeIn(2)),
                new cc.FadeOut(0.2).easing(cc.easeIn(2))
            ),
            new cc.CallFunc(function () {
                this.options.callback();
                this.rewardsList.receiveRewards();
            }.bind(this)),
            new cc.RemoveSelf()
        ));
    },

    isCompact: function () {
        return this.options.compact;
    },

    isCompletion: function () {
        return this.options.callback;
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    QUESTPANEL_CAPTION: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },

    QUESTPANEL_REWARDS: {
        name: "nostroke",
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT,
        size: 44
    }
});

cleverapps.styles.QuestInfoView = {
    regular: {
        width: 620,
        height: 250,

        title: {
            width: 620,
            maxHeight: 140,

            padding: {
                x: 15,
                y: 15
            },

            x: { align: "center", dx: 0 },
            y: { align: "top", dy: 0 }
        },

        icon: {
            width: 160,
            height: 160,

            x: { align: "center", dx: -190 },
            y: { align: "bottom", dy: 50 },

            offsetY: 10,

            magnifier: {
                x: { align: "right", dx: 30 },
                y: { align: "bottom", dy: -16 }
            },

            checkmark: {
                x: { align: "right", dx: 24 },
                y: { align: "top", dy: 14 }
            }
        },

        rewards: {
            x: { align: "center", dx: 104 },
            y: { align: "bottom", dy: 78 },
            margin: 0
        },

        arrow: {
            x: { align: "left", dx: -38 },
            offsetY: -5
        }
    },

    compact: {
        width: 148,
        height: 142,

        arrow: {
            x: { align: "left", dx: -38 },
            y: { align: "top", dy: -42 }
        },

        duration: 0.5,
        delta: 25
    },

    minX: 286
};