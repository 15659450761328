/**
 * Created by olga on 27.09.2024
 */

var FixableView = cc.Node.extend({
    ctor: function (component, unitView) {
        this._super();

        this.unitView = unitView;
    },

    afterUpgradeStage: function (f, previousStageImage) {
        if (this.unitView.unit.findComponent(Switchable)) {
            this.previousStageImage = previousStageImage;
            this.unitView.setVisible(false);
            previousStageImage.setVisible(false);
            f();
            return;
        }

        if (!this.unitView.sprite.isVisible()) {
            previousStageImage.removeFromParent();
            f();
            return;
        }

        if (this.unitView.sprite.hasAnimation("switch0") && this.unitView.sprite.hasAnimation("choice0")) {
            previousStageImage.removeFromParent();
            this.unitView.sprite.setAnimationAndIdleAfter("switch0", "choice0");
            this.unitView.sprite.setCompleteListenerOnce(f);
        } else {
            this.unitView.setVisible(false);
            AnimationsLibrary.upgrade(previousStageImage, this.unitView, {
                cloud: true,
                parent: Map2d.currentMap.getMapView().animations,
                callback: f
            });
        }
    }
});