/**
 * Created by razial on 19.10.2021
 */

cleverapps.SkinManager.SKINS.halloween_expedition = {
    bundle: "expedition_halloween",

    slots: Object.assign({}, cleverapps.SkinManager.SKINS.rose_energy.slots, {
        miningEffects: function () {
            return {
                animation: "chop",
                sound: bundles.merge_halloween.urls.worker_sound
            };
        },

        gameAudio: function () {
            return bundles.expedition_halloween.urls.halloween_music;
        },

        worker_json: function () {
            return bundles.merge_halloween.jsons.worker_json;
        },

        workerSkin: function () {
            var json = cleverapps.skins.getSlot("worker_json") || bundles.mineable.jsons.worker_json;
            return cleverapps.Spine.hasSkin("halloween", json) ? "halloween" : undefined;
        },

        avatarFramesBundle: function () {
            return cleverapps.bundleLoader.isLoaded("snail_feast_halloween") ? "snail_feast_halloween" : undefined;
        },

        bonusWorkerPersonSkin: function () {
            if (cleverapps.config.name === "mergecraft") {
                return "halloween";
            }
        },

        unitIcon: function (unit) {
            return bundles.unit_icons_halloween.frames["small_icon_family_" + unit.code];
        },

        windowDecors: function (options) {
            return cleverapps.SkinManager.SKINS.halloween.slots.windowDecors(options);
        },
    
        menuBarItem: function () {
            return cleverapps.styles.SceneDecors.halloween_menubar;
        },

        menuBarLevelItem: function () {
            return cleverapps.styles.SceneDecors.halloween_menubar_level;
        },

        gameSceneBg: function () {
            return {};
        },

        merge_wand_png: bundles.menubar.frames.halloween_merge_wand_png,

        merge_worker_png: bundles.menubar.frames.halloween_merge_worker_png,

        worker_icon_png: bundles.buttons_inlined_icons.frames.halloween_worker_icon_png,
        wand_icon_png: bundles.buttons_inlined_icons.frames.halloween_wand_icon_png,

        expedition_buildpass_icon_json: bundles.sidebar.jsons.halloween_expedition_buildpass_icon_json,

        reward_worker_png: bundles.reward_icons.frames.halloween_reward_worker_png,
        reward_wand_png: bundles.reward_icons.frames.halloween_reward_wand_png,
        reward_wand_small_png: bundles.reward_icons.frames.halloween_reward_wand_small_png,

        feed_icon_png: bundles.buttons_inlined_icons.frames.halloween_feed_icon_png,

        snail_feast_icon_json: bundles.sidebar.jsons.halloween_snail_feast_icon_json,
        snailhouse_offer_json: bundles.sidebar.jsons.halloween_snailhouse_offer_json,
        snail_feast_icon: bundles.reward_icons.frames.halloween_snail_feast_icon,

        sellerPerson: function () {
            return "hlseller";
        },

        fogballoon_showup_effect: function () {
            return bundles.merge.urls.fogballoon_showup_effect;
        },

        fogballoon_buy_effect: function () {
            return bundles.merge.urls.fogballoon_buy_effect;
        },

        fog_disappear_effect: function () {
            return bundles.fog_halloween.urls.open_effect;
        }
    })
};
