/**
 * Created by mac on 12/23/20
 */

var MultiCellView = function (unit, unitView) {
    var shape = Unit.GetShape(unit);
    if (shape.length > 1) {
        var pos = MultiCellView.calcCenterPosition(shape, unitView);
        unitView.x += pos.x;
        unitView.y += pos.y;
    }
};

MultiCellView.calcCenterPosition = function (shape, unitView) {
    if (unitView && unitView.hasBoneAnchor && unitView.hasBoneAnchor()) {
        return cc.p(0, 0);
    }

    var centerX = 0, centerY = 0;
    for (var i = 0; i < shape.length; i++) {
        var x = shape[i].x + shape[i].y + 1;
        var y = shape[i].x - shape[i].y;
        centerX += x * cleverapps.styles.Map2dView.cell.width / 2;
        centerY += y * cleverapps.styles.Map2dView.cell.height / 2;
    }
    centerX /= shape.length;
    centerY /= shape.length;
    centerX -= cleverapps.styles.Map2dView.cell.width / 2;
    return cc.p(centerX, centerY);
};