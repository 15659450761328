/**
 * Created by Andrey Popov on 24.10.2024
 */

var Map2dView = cleverapps.UI.ScrollView.extend({
    ctor: function (map, onPositionChanged) {
        var innerMap = this.innerMap = new Map2dInnerView(map);
        this._super(innerMap, {
            scrollBarEnabled: false,
            zoom: cleverapps.config.demoMode ? { minZoom: 0.2 } : true,
            outOfBoundaryScale: cleverapps.skins.getSlot("outOfBoundaryScale"),
            inertialScrollFactor: 0.2
        });

        this.setClippingEnabled(false);
        this.isMapScroll = true;
        this.debugId = "Scroll";

        this.touchZoomDisabled = true;
        this.touchScrollDisabled = true;

        map.onDiscoverScrollView = function () {
            return this;
        }.bind(this);

        map.getScrollZoom = function () {
            return this.zoomHandler.normalizeZoom(this.zoom);
        }.bind(this);

        map.isScrollProcessing = this.createListener(function () {
            return this.scrollHandler.isProcessing();
        }.bind(this));

        this.updateSize();
        this.setLocalZOrder(-1);

        this.updateVisibility();

        this.zoomHandler.setZoom((cleverapps.silentIntro ? 1 : 0.8) * map.getBasicZoom(), true);
        this.setInitialScroll(map.scrollCell || map.getMapCenterCell());

        this.onUpdatePositionListener = this.onUpdateZoomListener = this.createListener(function () {
            map.onUpdateScroll();
            onPositionChanged(this);
        }.bind(this));
    },

    setInitialScroll: function (cell) {
        var scrollCell = this.innerMap.getCell(cell.x, cell.y);
        scrollCell = scrollCell ? this.normalizePoint(this.targetToPoint(scrollCell)) : this.getContainerPosition();

        this.scrollToPoint(scrollCell);
    },

    updateSize: function () {
        var bgSize = cleverapps.resolution.getBgSize();
        var safePadding = cleverapps.resolution.getSafePadding();
        this.setContentSize(bgSize.width, bgSize.height);
        this.setPositionRound(bgSize.width / 2 - safePadding.left, bgSize.height / 2 - safePadding.bottom);

        this.zoomHandler.setZoom(this.zoom, true);
    },

    getAnimationsLayer: function () {
        return this.innerMap.animations;
    },

    getCloudsAboveLayer: function () {
        return this.innerMap.cloudsAbove;
    }
});