/**
 * Created by spepa on 26.09.2022
 */

QuestsConfig.blackfriday = cleverapps.clone(QuestsConfig.universal, true);

delete QuestsConfig.blackfriday.rpcustomerbridge4_0;
delete QuestsConfig.blackfriday.rpcustomerbridge4_1;
delete QuestsConfig.blackfriday.rpcustomermain4_0;
delete QuestsConfig.blackfriday.rpcustomermain4_1;
delete QuestsConfig.blackfriday.rpproducer4;
delete QuestsConfig.blackfriday.rpproduct4;

QuestsConfig.blackfriday.drfruit_3 = {
    trigger: { unit: { code: "dr2dragonblue", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "drfruit", stage: 3 }
    }
};

QuestsConfig.blackfriday.dr2dragonblue_1 = {
    trigger: { unit: { code: "dr2dragonblue", stage: 1 } },
    quest: {
        type: Map2d.FEED_HERO,
        unit: { code: "dr2dragonblue", stage: 1 }
    },
    dynamic: {
        getIcon: function () {
            return UnitView.getUnitImage({ code: "wands", stage: 0 }, { alignAnchorX: true, preferStatic: true });
        },
        getTitle: function () {
            return Messages.get("Quest.wands.title");
        }
    }
};

CustomerRecipes.blackfriday = cleverapps.clone(CustomerRecipes.universal, true);

Ruins.TIERS.blackfriday = {
    cheap: {
        soft: 59,
        rewards: [
            { code: "crystal", stage: 0, amount: 1 },
            { code: "worker", stage: 0, amount: 1 },
            { code: "drfreechest", stage: 0, amount: 1 }
        ]
    },
    costly: {
        hard: 249,
        rewards: [
            { code: "crystal", stage: 3, amount: 1 },
            { code: "worker", stage: 4, amount: 1 },
            { code: "drshopchest", stage: 1, amount: 1 },
            { code: "bppointsstar", stage: 0, amount: 3 }
        ]
    }
};

MissionTree.PRIZES.blackfriday = [{
    amount: 5,
    units: [
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 2 }
    ]
}];

Landmarks.WORLDS.blackfriday = {
    fog: "fog3",
    force: Forces.LANDMARK_DONOR_SLOT1,
    landmarks: [{
        code: "landmark",
        stage: 20,
        targetExpedition: "main",
        donors: ["rpcustomermain0", "rpcustomermain1", "rpcustomermain2", "rpcustomermain3"]
    }]
};

PassLevelsConfig[Mission.CompoundType(Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_BLACKFRIDAY_EXPEDITION)] = cleverapps.clone(ExpeditionPassWindow.LEVELS, true);
PassLevelsConfig[Mission.CompoundType(Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_BLACKFRIDAY_EXPEDITION)].forEach(function (level) {
    level.task.goal = Math.round(level.task.goal / 3);
});