/**
 * Created by razial on 19.10.2021
 */

cleverapps.SkinManager.SKINS.blackfriday_expedition = {
    bundle: "expedition_blackfriday",

    slots: Object.assign({}, cleverapps.SkinManager.SKINS.rose_energy.slots, {
        miningEffects: function () {
            return {
                animation: "chop",
                sound: bundles.merge_blackfriday.urls.worker_sound
            };
        },

        gameAudio: function () {
            return bundles.expedition_blackfriday.urls.blackfriday_music;
        },

        worker_json: function () {
            return bundles.merge_blackfriday.jsons.worker_json;
        },

        workerSkin: function () {
            var json = cleverapps.skins.getSlot("worker_json") || bundles.mineable.jsons.worker_json;
            return cleverapps.Spine.hasSkin("blackfriday", json) ? "blackfriday" : undefined;
        },

        avatarFramesBundle: function () {
            return cleverapps.bundleLoader.isLoaded("snail_feast_blackfriday") ? "snail_feast_blackfriday" : undefined;
        },

        bonusWorkerPersonSkin: function () {
            if (cleverapps.config.name === "mergecraft") {
                return "blackfriday";
            }
        },

        unitIcon: function (unit) {
            return bundles.unit_icons_blackfriday.frames["small_icon_family_" + unit.code];
        },

        gameSceneBg: function () {
            return {};
        },

        expedition_buildpass_icon_json: bundles.sidebar.jsons.blackfriday_expedition_buildpass_icon_json,

        feed_icon_png: bundles.buttons_inlined_icons.frames.blackfriday_feed_icon_png,

        whirlwind: bundles.fog_animations.jsons.blackfriday_whirlwind,

        snail_feast_icon_json: bundles.sidebar.jsons.blackfriday_snail_feast_icon_json,
        snailhouse_offer_json: bundles.sidebar.jsons.blackfriday_snailhouse_offer_json,
        snail_feast_icon: bundles.reward_icons.frames.blackfriday_snail_feast_icon,

        fogballoon_showup_effect: function () {
            return bundles.merge.urls.fogballoon_showup_effect;
        },

        fogballoon_buy_effect: function () {
            return bundles.merge.urls.fogballoon_buy_effect;
        },

        fog_disappear_effect: function () {
            return bundles.fog_blackfriday.urls.open_effect;
        }
    })
};
