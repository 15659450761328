/**
 * Created by andrey on 21.12.2020.
 */

cleverapps.override(cleverapps.Tool, {
    UI: {
        windows: {
            levelUpWindow: displayToolWindow(function () {
                new LevelUpWindow();
            }),

            startQuestWindow: displayToolWindow(function () {
                new StartQuestWindow(Game.currentGame.quests.quests.slice(0, 2));
            }),

            finishQuestWindow: displayToolWindow(function () {
                new FinishQuestWindow(Game.currentGame.quests.quests[0]);
            }),

            speedUpWindow: displayToolWindow(function () {
                var unit = new Unit({
                    code: "wood",
                    stage: 6,
                    unbuilt: true
                });

                var worker = new GameWorker(Map2d.currentMap.workers.chooseFreeSkin());
                worker.setSpeedUpBonus();
                worker.assign(unit, unit.findComponent(Buildable).time);

                new SpeedUpWindow(worker);
            }),

            buyIngredientsWindow: displayToolWindow(function () {
                new BuyIngredientsWindow({
                    hardItems: [
                        { ingredient: "wheat", amount: 10 },
                        { ingredient: "apple", amount: 3 },
                        { ingredient: "candies", amount: 5 }
                    ],
                    softItems: [
                        { ingredient: "strawberry", amount: 5 },
                        { ingredient: "mushrooms", amount: 2 }
                    ],
                    totalHard: 300,
                    totalSoft: 20
                });
            }),

            SpecialEnergyOfferWindow: displayToolWindow(function () {
                new SpecialEnergyOfferWindow();
            }),

            SeasonWindow: displayToolWindow(function () {
                new SeasonWindow(Game.currentGame.season);
            }),

            ReceiveWorkerWindow: displayToolWindow(function () {
                new ReceiveWorkerWindow();
            }),

            WarehouseWindow: displayToolWindow(function () {
                new WarehouseWindow();
            })
        }
    },

    minigame: ToolFolder.create(function () {
        var minigame = cleverapps.scenes.getRunningScene().mineGame;
        if (minigame) {
            return {
                win: minigame.win.bind(minigame)
            };
        }
    }),

    game: {
        win: undefined,
        lose: undefined,

        stop_saving: function () {
            connector.dataLoader.setEnabled(false);
            cleverapps.dataLoader.setEnabled(false);
        },

        tutorial: ToolFolder.create(function () {
            var menu = {};

            menu.finishAll = function () {
                Game.currentGame.tutorial.finishAll();

                Game.currentGame.quests.quests = Game.currentGame.quests.quests.filter(function (quest) {
                    return !quest.getTutorial();
                });
                Game.currentGame.quests.save();
            };

            for (var id in MergeTutorials) {
                menu[id] = function (id) {
                    cleverapps.focusManager.display({
                        focus: "quests",
                        action: function (f) {
                            Game.currentGame.tutorial.showTutorial(MergeTutorials[id], f);
                        }
                    });
                }.bind(null, id);
            }

            return menu;
        }),

        nextHero: function () {
            var merge = Game.currentGame;

            var type = cleverapps.unitsLibrary.getExpeditionUnitType("hero");
            var currentHeroCode = cleverapps.unitsLibrary.getCurrentHero(type);
            var activeHeroCode = cleverapps.unitsLibrary.getActiveHero(type);

            var prevHero = cleverapps.unitsLibrary.heroes.filter(function (unit) {
                return unit.code === activeHeroCode;
            })[0];

            var cell = merge.map.findEmptySlot(prevHero.x, prevHero.y);

            var newHero = new Unit({
                code: currentHeroCode,
                stage: Families[currentHeroCode].units.length - 1
            });

            newHero.setPosition(cell.x, cell.y);
            merge.map.add(Map2d.LAYER_UNITS, newHero.x, newHero.y, newHero);
            merge.map.onAddUnit(newHero.x, newHero.y, newHero);

            cleverapps.unitsLibrary.openUnit(newHero);
        },

        resetBonusWorker: function () {
            Map2d.currentMap.workers.removeBonusWorker();
        },

        resetFreeWorker: function () {
            cleverapps.dataLoader.remove(DataLoader.TYPES.FREE_WORKER + Game.currentGame.slot);
            Map2d.currentMap.workers.removeBonusWorker();
        },

        sendPixels: function () {
            if (cleverapps.unitsLibrary.listAvailableByType("fruit").length || cleverapps.travelBook.isExpedition()) {
                Game.currentGame.pixelsPlanner.createSwarm();
            }
        },

        sendFriendBalloon: function () {
            Game.currentGame.pixelsPlanner.createSwarm({ type: PixelsSwarm.FRIEND, amount: 1 });
        },

        sendKraken: function () {
            Game.currentGame.monstersPlanner.spawnMonster();
        },

        battlePassPoints: ToolFolder.create(function () {
            return {
                send: function () {
                    var current = Game.currentGame.bpPointsPlanner.getCurrentPoints();
                    if (current) {
                        Game.currentGame.bpPointsPlanner.performSpawn(current);
                    }
                },
                sendWithWindow: function () {
                    var current = Game.currentGame.bpPointsPlanner.getCurrentPoints();
                    if (current) {
                        Game.currentGame.bpPointsPlanner.performSpawn(current);

                        var mission = cleverapps.missionManager.findLocalPass();
                        if (mission) {
                            mission.logic.displayWindow();
                        }
                    }
                },
                resetCoolDown: function () {
                    cleverapps.adsLimits.reset(AdsLimits.TYPES.POINTS_THIRD_ELEMENT);
                }
            };
        }),

        resetWands: function () {
            Game.currentGame.takeWands(Game.currentGame.wands);
        },

        add10Wands: function () {
            Game.currentGame.addReward("wands", 10);
        },

        add1000Wands: function () {
            Game.currentGame.addReward("wands", 1000);
        },

        toggleAnchorsMode: {
            flag: "keyboardAnchorsMode"
        },

        toggleNodeDebugPanel: {
            flag: "nodeDebugPanelMode"
        },

        energyLottery: {
            reset: function () {
                Game.currentGame.energyLottery.reset();
            }
        },

        quests: ToolFolder.create(function () {
            var quests = Game.currentGame && Game.currentGame.quests || [];
            var menu = {
                repeated: {}
            };

            for (var id in quests.config) {
                var config = quests.config[id];

                if (RepeatedQuests[id]) {
                    menu.repeated[id] = function (repeated) {
                        var generated = quests.generateConfig(repeated);
                        if (generated) {
                            quests.startQuests([generated]);
                        }
                    }.bind(undefined, config);
                } else {
                    var trigger = config.trigger;
                    var type = id.replace(/_.+/, "") || "other";

                    if (!menu[type]) {
                        menu[type] = {};
                    }

                    menu[type][id] = quests.create.bind(quests, {
                        unit: trigger && trigger.unit,
                        fog: trigger && trigger.fog
                    });
                }
            }

            return menu;
        }),

        stories: ToolFolder.create(function () {
            var menu = {};
            var page = cleverapps.travelBook.getCurrentPage();
            var level = page.getCurrentLevel();

            Object.keys(UnitStoriesData).forEach(function (id) {
                var code = id.split("_")[0];
                var stage = +id.split("_")[1];

                if (code === "fog") {
                    menu[id] = function (id) {
                        Game.currentGame.unitStories.onFogOpened({ id: id.slice("fog_".length) });

                        Game.currentGame.counter.trigger();
                    }.bind(null, id);

                    return;
                }

                if (!level.families[code]) {
                    return;
                }

                if (!menu[code]) {
                    menu[code] = {};
                }

                menu[code][id] = function (id) {
                    var unit = Map2d.currentMap.listAvailableUnits({
                        code: code,
                        stage: stage
                    })[0];

                    if (unit) {
                        Game.currentGame.unitStories.onUnitAvailable(unit);
                    } else {
                        Game.currentGame.unitStories.fresh.push({
                            story: Game.currentGame.unitStories.findStory(id)
                        });
                        Game.currentGame.counter.trigger();
                    }
                }.bind(null, id);
            });
            return menu;
        }),

        seasons: ToolFolder.create(function () {
            var menu = {};

            menu.reset = function () {
                Season.getCurrentSeason = function () {
                    return new Date().getMonth();
                };
            };

            for (var i = 0; i < 12; i++) {
                menu["season" + i] = function (i) {
                    Season.getCurrentSeason = function () {
                        return i;
                    };
                }.bind(this, i);
            }

            return menu;
        }),

        thirdElement: ToolFolder.create(function () {
            var spawn = function (type) {
                var scene = cleverapps.scenes.getRunningScene();
                var map = Map2d.currentMap;

                var cell = scene.map.innerMap.getCellByCoordinates(scene.map.getCurrentPosition());
                cell = map.findEmptySlot(cell.x, cell.y);

                var unit = {
                    stage: 4,
                    code: "wood"
                };

                if (cell && unit) {
                    var config = ThirdElement.GetConfig(type, unit);
                    if (!config) {
                        return;
                    }
                    var options = {
                        code: "thirdelement", stage: config.limit !== undefined ? 0 : 1, type: type, prize: unit
                    };

                    var offer = new Unit(options);

                    offer.setPosition(cell.x, cell.y);
                    map.add(Map2d.LAYER_UNITS, cell.x, cell.y, offer);
                    map.onAddUnit(cell.x, cell.y, offer);
                    offer.findComponent(ThirdElement).onAppearance();
                }
            };

            return {
                ads: spawn.bind(undefined, ThirdElement.TYPE_ADS),
                ruby: spawn.bind(undefined, ThirdElement.TYPE_RUBY)
            };
        }),

        pixelOffer: ToolFolder.create(function () {
            var menu = {};

            for (var i = 0; i < PixelOfferLogic.LEVELS.length; i++) {
                menu["stage_" + i] = function (stage) {
                    var offer = cleverapps.offerManager.findOffer({ type: Offers.TYPES.PIXEL_OFFER });
                    if (!offer) {
                        cleverapps.offerManager.addOffer(Offers.TYPES.PIXEL_OFFER);
                        cleverapps.offerManager.shownOfferTypes.push(Offers.TYPES.PIXEL_OFFER);
                        offer = cleverapps.offerManager.findOffer({ type: Offers.TYPES.PIXEL_OFFER });
                    }
                    offer.stage = stage;
                    cleverapps.offerManager.save();
                    cleverapps.offerManager.refreshOffer(Offers.TYPES.PIXEL_OFFER);
                }.bind(this, i);
            }

            return menu;
        }),

        barrel: function () {
            Game.currentGame.barrelPlanner.spawn();
        },

        hideUI: function () {
            MergeScene.prototype.addSideBar = function () {};
            MergeScene.prototype.addMenuContainer = function () {};

            cleverapps.Tool.Debug.screenshots();
        },

        makeUnknown: ToolFolder.create(function () {
            var menu = {};

            menu.clear = function () {
                cleverapps.dataLoader.remove(DataLoader.TYPES.UNKNOWN_UNIT_TEST);
                cleverapps.dataLoader.processSaveQueue();
                setTimeout(window.location.reload.bind(window.location), 0);
            };

            Object.keys(Game.currentGame && Game.currentGame.level.families || {}).forEach(function (code) {
                menu[code] = {
                    family: function () {
                        cleverapps.dataLoader.save(DataLoader.TYPES.UNKNOWN_UNIT_TEST, {
                            code: code
                        });
                        cleverapps.dataLoader.processSaveQueue();
                        setTimeout(window.location.reload.bind(window.location), 0);
                    },
                    lastUnit: function () {
                        cleverapps.dataLoader.save(DataLoader.TYPES.UNKNOWN_UNIT_TEST, {
                            code: code,
                            stage: Families[code].units.length - 1
                        });
                        cleverapps.dataLoader.processSaveQueue();
                        setTimeout(window.location.reload.bind(window.location), 0);
                    }
                };
            });
            return menu;
        }),

        pocket: {
            clear: function () {
                Game.currentGame.pocket.bubbles.forEach(function (bubble) {
                    bubble.remove();
                });
            },

            addUnit: ToolFolder.create(function () {
                return Unit.CreateHierarchyMenu(function (unit) {
                    Game.currentGame.pocket.addUnits(unit);
                });
            })
        },

        pawbox: {
            clear: function () {
                Game.currentGame.pawBox && Game.currentGame.pawBox.setPoints(0);
            },
            add: function () {
                if (Game.currentGame.pawBox) {
                    var points = parseInt(window.prompt("points", Game.currentGame.pawBox.getPointsLeft()));
                    if (points) {
                        Game.currentGame.pawBox.addPoints(points);
                    }
                }
            }
        },

        storage: {
            resetLevel: function () {
                Game.currentGame.harvested.level = 0;
                Game.currentGame.harvested.save();
                Game.currentGame.harvested.onChange();
            },
            resetItems: function () {
                Game.currentGame.harvested.items = {};
                Game.currentGame.harvested.save();
                Game.currentGame.harvested.onChange();
            }
        },

        timeouts: {
            hastenFactory: function () {
                ToyFactory.PRODUCTION_DURATION = cleverapps.parseInterval("5 seconds");
                Map2d.currentMap.listAvailableUnits().forEach(function (unit) {
                    var factory = unit.findComponent(ToyFactory);
                    if (factory) {
                        factory.start();
                    }
                });
            }
        },

        testUnitSaveLogs: function () {
            var slot = Map2d.currentMap.findEmptySlot(0, 0);

            var unit = new Unit({ code: "wood", stage: 3 });
            unit.setPosition(slot.x, slot.y);

            cleverapps.notification.create("unit created " + unit.code + "_" + unit.stage + ". It will be saved in 15 sec.");

            setTimeout(function () {
                unit.save();
                cleverapps.notification.create("unit saved " + unit.code + "_" + unit.stage);
            }, 15 * 1000);
        }
    },

    Debug: {
        customers: ToolFolder.create(function () {
            var customers = {};

            Map2d.currentMap && Map2d.currentMap.listAvailableUnits([{ type: "rpcustomer" }]).forEach(function (customerUnit) {
                customers[customerUnit.code] = function () {
                    var customer = customerUnit.findComponent(Customer);

                    var ingredients = {};

                    customer.getCurrentRecipe().ingredients.forEach(function (ingredient) {
                        ingredients["add_" + ingredient.unit.code] = function () {
                            var scene = cleverapps.scenes.getRunningScene();
                            var cell = scene.map.innerMap.getCellByCoordinates(scene.map.getCurrentPosition());
                            cell = Map2d.currentMap.findEmptySlot(cell.x, cell.y, ingredient.unit, { radius: 0 });
                            if (cell) {
                                var unit = new Unit(ingredient.unit);
                                if (unit.findComponent(Creatable)) {
                                    unit.findComponent(Creatable).state = Creatable.STATE_BUILT;
                                }

                                unit.setPosition(cell.x, cell.y);
                                Map2d.currentMap.add(Map2d.LAYER_UNITS, cell.x, cell.y, unit);
                                Map2d.currentMap.onAddUnit(cell.x, cell.y, unit);
                                Map2d.currentMap.onUnitAvailable(unit);

                                Map2d.mapEvent(Map2d.SPAWN, { unit: unit });
                            }
                        };
                    });

                    ingredients.regenerate = function () {
                        customer.seed = Date.now();
                        customer.unit.save();
                        customer.order();

                        cleverapps.toolModel.refresh();
                        cleverapps.windows.closeAllWindows();
                        Map2d.currentMap.customers.showWindow(customer);
                    };

                    return ingredients;
                };
            }, this);

            return customers;
        }),
        demo: ToolFolder.create(function () {
            var urls = {};

            cleverapps.travelBook.pages.forEach(function (page) {
                urls[page.id] = function () {
                    var url = cleverapps.RestClient.standsWeb("/?unitsDemo=" + page.id);
                    connector.platform.openUrl(url);
                };

                var addUnit = function (code, unit) {
                    if (Families[unit] && Families[unit].expeditions.includes(page.id)) {
                        urls[code] = function () {
                            var url = cleverapps.RestClient.standsWeb("/?unitsDemo=" + page.id + "&code=" + unit);
                            connector.platform.openUrl(url);
                        };
                    }
                };

                var battlepassUnit = UnitsLibrary.EXPEDITION_PREFIXES[page.id] + "battlepass";
                addUnit(page.id + "_bp", battlepassUnit);
                if (page.id === "dragonia3") {
                    cleverapps.rangeArray(1, 5).forEach(function (stage) {
                        addUnit(page.id + "_bp" + stage, battlepassUnit + stage);
                    });
                }
            });

            return urls;
        })
    }
});
