/**
 * Created by Andrey Popov on 30.10.2024
 */

var ExchangeWindow = CleverappsWindow.extend({
    onWindowLoaded: function (customer) {
        this.customer = customer;
        this.recipe = this.customer.getCurrentRecipe();

        this._super({
            name: "ExchangeWindow",
            customTitle: true,            
            contentPadding: customer.unit.isCloseToMapLeft() ? cleverapps.UI.DOCK_RIGHT : cleverapps.UI.DOCK_LEFT,
            noBackground: true,
            content: this.createContent(),
            notCloseByTouchInShadow: true
        });

        this.recipesUpdatedHandler = Map2d.currentMap.customers.on("onRecipesUpdated", this.updateButton.bind(this), this);
        this.updateButton();
    },

    createContent: function () {
        var styles = cleverapps.styles.ExchangeWindow;

        this.content = new cc.Node();
        this.content.setAnchorPoint(0.5, 0.5);
        this.content.setContentSize2(styles);

        this.title = new CustomerWindowTitle(this.customer);
        this.title.setPositionRound(styles.title);
        this.content.addChild(this.title);

        var bg = cleverapps.UI.createScale9Sprite(
            bundles.customer_window.frames.customer_scroll,
            cleverapps.UI.Scale9Rect.TwoPixelXY
        );
        bg.setContentSize2(styles.bg);
        bg.setPositionRound(styles.bg);
        this.content.addChild(bg);

        var guide = this.customer.getGuideOptions();
        if (guide) {
            var help = new cleverapps.UI.HelpButton(function () {
                new GuideWindow(guide);
            });
            help.setPositionRound(styles.help);
            bg.addChild(help);
        }

        var layout = new cleverapps.Layout([this.createIngredients(), this.createOrders()], {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL
        });
        layout.setPositionRound(styles.layout);
        bg.addChild(layout);

        var arrow = this.arrow = new cleverapps.Spine(bundles.customer_window.jsons.customer_arrow_json);
        this.content.addChild(arrow);
        arrow.setPositionRound(styles.arrow);

        this.buttonText = cleverapps.UI.generateTTFText("Customer.BuyAll", cleverapps.styles.FONTS.CUSTOMER_BUTTON_TEXT);
        this.buttonText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        this.content.addChild(this.buttonText);
        this.buttonText.fitTo(styles.buttonText.width);
        this.buttonText.setPositionRound(styles.buttonText);

        this.button = this.createButton();
        this.content.addChild(this.button);
        this.button.setPositionRound(styles.button);

        return this.content;
    },

    beforeCloseAnimation: function (callback) {
        this.recipesUpdatedHandler.clear();
        Map2d.currentMap.customers.clearWantsWindow();

        if (!this.customerForExchange) {
            callback();
            return;
        }
        var position;
        this.runAction(new cc.Sequence(
            new cc.CallFunc(function () {
                position = this.content.convertToNodeSpace(this.ordersBg.parent.convertToWorldSpace(this.ordersBg.getPosition()));
                this.ingredients.forEach(function (ingredient) {
                    ingredient.icon.replaceParentSamePlace(this.content);
                    ingredient.icon.runAction(new cc.Sequence(
                        new cc.Spawn(
                            new cc.ScaleTo(0.6, 1.5),
                            new cc.JumpTo(0.6, position.x, position.y, cleverapps.styles.ExchangeWindow.ingredients.flyHeight, 1)
                        ),
                        new cc.RemoveSelf()
                    ));
                }.bind(this));
            }.bind(this)),
            new cc.DelayTime(0.4),
            new cc.PlaySound(bundles.merge.urls.unit_desturction_effect),
            new cc.CallFunc(function () {
                var animation = new cleverapps.Spine(bundles.orderswindow.jsons.ow_rewards_highlight_json);
                animation.setScale(1.7);
                animation.setAnchorPoint(0.5, 0.5);
                animation.setPositionRound(this.ordersBg.width / 2, this.ordersBg.height / 2);
                animation.setAnimation(0, "animation", false);
                animation.setCompleteListenerRemove();
                this.ordersBg.addChild(animation);
                this.title.close();
            }.bind(this)),
            new cc.DelayTime(0.6),
            new cc.CallFunc(function () {
                this.hideSelf();
                Map2d.currentMap.unhighlightUnit();
                Map2d.currentMap.zoomIn(this.customerForExchange.unit, {
                    callback: this.customerForExchange.exchange.bind(this.customerForExchange, callback)
                });
            }.bind(this))
        ));
    },

    createIngredients: function () {
        this.ingredients = this.recipe.getIngredients().map(function (ingredient) {
            return new CustomerIngredientView(ingredient);
        });

        return new cleverapps.Layout(this.ingredients, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.ExchangeWindow.ingredients.margin
        });
    },

    createOrders: function () {
        var styles = cleverapps.styles.ExchangeWindow.orders;

        this.orders = this.recipe.getOrder().map(function (order) {
            return new CustomerOrderView(order);
        });

        var layout = new cleverapps.Layout(this.orders, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        var bg = this.ordersBg = cleverapps.UI.createScale9Sprite(
            bundles.customer_window.frames.customer_reward_bg,
            cleverapps.UI.Scale9Rect.TwoPixelXY
        );
        bg.setContentSize2(styles.width, layout.height + styles.extraHeight);
        bg.addChild(layout);
        layout.setPositionRound(bg.width / 2, bg.height / 2);

        return bg;
    },

    createButton: function () {
        var button = this.createBuyButton();
        if (button) {
            this.buttonText.setVisible(true);
        } else {
            button = new cleverapps.UI.Button({
                text: "Customer.Exchange",
                width: cleverapps.styles.ExchangeWindow.button.width,
                height: cleverapps.styles.ExchangeWindow.button.height,
                onClicked: this.exchange.bind(this)
            });
            button.adviceTarget = Map2d.SPAWN;

            this.buttonText.setVisible(false);
            if (this.customer.isRecipeReady()) {
                button.enable();
            } else {
                button.disable();
            }
        }
        button.setCascadeOpacityEnabledRecursively(true);
        return button;
    },

    createBuyButton: function () {
        var shoppingList = this.recipe.getShoppingList();
        if (!shoppingList.totalHard && !shoppingList.totalSoft) {
            return;
        }
        var totalHard = shoppingList.totalHard, totalSoft = shoppingList.totalSoft;

        var styles = cleverapps.styles.ExchangeWindow.button;
        var msg = (totalSoft ? " @@" + totalSoft : "") + (totalHard ? " $$" + totalHard : "");

        return new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.button_blue,
            content: new TextWithIcon(msg),
            width: totalHard && totalSoft ? styles.buyWidthPair : styles.buyWidth,
            height: styles.height,
            onClicked: function () {
                if (!Map2d.currentMap.customers.canSpawnIngredients(shoppingList.hardItems.concat(shoppingList.softItems))) {
                    return;
                }

                if (totalHard) {
                    if (!cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.RECIPE_INGREDIENT_ALL, totalHard)) {
                        return;
                    }

                    Map2d.currentMap.customers.spawnIngredients(shoppingList.hardItems);
                }

                if (totalSoft && cleverapps.user.spendSoft(cleverapps.EVENTS.SPENT.RECIPE_INGREDIENT_ALL, totalSoft)) {
                    Map2d.currentMap.customers.spawnIngredients(shoppingList.softItems);
                }

                if (this.customer.isRecipeReady()) {
                    this.button.removeFromParent();
                    this.exchange();
                }
            }.bind(this)
        });
    },

    exchange: function () {
        var recipe = this.customer.getCurrentRecipe();
        var spawned = recipe.listPrize().length;
        var removed = recipe.getIngredients().reduce(function (total, ingredient) {
            return total + ingredient.amount;
        }, 0);
        var notEnough = spawned - removed - Map2d.currentMap.countEmptySlots();
        if (notEnough > 0) {
            cleverapps.centerHint.createTextHint("Spawn.nospace", { left: notEnough });
        } else {
            this.customerForExchange = this.customer;
        }
        this.close();
    },

    updateButton: function () {
        var newButton = this.createButton();
        this.button.parent.addChild(newButton);
        newButton.setPositionRound(this.button.getPosition());
        this.button.removeFromParent();
        this.button = newButton;
    },

    onHide: function () {
        this._super();
        Map2d.currentMap.unhighlightUnit();
    },

    onShow: function () {
        this._super();
        Map2d.currentMap.highlightUnit(this.customer.unit);

        [this.title].concat(this.ingredients).concat(this.orders).forEach(function (item) {
            item.showUp();
        });

        this.ordersBg.setOpacity(0);
        this.ordersBg.runAction(new cc.Sequence(
            new cc.DelayTime(0.7),
            new cc.CallFunc(function () {
                this.arrow.setAnimationAndIdleAfter("open", "idle");
            }.bind(this)),
            new cc.FadeIn(0.2)
        ));

        if (this.buttonText.isVisible()) {
            this.buttonText.setOpacity(0);
            this.buttonText.runAction(new cc.Sequence(
                new cc.DelayTime(1.5),
                new cc.FadeIn(0.5)
            ));
        }

        this.button.setOpacity(0);
        this.button.runAction(new cc.Sequence(
            new cc.DelayTime(1.5),
            new cc.FadeIn(0.5)
        ));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CUSTOMER_BUTTON_TEXT: {
        size: 26,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    CUSTOMER_INGREDIENTS_TEXT: {
        name: "default",
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.ExchangeWindow = {
    width: 700,
    height: 840,

    margin: 30,

    title: {
        x: { align: "center" },
        y: { align: "top", dy: 150 }
    },

    bg: {
        x: { align: "center" },
        y: { align: "bottom", dy: -40 },
        width: 520,
        height: 670
    },

    layout: {
        x: { align: "center" },
        y: { align: "center", dy: 80 }
    },

    arrow: {
        x: { align: "center" },
        y: { align: "center", dy: -68 }
    },

    ingredients: {
        margin: 6,
        flyHeight: 100
    },

    orders: {
        margin: 4,

        width: 380,
        extraHeight: 20
    },

    help: {
        x: { align: "right", dx: 10 },
        y: { align: "top", dy: 10 }
    },

    buttonText: {
        x: { align: "center" },
        y: { align: "bottom", dy: 126 },
        width: 480
    },

    button: {
        x: { align: "center" },
        y: { align: "bottom", dy: 18 },
        width: 200,
        height: 90,
        buyWidth: 240,
        buyWidthPair: 300
    }
};
