/**
 * Created by mac on 12/24/20
 */

var ComponentsFactory = function (res, unit, options) {
    if (options.grounded) {
        res.push(new Grounded(unit));
    }

    var data = unit.getData();

    if (data.info) {
        res.push(new InfoComponent(unit));
    }

    if (data.breakable) {
        res.push(new Breakable(unit, options));
    }

    if (data.buildable && (options.unbuilt || options.time)) {
        res.push(new Buildable(unit, options));
    }

    if (data.creatable) {
        res.push(new Creatable(unit, options));
    }

    if (data.mineable) {
        res.push(new Mineable(unit, options));
    }

    if (data.lockable) {
        res.push(new LockedComponent(unit, options));
    }

    if (data.field) {
        res.push(new FieldComponent(unit, options));
    }

    if (data.chest) {
        res.push(new Chest(unit));
    }

    if (data.pulsing) {
        res.push(new Pulsing(unit));
    }

    if (data.collectible) {
        res.push(new Collectible(unit));
    }

    if (data.resourceCollectible) {
        res.push(new ResourceCollectible(unit));
    }

    if (data.shine) {
        res.push(new Shine(unit));
    }

    if (data.makesorder) {
        res.push(new MakesOrder(unit, options));
    }

    if (data.instantWorker) {
        res.push(new InstantWorker(unit, options));
    }

    if (data.generator) {
        res.push(new GeneratorComponent(unit, options));
    }

    if (data.climbable) {
        res.push(new Climbable(unit, options));
    }

    if (data.campaign) {
        res.push(new Campaign(unit, options));
    }

    if (data.upgradable) {
        res.push(new Upgradable(unit, options));
    }

    if (data.fruit) {
        res.push(new Fruit(unit, options));
    }

    if (data.multicell) {
        res.push(new MultiCell(unit));
    }

    if (data.heroitem) {
        res.push(new HeroItem(unit));
    }

    if (data.heroitem && options.heroTutorial) {
        res.push(new HeroTutorial(unit));
    }

    if (data.thirdelement) {
        res.push(new ThirdElement(unit, options));
    }

    if (data.minigame) {
        res.push(new MiniGame(unit, options));
    }

    if (data.unitsshop) {
        res.push(new UnitsShopComponent(unit, options));
    }

    if (data.telly) {
        res.push(new Telly(unit, options));
    }

    if (data.monster) {
        res.push(new Monster(unit, options));
    }

    if (data.growing) {
        res.push(new Growing(unit));
    }

    if (data.mission) {
        res.push(new MissionTree(unit));
    }

    if (data.battlepasspoints) {
        res.push(new BattlePassPoints(unit, options));
    }

    if (data.tank) {
        res.push(new Tank(unit, options));
    }

    if (data.feedable) {
        res.push(new Feedable(unit, options));
    }

    if (data.barrel) {
        res.push(new Barrel(unit, options));
    }

    if (data.rudolf) {
        res.push(new Rudolf(unit, options));
    }

    if (data.toyfactory) {
        res.push(new ToyFactory(unit, options));
    }

    if (data.improver) {
        res.push(new Improver(unit, options));
    }

    if (data.portal) {
        res.push(new Portal(unit, options));
    }

    if (unit.getType() === "ruins") {
        res.push(new Ruins(unit, options));
    }

    if (data.draggable) {
        res.push(new Draggable(unit));
    }

    if (data.cup) {
        res.push(new CupComponent(unit, options));
    }

    if (unit.getType() === "ship") {
        res.push(new Ship(unit, options));
    }

    if (unit.code === "bank") {
        res.push(new Bank(unit, options));
    }

    if (unit.code === "landmarkpreview") {
        res.push(new LandmarkPreview(unit, options));
    }

    if (unit.getType() === "landmark") {
        res.push(new Landmark(unit, options));
    }

    if (unit.getType() === "landmarkspot") {
        res.push(new LandmarkSpot(unit));
    }

    if (data.landmarkDonor) {
        res.push(new LandmarkDonor(unit));
    }

    if (data.customer) {
        res.push(new Customer(unit, options));
    }

    if (data.treasureMap) {
        res.push(new TreasureMap(unit, options));
    }

    if (data.guide) {
        res.push(new GuideComponent(unit));
    }

    if (data.homefixId) {
        res.push(new HomefixIdComponent(unit, options, data.homefixId));
    }

    if (data.throwaway) {
        res.push(new Throwaway(unit, options, data.throwaway));
    }

    if (data.switchable) {
        res.push(new Switchable(unit, options, data.switchable));
    }

    if (data.homeTask) {
        res.push(new HomeTask(unit, data.homeTask));
    }

    if (data.regionControl) {
        res.push(new RegionControl(unit, options, data.regionControl));
    }

    if (data.invisible) {
        res.push(new Invisible(unit));
    }

    if (data.untouchable) {
        res.push(new Untouchable(unit, options));
    }

    if (data.dynamite) {
        res.push(new Dynamite(unit, options));
    }

    if (data.composite) {
        res.push(new Composite(unit));
    }

    if (data.highlight) {
        res.push(new HighLight(unit));
    }

    if (data.completeLocation) {
        res.push(new CompleteLocationComponent(unit, options, data.completeLocation));
    }

    if (data.fixable) {
        res.push(new Fixable(unit));
    }
};