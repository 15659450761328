/**
 * Created by vladislav on 28.03.2022
 */

var UnitHighlighter = cc.Node.extend({
    ctor: function (mapView, unit, options) {
        this._super();

        mapView.under.addChild(this);
        this._setGlobalZOrder(true);

        this.addUnit(unit);

        if (options.ground !== false) {
            unit.getShape().forEach(function (part) {
                this.createGroundSprite(mapView, unit.x, unit.y, part);
            }, this);

            if (!mapView.map2d.isWaterUnit(unit.x, unit.y) && [FamiliesHelper.ShapeCell, FamiliesHelper.ShapeSquare].indexOf(unit.getShape()) !== -1) {
                var highlight = new Highlight(unit.x, unit.y, {
                    unit: unit
                });
                var highlightView = new HighlightView(highlight);
                highlightView.setLocalZOrder(0);
                this.addChild(highlightView);
                highlightView.setPositionRound(mapView.alignInIsometricGrid(unit.x, unit.y));
            }
        }

        if (options.spotlight) {
            var position = mapView.alignInIsometricGrid(unit.x, unit.y);
            var spotlight = new cleverapps.Spine(bundles.unit_highlighter.jsons.unit_highlighter_json);
            this.addChild(spotlight);
            spotlight.setPositionRound(position);

            var multiCell = unit.findComponent(MultiCell);
            if (multiCell) {
                var centerDelta = multiCell.getCenter();
                spotlight.x += centerDelta.x;
                spotlight.y += centerDelta.y;

                spotlight.setAnimationAndIdleAfter("open2x2", "idle2x2");
            } else {
                spotlight.setAnimationAndIdleAfter("open", "idle");
            }
            cleverapps.audio.playSound(bundles.unit_highlighter.urls.unit_highlight_effect);
        }
    },

    createGroundSprite: function (mapView, x, y, part) {
        var map = mapView.map2d;
        map.setPointer(x, y);
        var ground = map.getGround(Iso.SAME);
        var groundView = ViewReader.parse(ground, map);

        if (!groundView) {
            return;
        }

        var position = mapView.alignInIsometricGrid(x + part.x, y + part.y);
        groundView.setScale(0.97);
        groundView.setPositionRound(position);
        this.addChild(groundView);
    },

    addUnit: function (unit) {
        this.unitView = unit.onGetView();
        this.unitView._setGlobalZOrder(true);
        if (this.unitView.getAdditionalView("ingredients")) {
            this.unitView.getAdditionalView("ingredients")._setGlobalZOrder(true);
            this.unitView.outline(true);
        }
    },

    removeUnit: function () {
        if (this.unitView) {
            this.unitView._setGlobalZOrder(false);
            if (this.unitView.getAdditionalView("ingredients")) {
                this.unitView.getAdditionalView("ingredients")._setGlobalZOrder(false);
            }
            this.unitView = undefined;
        }
    },

    unhighlight: function () {
        this.removeUnit();
        this.removeFromParent();
    }
});