/**
 * Created by mac on 12/15/20
 */

var Highlight = function (x, y, options) {
    options = options || {};

    this.error = 0;

    this.x = x;
    this.y = y;

    this.isErrorLight = options.isErrorLight;
    this.unit = options.unit;
    this.drag = options.drag;

    this.onMove = function () {};
    this.onRemove = function () {};
    this.show = function () {};
    this.hide = function () {};
    this.up = function () {};
};

Highlight.prototype.getShape = function () {
    return this.unit ? this.unit.getShape() : FamiliesHelper.ShapeCell;
};

Highlight.prototype.move = function (x, y, silent) {
    this.error = this.unit && !this.unit.canMoveTo(x, y);

    if (typeof Editor !== "undefined" && Editor.currentEditor) {
        this.showIntersect(x, y);
    }

    this.onMove(x, y, silent);
};

Highlight.prototype.showIntersect = function (x, y) {
    this.hideIntersect();

    if (!this.error) {
        return;
    }

    var intersections = {};

    this.getShape().forEach(function (shape) {
        var intersect = Map2d.currentMap.getUnit(x + shape.x, y + shape.y)
        || Map2d.currentMap.fences.getFencesIntersection(x + shape.x, y + shape.y);

        if (intersect) {
            intersections[shape.x + "_" + shape.y] = intersect.head ? intersect.head : intersect;
        }
    });

    Object.keys(intersections).forEach(function (key) {
        if (intersections[key] === this.unit) {
            delete intersections[key];
        }
    }.bind(this));

    if (Object.keys(intersections).length) {
        this.intersects = intersections;
        Object.keys(this.intersects).forEach(function (pos) {
            var intersect = this.intersects[pos];
            if (intersect instanceof Unit) {
                intersect.highlight();
                intersect.onGetView() && intersect.onGetView().debugBorder();
            }
        }.bind(this));
    }
};

Highlight.prototype.hideIntersect = function () {
    if (this.intersects && Object.keys(this.intersects).length) {
        Object.keys(this.intersects).forEach(function (pos) {
            var intersect = this.intersects[pos];
            if (intersect instanceof Unit) {
                intersect.unhighlight();
                intersect.onGetView() && intersect.onGetView().cleanupBorder();
            }
        }.bind(this));
    }

    delete this.intersects;
};

Highlight.prototype.destructor = function () {
    clearTimeout(this.timeout);
};

Highlight.prototype.remove = function () {
    clearTimeout(this.timeout);
    this.hideIntersect();
    this.onRemove();
};

Highlight.prototype.schedule = function (callback, timeout) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(callback, timeout);
};

Highlight.ONE_PULSE = 1500;