/**
 * Created by evgeny on 18.06.2024
 */

var SwitchableView = cc.Node.extend({
    ctor: function (switchable, unitView) {
        this._super();
        this.switchable = switchable;
        this.unitView = unitView;

        this.showChoice(this.switchable.choice || 0, true);
    },

    afterChangeChoice: function (f, silent) {
        if (silent) {
            this.showChoice(this.switchable.choice, true);
            return;
        }

        if (this.unitView.sprite && this.unitView.sprite.hasAnimation("switch")) {
            this.unitView.sprite.setVisible(true);
            this.unitView.sprite.setAnimationAndIdleAfter("switch", "animation");
            this.unitView.sprite.setCompleteListenerOnce(f);
        } else {
            this.unitView.setVisible(false);
            this.unitView.runAction(
                new cc.Sequence(
                    AnimationsLibrary.showUp(this.unitView),
                    new cc.CallFunc(function () {
                        f();
                    })
                )
            );
        }
    },

    beforeChangeChoice: function (f) {
        var fixable = this.unitView.unit.findComponent(Fixable);
        var previousStageImage = fixable.view.previousStageImage;
        if (previousStageImage) {
            this.unitView.setVisible(false);
            previousStageImage.setVisible(true);
            previousStageImage._setGlobalZOrder(true);

            previousStageImage.runAction(
                new cc.Sequence(
                    AnimationsLibrary.disappear(previousStageImage),
                    new cc.DelayTime(0.2),
                    new cc.CallFunc(function () {
                        f();
                    }),
                    new cc.RemoveSelf()
                )
            );
        } else {
            f();
        }
    },

    showChoice: function (choice, silent) {
        var json = SwitchableView.UnitIconJson(this.unitView.unit, choice);
        var spine = new cleverapps.Spine(json);
        this.unitView.setUnitImage(spine);
        this.unitView.sprite.setAnimation(0, "animation", true);
        if (this.hoverAnimation) {
            this.animateHover();
        }
        if (!silent) {
            this.unitView.setVisible(false);
            this.unitView.runAction(AnimationsLibrary.showUp(this.unitView));
        }
    },

    animateHover: function () {
        this.hoverAnimation = this.unitView.sprite.spine.runAction(
            AnimationsLibrary.blinkShader(this.unitView.sprite.spine, {
                brightness: Map2d.currentMap.onDiscoverMapView().highlighter ? undefined : 0.2,
                action: new cc.Sequence(
                    new cc.MoveBy(1, 0, 5).easing(cc.easeInOut(2)),
                    new cc.MoveBy(1, 0, -5).easing(cc.easeInOut(2))
                )
            })
        );
    },

    stopAnimateHover: function () {
        if (this.hoverAnimation) {
            this.unitView.sprite.spine.stopAction(this.hoverAnimation);
            delete this.hoverAnimation;
        }
    }
});

SwitchableView.UnitIconJson = function (unit, choice) {
    var bundle = UnitView.GetUnitBundle(unit);
    var res = bundle.jsons["unit_" + unit.code + "_" + (unit.stage + 1) + "_skin_" + choice];
    if (!res) {
        // temporarily!!!
        return bundle.jsons["unit_" + unit.code + "_" + (unit.stage + 1)];
    }
    return res && res.resolve();
};