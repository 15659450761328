/**
 * Created by vladislav on 31/10/2022
 */

var CustomerView = function (customer, unitView) {
    this.unitView = unitView;
    this.customer = customer;

    this.restoreState();
};

CustomerView.prototype.restoreState = function (animated) {
    if (cleverapps.config.demoMode || !this.customer.getCurrentRecipe() || (cleverapps.windows.currentWindow() instanceof ExchangeWindow)) {
        this.removeInfoView(animated);
    } else {
        this.createInfoView(animated);
    }

    if (this.customer.getCookingRecipe() && this.customer.getCookingTimeLeft()) {
        this.createTimer(animated);
    } else {
        this.removeTimer(animated);
    }
};

CustomerView.prototype.createInfoView = function (animated) {
    var ingredientsView = this.unitView.getAdditionalView("ingredients");
    if (ingredientsView) {
        return;
    }

    var recipe = this.customer.getCurrentRecipe();

    var infoView = new IngredientsInfoView({ ingredients: recipe.getIngredients() });
    infoView.setPositionRound(this.unitView.width / 2, this.unitView.height + cleverapps.styles.InfoView.dy);
    infoView.setAnchorPoint(0.5, 0);
    this.unitView.createAdditionalView("ingredients", infoView);

    if (animated) {
        ingredientsView = this.unitView.getAdditionalView("ingredients");
        ingredientsView.animateAppear();
    }

    cleverapps.UI.onClick(infoView, this.unitView.handleClick.bind(this.unitView));
};

CustomerView.prototype.removeInfoView = function (animated) {
    this.unitView.removeAdditionalView("ingredients", !animated);
};

CustomerView.prototype.createTimer = function (animated) {
    var timerView = this.unitView.getAdditionalView("timer");
    if (timerView) {
        return;
    }

    var styles = cleverapps.styles.CustomerView;

    var cookingRecipe = this.customer.getCookingRecipe();
    var cookingTimeLeft = this.customer.getCookingTimeLeft();

    var countdown = new cleverapps.CountDown(cookingTimeLeft, {
        onTick: this.updateBtn.bind(this)
    });

    var timer = new cleverapps.CountDownView(countdown, {
        font: cleverapps.styles.FONTS.COMPONENT_TIMER_TEXT,
        icon: bundles.timer_merge.frames.timer_png,
        bar: {
            type: ScaledProgressBar.Types.blue,
            width: styles.timer.width,
            fullValue: cookingRecipe.getCookingDuration(),
            reversed: false
        }
    });
    timer.setPositionRound(styles.timer);
    this.unitView.createAdditionalView("timer", timer);

    var button = this.button = new cleverapps.UI.Button({
        width: styles.button.width,
        height: styles.button.height,
        text: "$$" + SpeedUpWindow.CalcPrice(cookingTimeLeft) + ">>",
        type: cleverapps.styles.UI.Button.Images.button_blue,
        onClicked: this.speedUp.bind(this)
    });
    this.updateBtn(cookingTimeLeft);
    button.setPositionRound(styles.button);
    timer.addChild(button);

    if (animated) {
        timerView = this.unitView.getAdditionalView("timer");
        timerView.animateAppear();
    }
};

CustomerView.prototype.speedUp = function () {
    if (this.button) {
        var price = SpeedUpWindow.CalcPrice(this.customer.getCookingTimeLeft());
        if (cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.CUSTOMER, price)) {
            this.customer.enjoy();
        }
    }
};

CustomerView.prototype.updateBtn = function (timeLeft) {
    if (this.button) {
        timeLeft = timeLeft !== undefined ? timeLeft : this.customer.getTimeLeft();
        var price = SpeedUpWindow.CalcPrice(timeLeft);
        if (this.button.speedPrice !== price) {
            this.button.speedPrice = price;
            this.button.setString((price ? ("$$" + price) : "UseGoldButton.free") + TextWithIcon.ICONS_BY_NAME.speedup);
        }
    }
};

CustomerView.prototype.removeTimer = function (animated) {
    this.unitView.removeAdditionalView("timer", !animated);
    delete this.button;
};

cleverapps.styles.CustomerView = {
    distance: 600,

    button: {
        height: 50,
        width: 130,
        x: { align: "center" },
        y: { align: "bottom", dy: -52 }
    },

    timer: {
        x: { align: "center" },
        y: { align: "bottom", dy: 40 },
        width: 140
    }
};