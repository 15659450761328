/**
 * Created by Andrey Popov on 9/23/24.
 */

var FallSaleWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this._super({
            name: "FallSaleWindow",
            noBackground: true,
            notCloseByTouchInShadow: true,
            closeButtonDelay: true,
            fireworks: true,
            content: this.createContent(),
            openSound: bundles.fall_sale_window.urls.sale_showup_effect,
            button: {
                width: cleverapps.styles.FallSaleWindow.button.width,
                height: cleverapps.styles.FallSaleWindow.button.height,

                text: "FallSaleWindow.Button.text",
                onPressed: function () {
                    new HardCurrencyShopWindow();
                    this.close();
                }.bind(this)
            }
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.FallSaleWindow;

        var items = [];

        var header = this.createHeader();

        items.push(header);

        var spine = new cleverapps.Spine(bundles.fall_sale_window.jsons.sale_bg);
        spine.setAnimationAndIdleAfter("open", "idle");
        items.push(spine);

        var description = cleverapps.UI.generateOnlyText("FallSaleWindow.description", cleverapps.styles.FONTS.FALL_SALE_DESCRIPTION);
        description.setDimensions(styles.description.width, 0);
        description.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        description.fitTo(undefined, styles.description.height);

        items.push(description);

        var layout = new cleverapps.Layout(items, {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL
        });

        header.y += styles.title.offsetY;

        return layout;
    },

    createHeader: function () {
        var styles = cleverapps.styles.FallSaleWindow;

        var title1 = cleverapps.UI.generateOnlyText("FallSaleWindow.title1", cleverapps.styles.FONTS.FALL_SALE_TITLE);
        var title2 = cleverapps.UI.generateOnlyText("FallSaleWindow.title2", cleverapps.styles.FONTS.FALL_SALE_TITLE);

        var year1 = cleverapps.UI.generateOnlyText("20", cleverapps.styles.FONTS.FALL_SALE_TITLE);
        var year2 = cleverapps.UI.generateOnlyText("25", cleverapps.styles.FONTS.FALL_SALE_TITLE_YEAR);

        var year = new cleverapps.Layout([year1, year2], {
            margin: styles.year.margin,
            direction: cleverapps.UI.HORIZONTAL
        });

        year2.y += styles.year.offsetY;

        var title = new cleverapps.Layout([title1, year, title2], {
            margin: styles.title.margin,
            direction: cleverapps.UI.HORIZONTAL
        });

        cleverapps.UI.fitToBox(title, {
            width: styles.title.width
        });

        return title;
    },

    listBundles: function () {
        return ["fall_sale_window"];
    },

    getPerson: function () {
        return cleverapps.persons.choose("seller", "hero");
    }
});

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    FALL_SALE_TITLE_SHADOW: {
        color: new cc.Color(187, 60, 0, 255),
        direction: cc.size(4, -6)
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    FALL_SALE_TITLE: {
        size: 100,
        color: new cc.Color(255, 253, 81, 255),
        shadow: cleverapps.styles.DECORATORS.FALL_SALE_TITLE_SHADOW
    },

    FALL_SALE_TITLE_YEAR: {
        size: 130,
        color: new cc.Color(255, 253, 81, 255),
        shadow: cleverapps.styles.DECORATORS.FALL_SALE_TITLE_SHADOW
    },

    FALL_SALE_DESCRIPTION: {
        size: 24,
        color: new cc.Color(178, 237, 246, 255)
    }
});

cleverapps.styles.FallSaleWindow = {
    margin: -30,

    title: {
        width: 1000,

        margin: 10,

        offsetY: -63
    },

    year: {
        margin: -10,
        offsetY: 14
    },

    description: {
        width: 1000,
        height: 200
    },

    button: {
        width: 460,
        height: 100
    }
};

if (cleverapps.config.ui === "heroes") {
    cleverapps.overrideStyles(cleverapps.styles.FallSaleWindow, {
        year: {
            offsetY: 8
        }
    });
}