/**
 * Created by ivan on 11.04.18.
 */

var TravelBookToolbarItem = function () {
    ToolbarItem.call(this, ToolbarItem.TRAVEL_BOOK);

    this.enable();
};

TravelBookToolbarItem.prototype = Object.create(ToolbarItem.prototype);
TravelBookToolbarItem.prototype.constructor = TravelBookToolbarItem;

TravelBookToolbarItem.prototype.resetState = function () {
    if (cleverapps.travelBook.needsAttention()) {
        this.mark();
    } else {
        this.unmark();
    }
};

TravelBookToolbarItem.prototype.getForce = function () {
    return cleverapps.travelBook.chooseForce();
};

TravelBookToolbarItem.prototype.isVisible = function () {
    return cleverapps.user.checkAvailable(TravelBook.AVAILABLE);
};

TravelBookToolbarItem.prototype.showForce = function () {
    ToolbarItem.prototype.showForce.call(this);

    cleverapps.travelBook.markForceShown();
};

TravelBookToolbarItem.prototype.onClick = function (force) {
    cleverapps.focusManager.display({
        focus: "TravelBook",
        action: function (f) {
            new TravelBookWindow({
                targetPage: force && force.page
            });
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};